import { Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { useCountries } from 'utils/locale';

/**
 * Select input field for country selection with formik support
 * @param {object} inputProperties - Input properties of the component
 * @returns {JSX.Element} Country select input field
 * @component
 */
const CountrySelect = ({ name, placeholder, ...props }) => {
  const { t } = useTranslation();
  const countries = useCountries();

  return (
    <Select
      name={name}
      placeholder={placeholder}
      notFoundContent={t('Inputs.country.notFound')}
      showSearch
      options={countries.map((country) => ({ value: country._id, label: country.label }))}
      filterOption={(input, option) =>
        input
          ?.toLowerCase()
          ?.split(' ')
          ?.map((word) => option.label?.toLowerCase()?.indexOf(word) >= 0)
          ?.every(Boolean)
      }
      {...props}
    />
  );
};

export default CountrySelect;
