import { useTranslation } from 'react-i18next';
import { Select as SelectFormik } from 'formik-antd';
import { TableMemo } from 'memo';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

const columnsCompanyTable = ({ t, fields }) => [
  {
    dataIndex: 'required',
    render: (isRequired) => (isRequired ? '*' : null),
    key: 'isRequired',
    width: 12,
  },
  {
    title: t('admin.Setting.ImportCompanies.table.columnSource.title'),
    render: (rowValue, rowObject) => {
      return (
        <FormItem name={rowObject.columnTarget}>
          <SelectFormik
            name={rowObject.columnTarget}
            showSearch
            style={{ width: '100%' }}
            allowClear
            placeholder={t('admin.Setting.ImportCompanies.table.columnSource.placeholder')}
          >
            {fields.map((field) => (
              <SelectFormik.Option value={field} key={field}>
                {field}
              </SelectFormik.Option>
            ))}
          </SelectFormik>
        </FormItem>
      );
    },
    dataIndex: 'columnSource',
    key: 'columnSource',
  },
  {
    title: t('admin.Setting.ImportCompanies.table.columnTarget'),
    dataIndex: 'columnTarget',
    render: (columnTarget) => t(`admin.Setting.ImportCompanies.fields.${columnTarget}`),
    key: 'columnTarget',
  },
];

const Step2 = ({ companyFields, fields, currentStep }) => {
  const { t } = useTranslation();
  if (currentStep !== 1) return null;
  return <TableMemo pagination={false} dataSource={companyFields} columns={columnsCompanyTable({ t, fields })} />;
};

export default Step2;
