import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { Radio } from 'formik-antd';

export const DefaultOption = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.label')}
      name="shoppingCartSettings.defaultOption"
      tooltip={t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.tooltip')}
    >
      <Radio.Group name="shoppingCartSettings.defaultOption">
        <Radio.Button name="shoppingCartSettings.defaultOption" value="yes">
          {t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.optionYes')}
        </Radio.Button>
        <Radio.Button name="shoppingCartSettings.defaultOption" value="no">
          {t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.optionNo')}
        </Radio.Button>
        <Radio.Button name="shoppingCartSettings.defaultOption" value="decideLater">
          {t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.optionDecideLater')}
        </Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};
