import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';

/**
 * City input field for address for client form
 * @returns {JSX.Element} City input field
 */
const ClientCityField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="address.city"
      label={t('ClientsListPage.addClientModal.form.address.city.label')}
      placeholder={t('ClientsListPage.addClientModal.form.address.city.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ClientCityField;
