import { Button, Card } from 'antd';
import inputConfirmModal from 'utils/inputConfirmModal';
import i18n from 'i18n';
import { memo, useMemo, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { updateCompany } from 'graphql/methods';
import { grabFirstGQLDataResult } from 'utils/helpers';
import equal from 'fast-deep-equal/es6/react';
import { FaPlus, FaBuilding } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useLazyQuery } from '@apollo/client';
import { userCompanyQuery } from 'graphql/queries';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import getCountryName from 'ProjectCardManagement/ClientManagement/viewModel/getCountryName';
import classes from './CompanyCard.module.less';
import { CompanyForm, companyToString } from '../components/Inputs';
import { companyFormSchema } from '../components/schema';
import EditButton from '../components/EditButton';
import SwitchCompanyButton from '../components/SwitchCompanyButton';

const editCompanyFormSchema = ({ values }) => {
  return Yup.object().shape({
    ...companyFormSchema(values),
  });
};
const CompanyFormMemo = memo(CompanyForm, equal);

const editCompany = ({ company, onChange }) =>
  inputConfirmModal({
    formContent: () => <CompanyFormMemo />,
    fields: [],
    onSubmit: async (modifier) => {
      const { data } = await updateCompany({ _id: company?._id, ...modifier });
      const currentContact = grabFirstGQLDataResult(data);
      onChange(currentContact);
    },
    value: {
      type: company.type || 'company',
      name: company.name || '',
      identifier: company.identifier || '',
      street: company.address.street || '',
      houseNumber: company.address.houseNumber || '',
      commercialObject: company?.importFields?.commercialObject || '',
      cityCode: company.address.cityCode || '',
      city: company.address.city || '',
      country: company.address.country || 'DE',
    },
    headerText: i18n.t('user.ShoppingCart.ContactData.modal.titles.editCompany'),
    okText: i18n.t('user.ShoppingCart.ContactData.modal.ok'),
    cancelText: i18n.t('user.ShoppingCart.ContactData.modal.cancel'),
    validationSchema: editCompanyFormSchema,
    forceMultiField: true,
    width: '600px',
    errorResolver: {
      Duplicated: ['name', i18n.t('user.ShoppingCart.ContactData.company.duplicatedErrorMessage.name')],
    },
  });

function CompanyCard({ addNewCompany, company: companyIdFormik, onChange, initializationConfigDateForCompany }) {
  const { t } = useTranslation();
  const [getCompany, { data, ...rest }] = useLazyQuery(userCompanyQuery, {
    variables: { _id: companyIdFormik, initializationConfigDate: initializationConfigDateForCompany },
    fetchPolicy: 'network-only',
  });
  useEffect(() => companyIdFormik && getCompany(), [companyIdFormik, getCompany]);
  const company = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const onEdit = useCallback(() => {
    editCompany({ company, onChange });
  }, [company, onChange]);
  return (
    <GraphQLLoadingWrapper data={data} {...rest} isAlwaysDisplay>
      <Card
        onClick={company ? null : addNewCompany}
        title={
          company ? (
            <div className={classes.title}>
              <h3>{companyToString(company)}</h3>
              {company?.identifier ? <div>{company.identifier}</div> : null}
            </div>
          ) : (
            companyToString(company)
          )
        }
        className={cn(classes.cardCompany, company ? '' : 'cursor-pointer')}
        extra={company ? null : <Button className="ant-button-transparent" icon={<FaPlus />} />}
      >
        {company ? (
          <>
            <div className={classes.companyCartContent}>
              <div>
                <span>{company.address.street}</span> <span>{company.address.houseNumber}</span>
              </div>
              <div>
                <span>{company.address.cityCode}</span> <span>{company.address.city}</span>
              </div>
              <div>
                <span>{getCountryName(company.address.country)}</span>
              </div>
            </div>
            <div className={classes.cardCompanyActions}>
              <EditButton onEdit={onEdit} />
              <SwitchCompanyButton onClick={addNewCompany} />
            </div>
          </>
        ) : (
          <div className={classes.empty}>
            <div>
              <FaBuilding size="50px" />
            </div>
            <div>{t('user.ShoppingCart.ContactData.company.empty')}</div>
          </div>
        )}
      </Card>
    </GraphQLLoadingWrapper>
  );
}
export default CompanyCard;
