import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import PageContainer from 'components/layout/PageContainer';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { Form, Input, InputNumber } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import * as Yup from 'yup';
import PhoneNumber, { PhoneInputValidationSchema } from 'components/common/PhoneInputFormik';
import i18n from 'i18n';
import { adminTenantCompanyQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { Card, Skeleton } from 'antd';
import { updateTenantCompany } from 'graphql/methods';
import { CountryFormikDropdownInput } from 'pages/shoppingCartManagement/ShoppingCart/ContactData/components/Inputs';
import { defaults } from 'lodash';
import classes from './CompanySetting.module.less';
import CompanySettingHelperWidget from './CompanySettingHelperWidget';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const schema = ({ isOnboarding }) => ({
  companyName: Yup.string().required().label(i18n.t('admin.Setting.TenantCompany.inputs.name.title')),
  street: Yup.string().required().label(i18n.t('admin.Setting.TenantCompany.inputs.street.title')),
  streetNr: Yup.string().label(i18n.t('admin.Setting.TenantCompany.inputs.houseNumber.title')),
  cityCode: Yup.string()
    .transform((value) => value || '')
    .required()
    .label(i18n.t('admin.Setting.TenantCompany.inputs.cityCode.title')),
  city: Yup.string().required().label(i18n.t('admin.Setting.TenantCompany.inputs.city.title')),
  phoneNr: PhoneInputValidationSchema({ required: true }).label(
    i18n.t('admin.Setting.TenantCompany.inputs.phoneNumber.title'),
  ),
  country: Yup.string().required().label(i18n.t('admin.Setting.TenantCompany.inputs.country.title')),
  officeEmail: Yup.string().required().email().label(i18n.t('admin.Setting.TenantCompany.inputs.officeEmail.title')),
  websiteUrl: (() => {
    const websiteUrl = Yup.string().simplifiedUrl().label(i18n.t('admin.Setting.TenantCompany.inputs.website.title'));
    if (isOnboarding) return websiteUrl.required();
    return websiteUrl;
  })(),
  creditorIdentNr: Yup.string().nullable().label(i18n.t('admin.Setting.TenantCompany.inputs.creditorIdentNr.title')),
});

export const defaultValues = {
  companyName: '',
  street: '',
  streetNr: '',
  cityCode: '',
  city: '',
  phoneNr: '49-',
  country: 'DE',
  websiteUrl: '',
  officeEmail: '',
  creditorIdentNr: '',
};

export const updateCompanySettingSchema = ({ isOnboarding } = {}) => Yup.object().shape(schema({ isOnboarding }));
const useInitialValues = () => {
  const { data, loading } = useCachedQuery(adminTenantCompanyQuery);
  const company = grabFirstGQLDataResult(data);

  const initialValues = useMemo(() => defaults({}, company, defaultValues), [company]);
  return { initialValues, loading, initialLoading: !data && loading };
};
function CompanySetting() {
  const { initialValues, initialLoading } = useInitialValues();
  const onSubmit = useCallback((values) => updateTenantCompany(values), []);

  if (initialLoading) return skeletons.map((k) => <Skeleton title loading active key={k} />);
  return (
    <Card>
      <CompanySettingForm initialValues={initialValues} onSubmit={onSubmit} />
    </Card>
  );
}
function CompanySettingForm({ initialValues, onSubmit }) {
  return (
    <I18nFormik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={updateCompanySettingSchema}
      enableReinitialize
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <CompanySettingsFormFields />
        <SaveChangesButton initialValues={initialValues} />
      </Form>
    </I18nFormik>
  );
}

export function CompanySettingsFormFields() {
  const { t } = useTranslation();

  return (
    <>
      <FormItem name="companyName" label={t('admin.Setting.TenantCompany.inputs.name.title')}>
        <Input name="companyName" />
      </FormItem>
      <div className={classes.group}>
        <div className="flex-3">
          <FormItem name="street" label={t('admin.Setting.TenantCompany.inputs.street.title')}>
            <Input name="street" />
          </FormItem>
        </div>
        <div className="flex-1">
          <FormItem name="streetNr" label={t('admin.Setting.TenantCompany.inputs.houseNumber.title')}>
            <Input name="streetNr" />
          </FormItem>
        </div>
      </div>
      <div className={classes.group}>
        <div className="flex-1">
          <FormItem name="cityCode" label={t('admin.Setting.TenantCompany.inputs.cityCode.title')}>
            <InputNumber min={0} maxLength={9} name="cityCode" controls={false} />
          </FormItem>
        </div>
        <div className="flex-3">
          <FormItem name="city" label={t('admin.Setting.TenantCompany.inputs.city.title')}>
            <Input name="city" />
          </FormItem>
        </div>
      </div>
      <CountryFormikDropdownInput />
      <FormItem name="phoneNr" label={t('admin.Setting.TenantCompany.inputs.phoneNumber.title')}>
        <PhoneNumber name="phoneNr" maxLength={60} />
      </FormItem>
      <FormItem name="officeEmail" label={t('admin.Setting.TenantCompany.inputs.officeEmail.title')}>
        <Input name="officeEmail" />
      </FormItem>
      <FormItem name="websiteUrl" label={t('admin.Setting.TenantCompany.inputs.website.title')}>
        <Input name="websiteUrl" />
      </FormItem>
      <FormItem name="creditorIdentNr" label={t('admin.Setting.TenantCompany.inputs.creditorIdentNr.title')}>
        <Input name="creditorIdentNr" />
      </FormItem>
    </>
  );
}

function CompanySettingWrapper() {
  const { t } = useTranslation();
  return (
    <PageContainer title={t('admin.Setting.TenantCompany.title')} left={<CompanySettingHelperWidget />}>
      <CompanySetting />
    </PageContainer>
  );
}

export default memo(CompanySettingWrapper, equal);
