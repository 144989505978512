import PageContainer from 'components/layout/PageContainer';
import { useTranslation } from 'react-i18next';
import { Card, Result } from 'antd';
import { useState, useCallback } from 'react';
import filter from 'lodash/filter';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import * as Yup from 'yup';
import merge from 'lodash/merge';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { importCompaniesFromCSV } from 'graphql/methods';
import companyFields from './constants/companyFields';
import ImportCompaniesForm from './ImportCompaniesForm';

const validationSchema = (_companyFields) => {
  const shape = {
    defaultCompanyType: Yup.string(),
    ...merge(
      ..._companyFields.map((companyField) => {
        return { [companyField.columnTarget]: companyField.required ? Yup.string().required() : Yup.string() };
      }),
    ),
  };
  const schema = Yup.object().shape(shape);
  return schema;
};

const getCompaniesFromCSV = ({ companiesData, values }) => {
  return companiesData.map((companyData) => {
    const newCompanyData = {};
    companyFields.forEach((requiredField) => {
      newCompanyData[requiredField.columnTarget] = companyData[values[requiredField.columnTarget]];
    });
    if (!values.importCompanyType) {
      if (values.defaultCompanyType) newCompanyData.type = values.defaultCompanyType;
    } else {
      newCompanyData.type = companyData[values.importCompanyType] ? 'company' : 'individual';
    }
    const {
      street,
      houseNumber,
      cityCode,
      city,
      country,
      commercialObject,
      importAcademicTitle,
      importCompanyType,
      importEmail,
      importSalutation,
      ...data
    } = newCompanyData;
    return {
      ...data,
      address: { street, houseNumber, cityCode, city, country },
      importFields: { importAcademicTitle, importCompanyType, importEmail, importSalutation, commercialObject },
    };
  });
};

const ImportCompanies = () => {
  const { t } = useTranslation();
  const [companiesData, setCompaniesData] = useState([]);
  const [onComplete, setOnComplete] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSubmit = useCallback(
    async (values) => {
      const newCompaniesData = getCompaniesFromCSV({ companiesData, values });
      const selectedCompanies2 = filter(newCompaniesData, (element, index) => selectedRowKeys?.includes(index));
      const { data } = await importCompaniesFromCSV(selectedCompanies2);
      const result = grabFirstGQLDataResult(data);
      setOnComplete(result);
    },
    [companiesData, selectedRowKeys],
  );

  return (
    <PageContainer title={t(`admin.Setting.ImportCompanies.${!onComplete ? 'title' : 'completePage.title'}`)}>
      {!onComplete ? (
        <I18nFormik onSubmit={onSubmit} initialValues={{}} validationSchema={validationSchema(companyFields)}>
          <ImportCompaniesForm
            companiesData={companiesData}
            selectedRowKeys={selectedRowKeys}
            setCompaniesData={setCompaniesData}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </I18nFormik>
      ) : (
        <Card>
          <Result
            status="success"
            title={
              <p>
                {t('admin.Setting.ImportCompanies.completePage.numberInsert', {
                  count: onComplete?.numberInsert || 0,
                })}
                <br />
                {t('admin.Setting.ImportCompanies.completePage.numberUpdate', {
                  count: onComplete?.numberUpdate || 0,
                })}
              </p>
            }
            subTitle={<p>{t('admin.Setting.ImportCompanies.completePage.tip')}</p>}
          />
        </Card>
      )}
    </PageContainer>
  );
};

export default ImportCompanies;
