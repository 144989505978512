import React, { memo, useMemo, useCallback } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useCachedQuery } from 'graphql/utils';
import { Transfer, Tooltip } from 'antd';
import { Radio } from 'formik-antd';
import equal from 'fast-deep-equal/es6/react';
import { adminDocumentTemplateShowOrHideItemsQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import classes from './ConfigurationShowHideItems.module.less';

/**
 * @typedef {Object} ReadableTexts
 * @property {string} name
 */

/**
 * @typedef {Object} ItemWithReadableTexts
 * @property {ReadableTexts} readableTexts
 */

/**
 * @typedef {Object} ItemWithName
 * @property {string} name
 * @property {boolean} [active]
 */

/**
 * @typedef {ItemWithReadableTexts | ItemWithName} Item
 * @property {string} _id
 */

/**
 * @typedef {Object} Category
 * @property {string} _id
 * @property {string} name
 * @property {Item[]} [items]
 * @property {Item[]} [staticItems]
 */

/**
 * Formats the display string for an item.
 * @param {Category} category
 * @param {Item} item
 * @returns {string} Formatted string in the format "CategoryName > ItemName".
 */
const formatItem = (category, item, isStaticItem, t) =>
  `${category.name} > ${
    isStaticItem ? t('admin.DocumentTemplateConfigurationPage.selectItemsToShowOrHide.transfer.staticPrefix') : ''
  }${item.name || item.readableTexts.name}`;

/**
 * ConfigurationShowHideItems component allows users to select items to show or hide.
 *
 * @param {Object} props
 * @param {string} props.name
 * @returns {React.Component}
 */
export const ConfigurationShowHideItems = memo(({ name, showStaticItems }) => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(adminDocumentTemplateShowOrHideItemsQuery);

  /** @type Category */
  const categories = grabFirstGQLDataResult(data);

  const { value: selectedItems, onChange: onSelectedItemsChange } = useFormikField(`${name}.selectedItems`);
  const { value: displayMode, onChange: onDisplayModeChange } = useFormikField(`${name}.displayMode`);

  const dataSource = useMemo(() => {
    if (!categories) return [];
    return categories.flatMap((category) => [
      // Map regular items
      ...(category.items || [])
        .filter((item) => item.active)
        .map((item) => ({
          key: `${category._id}:${item._id}:false`,
          title: formatItem(category, item),
          category: category._id,
        })),
      // Map static items
      ...(category.staticItems || [])
        .filter(() => showStaticItems)
        .map((item) => ({
          key: `${category._id}:${item._id}:true`,
          title: formatItem(category, item, true, t),
          category: category._id,
        })),
    ]);
  }, [categories, showStaticItems, t]);

  const handleChange = useCallback(
    (nextTargetKeys) => {
      // Format the selected items for storage
      const formattedSelectedItems = nextTargetKeys.map((key) => {
        const [categoryId, itemId, isStaticItem] = key.split(':');
        return { categoryId, itemId, isStaticItem: isStaticItem === 'true' };
      });
      onSelectedItemsChange(formattedSelectedItems);
    },
    [onSelectedItemsChange],
  );

  const handleDisplayModeChange = useCallback(
    (e) => {
      onDisplayModeChange(e.target.value);
    },
    [onDisplayModeChange],
  );

  /**
   * Renders an individual item in the Transfer component.
   * @param {Object} item
   * @returns {Object}
   */
  const renderItem = useCallback((item) => {
    const { title } = item;
    return {
      label: (
        <Tooltip placement="topLeft" title={title}>
          <span>{title}</span>
        </Tooltip>
      ),
      value: '',
    };
  }, []);

  const targetKeys = useMemo(() => {
    return (selectedItems || []).map((item) => `${item.categoryId}:${item.itemId}:${item.isStaticItem}`);
  }, [selectedItems]);

  /**
   * Custom filter function for the Transfer components search feature.
   * @param {string} inputValue
   * @param {Object} option
   * @returns {boolean}
   */
  const filterOption = useCallback((inputValue, option) => {
    const words = inputValue.split(' ');
    return words.every((word) => option.title.toLowerCase().indexOf(word.toLowerCase()) > -1);
  }, []);

  return (
    <div className={classes.holder}>
      <div className="align-center">
        <span style={{ fontStyle: 'italic' }}>
          {t('admin.DocumentTemplateConfigurationPage.selectItemsToShowOrHide.title')}
        </span>
        <span style={{ marginLeft: '8px' }}>
          <Tooltip
            title={t('admin.DocumentTemplateConfigurationPage.selectItemsToShowOrHide.tooltip')}
            autoAdjustOverflow
          >
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      </div>

      <Radio.Group
        className={classes.radioGroup}
        name={`${name}.displayMode`}
        value={displayMode || 'show'}
        onChange={handleDisplayModeChange}
      >
        <Radio.Button value="show">
          {t('admin.DocumentTemplateConfigurationPage.selectItemsToShowOrHide.switch.show')}
        </Radio.Button>
        <Radio.Button value="hide">
          {t('admin.DocumentTemplateConfigurationPage.selectItemsToShowOrHide.switch.hide')}
        </Radio.Button>
      </Radio.Group>

      <Transfer
        showSearch
        className={classes.styledTransfer}
        dataSource={dataSource}
        filterOption={filterOption}
        targetKeys={targetKeys}
        onChange={handleChange}
        render={renderItem}
        loading={loading}
        titles={[
          t('admin.DocumentTemplateConfigurationPage.selectItemsToShowOrHide.transfer.leftTitle'),
          t(
            `admin.DocumentTemplateConfigurationPage.selectItemsToShowOrHide.transfer.rightTitle.${
              displayMode || 'show'
            }`,
          ),
        ]}
      />
    </div>
  );
}, equal);
