import apollo from 'graphql/apollo';
import { updateShoppingCartMutation } from 'graphql/mutations';

const updateShoppingCart = async ({ _id, modifier: { statusId } }) => {
  // console.debug('Status id ', _id, '; ', statusId);
  return apollo.mutate({
    mutation: updateShoppingCartMutation,
    variables: {
      _id,
      modifier: {
        statusId,
      },
    },
  });
};

export default updateShoppingCart;
