import { gql } from '@apollo/client';

const getProjectCardsOfClientQuery = gql`
  query getProjectCardsOfClient($clientId: ID!) {
    getProjectCardsOfClient(clientId: $clientId) {
      _id
      name
      digitalSignatureState
      createdAt
      showDigits
      migrated
      isLatest
      contacts {
        _id
        _apolloCacheKey
        firstName
        lastName
      }
      oneOff {
        _id
        _apolloCacheKey
        discountedValue
        discountedVAT
      }
      monthly {
        _id
        _apolloCacheKey
        discountedValue
        discountedVAT
      }
      yearly {
        _id
        _apolloCacheKey
        discountedValue
        discountedVAT
      }
      status {
        name
        _id
      }
    }
  }
`;

export default getProjectCardsOfClientQuery;
