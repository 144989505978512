import { Input } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

export const SignaturePageOptionNoText = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.MonthlyPaymentPreferences.signatureSettings.signaturePageOptionNo.label')}
      tooltip={t('admin.Setting.MonthlyPaymentPreferences.signatureSettings.signaturePageOptionNo.tooltip')}
      name="signatureSettings.optionPaySeparatelyText"
    >
      <Input name="signatureSettings.optionPaySeparatelyText" />
    </FormItem>
  );
};
