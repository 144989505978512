import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { ErrorMessage } from 'formik';
import cn from 'classnames';
import { IoWarning } from 'react-icons/io5';
import classes from './ShoppingCartFormItem.module.less';

export const RequiredFieldsAlertWrapper = memo(({ children, className }) => {
  return (
    <div className={cn(classes.requiredAlert, className)}>
      <div>
        <IoWarning size={20} />
      </div>
      <div>{children}</div>
    </div>
  );
}, equal);

const ShoppingCartFormItem = ({ name, label, children, ...props }) => {
  return (
    <FormItem name={name} label={label} help=" " {...props}>
      {children}
      <div className={classes.errorMessageContainer}>
        <ErrorMessage component={RequiredFieldsAlertWrapper} name={name} />
      </div>
    </FormItem>
  );
};

export default memo(ShoppingCartFormItem, equal);
