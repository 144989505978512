import { Input } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

export const HelpTextInput = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.helpText.label')}
      name="shoppingCartSettings.helpText"
      tooltip={t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.helpText.tooltip')}
    >
      <Input.TextArea name="shoppingCartSettings.helpText" autoSize />
    </FormItem>
  );
};
