import { message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import classes from './CloseableMessage.module.less';

/**
 * Content of the closeable message with message and close button
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.key - Unique key for the message
 * @param {string} inputProperties.messageText - Text to display in the message
 * @returns {JSX.Element} A closeable message component
 */
const MessageContent = ({ key, messageText }) => {
  return (
    <div className={classes.contentContainer}>
      <CloseOutlined className={classes.closeButton} onClick={() => message.destroy(key)} />
      <p>{messageText}</p>
    </div>
  );
};

/**
 * Generates config for a message to be displayed as closeable message
 * @param {string} messageText - Text to display in the message
 * @param {string} key - Unique key for the message
 * @param {number} duration - Duration of the message in seconds
 * @returns {object} Config for the message to parse to the message function of antd
 */
const generateMessageConfig = (messageText, key, duration = 15) => {
  return {
    content: <MessageContent key={key} messageText={messageText} />,
    className: classes.message,
    duration,
    key,
  };
};

export default generateMessageConfig;
