import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared';

import { useCachedQuery } from 'graphql/utils';
import { userEditShoppingCartQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import RevokeDigitalSignatureProcessModal from 'components/user/RevokeDigitalSignatureProcessModal/RevokeDigitalSignatureProcessModal';
import EditShoppingCartModal from 'components/user/shoppingCart/modals/EditShoppingCartModal';

/**
 * Shows edit button and handles the edit modal for the project card
 * @param {object} inputProperties - Input properties of the component
 * @param {React.ReactNode} inputProperties.editButton - Edit button component
 * @param {string} inputProperties._id - ID of the project card
 * @param {boolean} inputProperties.disabled - Whether the edit button is disabled
 * @param {string} inputProperties.className - Class name of the button
 * @param {object} inputProperties.projectCard - Project card object
 * @returns {JSX.Element} Edit button with modal handler
 * @component
 */
const EditModalHandler = ({ editButton: ButtonToOpen, _id, disabled, className, projectCard }) => {
  const navigate = useNavigate();
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const { data, loading } = useCachedQuery(userEditShoppingCartQuery, {
    variables: { _id },
    fetchPolicy: 'cache-and-network',
    skip: !!projectCard,
  });

  const { isLatest, migrated, digitalSignatureState } = useMemo(() => {
    if (projectCard) {
      return projectCard;
    }
    return grabFirstGQLDataResult(data) ?? {};
  }, [data, projectCard]);

  const openEditShoppingCartRevisionModal = () => {
    if (digitalSignatureState === DIGITAL_SIGNATURE_STATE.STARTED) setShowRevokeModal(true);
    else setIsShowEditModal(true);
  };

  const hideEditModal = () => {
    setIsShowEditModal(false);
  };

  const closeRevokeModal = () => {
    setShowRevokeModal(false);
  };

  if ((data || projectCard) && !isLatest) return null;

  return (
    <>
      <ButtonToOpen
        disabled={(!data && !projectCard && loading) || disabled}
        loading={!data && loading && !projectCard}
        onClick={openEditShoppingCartRevisionModal}
        className={className}
      />
      {showRevokeModal ? (
        <RevokeDigitalSignatureProcessModal
          shoppingCartId={_id}
          onCloseModal={closeRevokeModal}
          onRevokeStarted={() => setIsShowEditModal(true)}
          isForEditShoppingCart
        />
      ) : null}
      <EditShoppingCartModal
        _id={_id}
        navigate={navigate}
        isShowEditModal={isShowEditModal}
        hideModal={hideEditModal}
        migrated={migrated}
      />
    </>
  );
};

export default EditModalHandler;
