import { useFormikField } from 'hooks/common/useFormikField';

/**
 * Shows children components only if the type of the client is company
 * @param {object} inputProperties - Input properties of the component
 * @param {React.ReactNode} inputProperties.children - Children of the component
 * @returns {React.ReactNode} Shows only the children if the type is company
 */
const CompanyFields = ({ children }) => {
  const { value: type } = useFormikField('type');

  if (type !== 'company') return null;

  return children;
};

export default CompanyFields;
