import { Tabs as AntdTabs } from 'antd';
import classes from './Tabs.module.less';

const Tabs = ({ withUnderline = true, ...props }) => {
  if (!withUnderline) {
    return <AntdTabs {...props} className={classes.tabs__not_underlined} />;
  }
  return <AntdTabs {...props} />;
};

Tabs.TabPane = AntdTabs.TabPane;

export default Tabs;

export const NotUnderlinedSmallTabs = ({ ...props }) => <Tabs size="small" withUnderline={false} {...props} />;
