import { Input as FormikInput } from 'formik-antd';

/**
 * Input component for forms with formik support
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.name - Name of the field in the form data
 * @param {string} inputProperties.placeholder - Placeholder text for the input
 * @returns {JSX.Element} Input component
 * @component
 */
const Input = ({ name, placeholder, ...props }) => {
  return <FormikInput name={name} placeholder={placeholder} {...props} />;
};

export default Input;
