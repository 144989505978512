import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Tabs, { NotUnderlinedSmallTabs } from 'components/common/Tabs';
import { modifySearchParameters } from 'utils/modifySearchParameters';
import ProjectCardsTab from './ProjectCardsTab';
import DocumentsTab from './DocumentsTab';
import ClientHistoryTab from './ClientHistoryTab';

const { TabPane } = Tabs;

/**
 * Tab view for client view with multiple tabs for client data like project cards, etc.
 * @param {object} inputProperties - The input properties for the component
 * @param {string} inputProperties.client - The client object
 * @returns {JSX.Element} Tab view with multiple tabs for client data
 * @component
 */
const ClientDataTabs = ({ client }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage' });
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get('tab');

  const handleTabChange = (key) => {
    modifySearchParameters('tab', key);
  };

  return (
    <NotUnderlinedSmallTabs defaultActiveKey={activeTab || '1'} onChange={handleTabChange}>
      <TabPane tab={t('projectCardTab.title')} key="1">
        <ProjectCardsTab client={client} />
      </TabPane>
      <TabPane tab={t('documentsTab.title')} key="2">
        <DocumentsTab client={client} />
      </TabPane>
      <TabPane tab={t('clientHistoryTab.title')} key="3">
        <ClientHistoryTab client={client} />
      </TabPane>
      {/* <TabPane tab="Aktive Leistungen" key="4">
        Aktive Leistungen
      </TabPane> */}
    </NotUnderlinedSmallTabs>
  );
};

export default ClientDataTabs;
