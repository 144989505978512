import { Checkbox, Form } from 'formik-antd';
import { memo, useCallback, useMemo } from 'react';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { filter, find, pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { adminCategoryListQuery, adminCompanyTypesQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { Skeleton, Checkbox as CheckboxAntd } from 'antd';
import { updateCategory, updateCategoryItem } from 'graphql/methods';
import { useFormikContext } from 'formik';
import MoreInfoWidget, { MoreInfoWidgetContent } from 'components/common/MoreInfoWidget';
import confirmModal from 'utils/confirmModal';
import { useLibraryContext } from 'contexts/LibraryContext';
import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import apollo from 'graphql/apollo';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

const CheckAll = () => {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  const countCheckedTypes = useMemo(() => Object.keys(pickBy(values, Boolean)).length, [values]);
  const countFields = useMemo(() => Object.keys(values).length, [values]);
  const allChecked = useMemo(() => countCheckedTypes === countFields, [countCheckedTypes, countFields]);
  const onCheck = useCallback(() => {
    const newValues = {};

    Object.keys(values).forEach((key) => {
      newValues[key] = !allChecked;
    });

    Object.keys(newValues).forEach((key) => {
      setFieldValue(key, newValues[key], false);
    });
  }, [allChecked, setFieldValue, values]);

  return (
    <CheckboxAntd
      indeterminate={countCheckedTypes !== 0 && countCheckedTypes !== countFields}
      checked={allChecked}
      onChange={onCheck}
      style={{ marginBottom: '24px' }}
    >
      {t(`common.ConditionalVisibility.${countCheckedTypes === countFields ? 'uncheckAll' : 'checkAll'}`)}
    </CheckboxAntd>
  );
};
const CheckAllMemo = memo(CheckAll, equal);

export const ConditionalVisibilityHelperWidget = ({ parentType }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.howUseButton`,
      )}
      title={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.title`,
      )}
      helpText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.helpText`,
      )}
      videoCaption={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.videoCaption`,
      )}
      videoUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.videoUrl`,
      )}
      imageUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.imageUrl`,
      )}
    />
  );
};
const ConditionalVisibilityHelperWidgetMemo = memo(ConditionalVisibilityHelperWidget, equal);

/**
 * Get the category and item from the apollo cache
 * @param {boolean} isLibrary - Is this function called on common library data
 * @param {string} parentId - The parent id
 * @returns {object} The category and item
 */
const getCategoryAndItemFromCache = (isLibrary, parentId) => {
  const categories = grabFirstGQLDataResult(
    apollo.readQuery({
      query: adminCategoryListQuery,
      variables: { isLibrary },
    }),
  );
  let item;
  const category = categories.find((c) => {
    item = c.items.find((i) => i._id === parentId);
    return item;
  });
  return { category, item };
};

/**
 * Get the items referencing the current item
 * @param {object} param - The parameters
 * @param {string} param.itemId - The item id
 * @param {object} param.category - The category
 * @returns {object[]} The items referencing the current item
 */
const getItemsReferencingCurrentItem = ({ itemId, category }) => {
  try {
    return filter(category.items, ({ pricingFormula }) => {
      const formula = Mentions.richTextToFormula(pricingFormula, { hideMentionText: true });
      return !!Mentions.getInputFieldIdsFromFormula(formula).find((_id) => _id === itemId);
    });
  } catch {
    return [];
  }
};

/**
 * Retrieves the items referenced by the current item.
 * @param {Object} param - The param object.
 * @param {Object} param.item - The current item.
 * @param {Object} param.category - The category object.
 * @returns {Array} - The items referenced by the current item.
 */
const getItemsReferencedByCurrentItem = ({ item, category }) => {
  const referencedItemIds = item.recursiveFormulaInfo.formulaRequiredItemIds;
  return referencedItemIds.map((itemId) => find(category.items, { _id: itemId }));
};

/**
 * Retrieves the items involved in a formula.
 * @param {Object} param - The param for retrieving the items.
 * @param {Object} param.item - The item for which to retrieve the involved items.
 * @param {string} param.category - The category of the item.
 * @returns {Object} - The items referencing the current item and the items referenced by the current item.
 */
const getItemsInvolvedInFormula = ({ item, category }) => {
  const itemsReferencingItem = getItemsReferencingCurrentItem({ itemId: item._id, category });
  const itemsReferencedByItem = getItemsReferencedByCurrentItem({ item, category });
  return { itemsReferencingItem, itemsReferencedByItem };
};

/**
 * Checks if the referenced items have conflicting company types with the new item.
 * @param {Array} referencedItems - The array of referenced items.
 * @param {Object} newItemCompanyTypes - The company types of the new item.
 * @returns {boolean} - Returns true if there are conflicting company types, otherwise false.
 */
const haveReferencedItemsConflictingCompanyTypes = (referencedItems, newItemCompanyTypes) => {
  // The referenced items can't be missing company types that the new item has set to true.
  return referencedItems.some((referencedItem) => {
    const { companyTypeIds } = referencedItem;
    return Object.keys(newItemCompanyTypes).some((companyTypeId) => {
      return newItemCompanyTypes[companyTypeId] && !companyTypeIds.includes(companyTypeId);
    });
  });
};

/**
 * Checks if the referencing items have conflicting company types with the new item.
 * @param {Array} referencingItems - The array of referencing items to check.
 * @param {Object} newItemCompanyTypes - The company types of the new item.
 * @returns {boolean} - Returns true if there are conflicting company types, false otherwise.
 */
const haveReferencingItemsConflictingCompanyTypes = (referencingItems, newItemCompanyTypes) => {
  // The referencing items can't have more company types than the new item.
  return referencingItems.some((referencingItem) => {
    const { companyTypeIds } = referencingItem;
    return companyTypeIds.some((companyTypeId) => {
      return !newItemCompanyTypes[companyTypeId];
    });
  });
};

const COMPANY_TYPE_CONFLICTS = {
  NONE: 'none',
  REFERENCING_ITEMS_CONFLICT: 'referencingItemsConflict',
  REFERENCED_ITEMS_CONFLICT: 'referencedItemsConflicting',
  REFERENCING_AND_REFERENCED_ITEMS_CONFLICT: 'referencingAndReferencedItemsConflict',
};

/**
 * Checks if there is a conflict in company types for the given item
 * @param {Array} newItemCompanyTypes - The company types of the new item.
 * @param {boolean} isLibrary - Is this function called on common library data
 * @param {string} parentId - The parent id
 * @returns {Object} - An object containing the items referencing the item, items referenced by the item,
 * and a flag indicating if there is a company type conflict.
 */
const doCompanyTypesConflict = (newItemCompanyTypes, isLibrary, parentId) => {
  const { category, item } = getCategoryAndItemFromCache(isLibrary, parentId);

  const { itemsReferencingItem, itemsReferencedByItem } = getItemsInvolvedInFormula({ item, category });

  const referencingItemsConflicting = haveReferencingItemsConflictingCompanyTypes(
    itemsReferencingItem,
    newItemCompanyTypes,
  );
  const referencedItemsConflicting = haveReferencedItemsConflictingCompanyTypes(
    itemsReferencedByItem,
    newItemCompanyTypes,
  );

  let companyTypeConflict = COMPANY_TYPE_CONFLICTS.NONE;
  if (referencingItemsConflicting && referencedItemsConflicting) {
    companyTypeConflict = COMPANY_TYPE_CONFLICTS.REFERENCING_AND_REFERENCED_ITEMS_CONFLICT;
  } else if (referencingItemsConflicting) {
    companyTypeConflict = COMPANY_TYPE_CONFLICTS.REFERENCING_ITEMS_CONFLICT;
  } else if (referencedItemsConflicting) {
    companyTypeConflict = COMPANY_TYPE_CONFLICTS.REFERENCED_ITEMS_CONFLICT;
  }

  return {
    itemsReferencingItem,
    itemsReferencedByItem,
    companyTypeConflict,
  };
};

const resetForm = (formik, initialValues) => {
  formik.resetForm({
    values: initialValues,
  });
};

/**
 * Displays a modal to inform about a company type conflict and provides options to resolve it.
 * @param {object} formik - The Formik object.
 * @param {array} newCompanyTypeIds - An array of new company type IDs.
 * @param {array} relatedItems - An array of related items.
 * @param {object} initialValues - The initial values of the form.
 * @param {(key: string) => string} t - The translation function.
 */
const informCompanyTypeConflictModal = (
  formik,
  newCompanyTypeIds,
  relatedItems,
  initialValues,
  t,
  triggerUpdateCateogryItem,
) => {
  const formattedRelatedItems = (
    <ul>
      {relatedItems.map((item) => (
        <li>{item.name}</li>
      ))}
    </ul>
  );

  const formattedContetnt = (
    <>
      <p>{t('common.ConditionalVisibility.ConflictModal.content.intro')}</p>
      {formattedRelatedItems}
      <p>{t('common.ConditionalVisibility.ConflictModal.content.callForAction')}</p>
      <MoreInfoWidgetContent videoUrl={t('common.ConditionalVisibility.ConflictModal.videoUrl')} hideBook />
    </>
  );

  confirmModal({
    title: t('common.ConditionalVisibility.ConflictModal.title'),
    content: formattedContetnt,
    okText: t('common.ConditionalVisibility.ConflictModal.okText'),
    onOk: () => triggerUpdateCateogryItem(newCompanyTypeIds),
    onCancel: () => resetForm(formik, initialValues),
    width: 800,
  });
};

/**
 * Shows the correct conflict modal based on the company type conflict
 * @param {object} inputParameters - The input parameters
 * @param {string} inputParameters.companyTypeConflict - The company type conflict
 * @param {object} inputParameters.formik - The formik object
 * @param {object} inputParameters.newCompanyTypeIds - Object with all company type ids with the selected one with true as value
 * @param {object[]} inputParameters.itemsReferencedByItem - The items referenced by the item
 * @param {object[]} inputParameters.itemsReferencingItem - The items referencing the item
 * @param {object} inputParameters.initialValues - The initial values of the form
 * @param {(key: string) => string} inputParameters.t - The translation function
 * @param {(key: string) => string} inputParameters.triggerUpdateCateogryItem - The function to trigger the update of the category item
 */
const showConflictModal = ({
  companyTypeConflict,
  formik,
  newCompanyTypeIds,
  itemsReferencedByItem,
  itemsReferencingItem,
  initialValues,
  t,
  triggerUpdateCateogryItem,
}) => {
  switch (companyTypeConflict) {
    case COMPANY_TYPE_CONFLICTS.REFERENCING_AND_REFERENCED_ITEMS_CONFLICT:
      informCompanyTypeConflictModal(
        formik,
        newCompanyTypeIds,
        [...itemsReferencedByItem, ...itemsReferencingItem],
        initialValues,
        t,
        triggerUpdateCateogryItem,
      );
      break;
    case COMPANY_TYPE_CONFLICTS.REFERENCING_ITEMS_CONFLICT:
      informCompanyTypeConflictModal(
        formik,
        newCompanyTypeIds,
        itemsReferencingItem,
        initialValues,
        t,
        triggerUpdateCateogryItem,
      );
      break;
    case COMPANY_TYPE_CONFLICTS.REFERENCED_ITEMS_CONFLICT:
      informCompanyTypeConflictModal(
        formik,
        newCompanyTypeIds,
        itemsReferencedByItem,
        initialValues,
        t,
        triggerUpdateCateogryItem,
      );
      break;
    default:
  }
};

/**
 * The ConditionalVisibilityTab where users can set the company types for an item or category affecting the visibility in a shoppingcart
 * @param {object} param - The props
 * @param {string} param.parentId - The id of the parent aka the item or category
 * @param {string} [param.parentType='category'] - The type of the parent
 * @param {string[]} param.companyTypeIds - The company type ids
 * @returns {React.Component} The ConditionalVisibilityTab component
 * @component
 */
const ConditionalVisibilityTab = ({
  parentId,
  parentType = 'category',
  companyTypeIds: initialCompanyTypeIds,
  formikRef,
}) => {
  const { data, loading } = useCachedQuery(adminCompanyTypesQuery);
  const companyTypes = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const { t } = useTranslation();
  const initialValues = useMemo(() => {
    if (!companyTypes) return {};
    const defaultCompanyTypes = companyTypes.reduce((acc, { _id }) => {
      acc[_id] = false;
      return acc;
    }, {});
    initialCompanyTypeIds.forEach((e) => {
      defaultCompanyTypes[e] = true;
    });
    return defaultCompanyTypes;
  }, [initialCompanyTypeIds, companyTypes]);

  const { isLibrary } = useLibraryContext();

  /**
   * Handles the form submission, possibly displaying a modal to inform about a company type conflict.
   * @param {Object} values - The form values.
   * @param {Object} formik - The formik object.
   */
  // eslint-disable-next-line consistent-return
  const onSubmit = (values, formik) => {
    if (parentType === 'category')
      updateCategory({ _id: parentId, modifier: { companyTypeIds: Object.keys(pickBy(values, Boolean)) } });
    if (parentType === 'categoryItem') {
      const { ...newCompanyTypeIds } = values;

      const { itemsReferencingItem, itemsReferencedByItem, companyTypeConflict } = doCompanyTypesConflict(
        newCompanyTypeIds,
        isLibrary,
        parentId,
      );

      if (companyTypeConflict === COMPANY_TYPE_CONFLICTS.NONE) {
        triggerUpdateCateogryItem(newCompanyTypeIds);
        return;
      }

      showConflictModal({
        companyTypeConflict,
        formik,
        newCompanyTypeIds,
        itemsReferencedByItem,
        itemsReferencingItem,
        initialValues,
        t,
        triggerUpdateCateogryItem,
      });

      throw new Error('COMPANY_TYPE_CONFLICTS'); // ! This prevents the modal from navigating to the next item
    }
  };

  const triggerUpdateCateogryItem = (newCompanyTypeIds) => {
    return updateCategoryItem({
      _id: parentId,
      companyTypeIds: Object.keys(pickBy(newCompanyTypeIds, Boolean)),
    });
  };

  return (
    <>
      <ConditionalVisibilityHelperWidgetMemo parentType={parentType} />
      <I18nFormik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} ref={formikRef}>
        <Form layout="vertical">
          <AlertFromFormik />
          <h4>
            {t('admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.bodyTitle')}
          </h4>
          <CheckAllMemo />
          {skeletons.map((k) => (
            <Skeleton title loading={!data && loading} active key={k} />
          ))}
          {companyTypes?.map(({ label, _id }) => (
            <FormItem name={_id} key={_id}>
              <Checkbox name={_id}>{t(label)}</Checkbox>
            </FormItem>
          ))}
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </>
  );
};
export default memo(ConditionalVisibilityTab, equal);
