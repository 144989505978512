import AccessGroupsMultiSelect from 'components/common/AccessGroupsMultiSelect';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import { Form } from 'formik-antd';
import { updateCategory } from 'graphql/methods';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';
import toast from 'utils/toast';

/**
 * Tab content for category configuration is common library
 * @param {Object} inputParameters - Input properties of the component
 * @param {string} inputParameters._id - ID of the category
 * @param {string[]} inputParameters.commonLibraryAccessGroups - List of access groups of the category
 * @returns {JSX.Element} tab content for category configuration, which is only used in common library for common library settings
 * @component
 */
const CommonLibraryTab = ({ _id, commonLibraryAccessGroups }) => {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      commonLibraryAccessGroups: commonLibraryAccessGroups || [],
    }),
    [commonLibraryAccessGroups],
  );
  return (
    <I18nFormik
      initialValues={initialValues}
      onSubmit={(values, formik) =>
        updateCategory({ _id, modifier: values })
          .then((response) => {
            const { data } = response;
            const warnings = grabFirstGQLDataResult(data)?.warnings;
            warnings &&
              warnings.forEach((warning) => toast.warning(t(`BackendWarnings.${warning.key}`, warning.variables)));
            return response;
          })
          .catch(formik.setErrors)
      }
      enableReinitialize
    >
      <Form layout="vertical">
        <AccessGroupsMultiSelect
          fieldName="commonLibraryAccessGroups"
          label={t(
            'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.commonLibrary.accessGroups.label',
          )}
          tooltip={t(
            'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.commonLibrary.accessGroups.tooltip',
          )}
          placeholder={t(
            'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.commonLibrary.accessGroups.placeholder',
          )}
        />
        <SaveChangesButton initialValues={initialValues} />
      </Form>
    </I18nFormik>
  );
};

export default CommonLibraryTab;
