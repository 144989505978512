import { InputNumber } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import classes from './AutomaticRemindSigneesAboutSignatureProcessIntervalInput.module.less';

/**
 * AutomaticRemindSigneesAboutSignatureProcessIntervalInput component is the input field for notification interval for the auto reminder emails (Needs formik context)
 * @returns {JSX.Element} number input field for the automate signature process card for the digital signature preference page
 * @component
 */
const AutomaticRemindSigneesAboutSignatureProcessIntervalInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem
      name="automaticRemindSigneesAboutSignatureProcessInterval"
      label={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.automaticRemindSigneesAboutSignatureProcessInterval.label',
      )}
      tooltip={t(
        'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.automaticRemindSigneesAboutSignatureProcessInterval.tooltip',
      )}
    >
      <InputNumber
        name="automaticRemindSigneesAboutSignatureProcessInterval"
        addonAfter={t(
          'admin.Setting.DigitalSignaturePreferences.automateSignatureProcess.automaticRemindSigneesAboutSignatureProcessInterval.unit',
        )}
        className={classes.input}
        min={1}
        controls={false}
      />
    </FormItem>
  );
};

export default AutomaticRemindSigneesAboutSignatureProcessIntervalInput;
