import { Radio } from 'formik-antd';

const { Button, Group } = Radio;

/**
 * Radio buttons component with formik support
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.name - Name of the field in the form data
 * @param {array} inputProperties.options - Options for the radio buttons
 * @param {string} inputProperties.options.label - Label of the radio button
 * @param {string} inputProperties.options.value - Value of the radio button
 * @param {object} inputProperties.groupProps - Additional group properties (See antd design doc for radio component for more information)
 * @param {object} inputProperties.buttonProps - Additional button properties (See antd design doc for radio component for more information
 * @returns {JSX.Element} Input component
 */
const RadioButtons = ({ name, options, groupProps, buttonProps }) => {
  return (
    <Group name={name} {...groupProps}>
      {options.map((option) => (
        <Button key={option.value} value={option.value} {...buttonProps}>
          {option.label}
        </Button>
      ))}
    </Group>
  );
};

export default RadioButtons;
