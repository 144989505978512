import React, { useMemo } from 'react';
import { Modal, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import { useFunctionToRefCB } from 'memo';
import InputConfirmForm from './InputConfirmForm';

// const NotFormik = (props) => {
//   const { children, fc } = props;
//   if (fc) return <FormikContext.Provider value={fc}>{children(fc)}</FormikContext.Provider>;
//   return children(fc);
// };
const InputConfirmModal = (props, formikRef) => {
  const {
    footer: Footer,
    bodyText,
    closeModal,
    onClose,
    fields,
    forceMultiField,
    handleClose,
    headerText,
    isClosing,
    isSubmitting,
    cancelText,
    okText,
    // notRenderFormik,
    onSubmit,
    validationSchema,
    value,
    width,
    height,
    labelCol,
    onChange,
    formContent,
    // formikContext,
    ContainerComponent = Modal,
    extraContainerProps = {},
    showAlert,
    alertType,
    alertMessage,
    alertDescription,
  } = props;
  const FormikComponent = /* notRenderFormik ? NotFormik : */ I18nFormik;
  const { t } = useTranslation();
  const onSubmitForm = useFunctionToRefCB((e) => {
    e.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment
    window.setTimeout(() => formikRef?.current?.submitForm(), 0);
  });
  const footer = useMemo(() => {
    if (Footer) return <Footer onClose={onClose} onSubmit={onSubmitForm} />;
    return null;
  }, [Footer, onClose, onSubmitForm]);
  return (
    <FormikComponent
      ref={formikRef}
      closeForm={closeModal}
      initialValues={fields.length === 1 && !forceMultiField ? { [fields[0].name]: value } : value}
      onReset={closeModal}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      // fc={formikContext}
    >
      <ContainerComponent
        afterClose={closeModal}
        onClose={onClose}
        confirmLoading={isSubmitting}
        onCancel={handleClose}
        onOk={onSubmitForm}
        title={headerText}
        visible={!isClosing}
        width={width}
        height={height}
        okText={okText || t('common.ok')}
        cancelText={cancelText || t('common.cancel')}
        className="input-confirm-modal"
        {...(footer ? { footer } : {})}
        {...extraContainerProps}
      >
        {showAlert ? (
          <Alert
            type={alertType}
            message={alertMessage}
            description={alertDescription}
            showIcon
            className={alertType === 'info' ? 'alert-info' : ''}
          />
        ) : null}
        <InputConfirmForm
          bodyText={bodyText}
          fields={fields}
          formContent={formContent}
          handleSubmit={onSubmitForm}
          labelCol={labelCol}
          onChange={onChange}
        />
      </ContainerComponent>
    </FormikComponent>
  );
};

export default React.forwardRef(InputConfirmModal);
