import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { Button, Card, Skeleton } from 'antd';
import { DeleteOutlined, SelectOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { deleteTenantLogo, updateTenantLogo } from 'graphql/methods';
import { Formik } from 'formik';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import { useFormikField } from 'hooks/common/useFormikField';
import FormikImage from 'components/common/FormikImage';
import { tenantLogoQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import classes from './CorporateLogo.module.less';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

export const SelectLogoButton = () => {
  const { t } = useTranslation();

  return (
    <Button type="primary" icon={<SelectOutlined />}>
      {t('admin.Setting.CorporateIdentity.TenantLogo.select')}
    </Button>
  );
};

export const RemoveLogoButton = () => {
  const { t } = useTranslation();
  const { value, onChange } = useFormikField('logo');
  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  if (!value) return null;
  return (
    <Button onClick={onClick} icon={<DeleteOutlined />}>
      {t('admin.Setting.CorporateIdentity.TenantLogo.remove')}
    </Button>
  );
};

const Control = ({ initialValues, autosaveEnabled, onSaveClicked }) => {
  return (
    <>
      <SelectLogoButton />
      <RemoveLogoButton />
      <SaveChangesButton
        withDisable
        initialValues={initialValues}
        autosaveEnabled={autosaveEnabled}
        onClick={onSaveClicked}
      />
    </>
  );
};

function CorporateLogo({
  noCardWrap,
  containerClassName = classes.containerFormikImage,
  uploadClassName = classes.controlImage,
  name = 'logo',
  autosaveEnabled,
  hideFallback,
  onChange,
}) {
  const { data, loading } = useCachedQuery(tenantLogoQuery);
  const [me] = useCurrentUser();

  useEffect(() => {
    if (!loading) typeof onChange === 'function' && onChange(grabFirstGQLDataResult(data));
  }, [data, loading, onChange]);

  const initialValues = useMemo(() => ({ [name]: grabFirstGQLDataResult(data) }), [data, name]);

  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (values, formik) => {
      try {
        const logo = values[name];
        formik.setSubmitting(true);
        if (logo) await updateTenantLogo({ logo, tenantId: me.tenantId });
        else await deleteTenantLogo();
      } catch (error) {
        console.error(error);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [me.tenantId, name],
  );

  const onSaveClicked = (_, event) => {
    event.stopPropagation();
    return event;
  };

  const content =
    loading || !data ? (
      skeletons.map((k) => <Skeleton title loading active key={k} />)
    ) : (
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        <FormikImage
          hideFallback={hideFallback}
          name={name}
          control={
            <Control autosaveEnabled={autosaveEnabled} initialValues={initialValues} onSaveClicked={onSaveClicked} />
          }
          containerClassName={containerClassName}
          uploadClassName={uploadClassName}
        />
      </Formik>
    );
  if (noCardWrap) return content;
  return <Card title={t('admin.Setting.CorporateIdentity.TenantLogo.title')}>{content}</Card>;
}

export default memo(CorporateLogo, equal);
