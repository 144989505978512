import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Card from 'components/common/CardV2';
import { RenderTrueFalse } from 'components/common/List/FieldRenderers';
import { formatIbanString } from 'utils/formatValues';

import classes from './BankaccountCard.module.less';

/**
 * Value for sepa of bank account (Icon and link)
 * @param {object} inputProperties - The input properties for the component
 * @param {string} inputProperties.sepaLink - The link to the SEPA document
 * @param {boolean} inputProperties.hasSepa - Has the account a SEPA mandate
 * @returns {JSX.Element} SEPA value
 * @component
 */
const SEPAValue = ({ sepaLink, hasSepa }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails.bankAccountCard' });
  return (
    <>
      <RenderTrueFalse value={hasSepa} />
      {sepaLink ? (
        <a href={sepaLink} className={classes.bankaccount_card_sepa_link}>
          {t('downloadDocument')}
        </a>
      ) : null}
      {!sepaLink && hasSepa ? (
        <span className={classes.bankaccount_card_sepa_not_signed_info}>{t('noSignedSepa')}</span>
      ) : null}
    </>
  );
};

/**
 * Card for displaying bank account details
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.bankaccount - The bank account to display
 * @param {string} inputProperties.bankaccount.accountOwnerName - The name of the account owner
 * @param {string} inputProperties.bankaccount.bic - The bank identifier code
 * @param {string} inputProperties.bankaccount.iban - The international bank account number
 * @param {object} inputProperties.bankaccount.bankData - The data of the bank
 * @param {string} inputProperties.bankaccount.bankData.bankName - The name of the bank
 * @param {string} inputProperties.bankaccount.bankData.bankCity - The city of the bank
 * @param {string} inputProperties.bankaccount.sepaLink - The link to the SEPA document
 * @returns {JSX.Element} Bank account card
 * @component
 */
const BankaccountCard = ({ bankaccount }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails.bankAccountCard' });
  return (
    <Card size="small" bordered>
      <div className={classes.bankaccount_card_content}>
        <div className={classes.bankaccount_details}>
          <Row>
            <Col className={classes.bankaccount_details_label}>{t('accountOwnerName')}</Col>
            <Col>{bankaccount.accountOwnerName}</Col>
          </Row>
          <Row>
            <Col className={classes.bankaccount_details_label}>{t('bic')}</Col>
            <Col>{bankaccount.bic}</Col>
          </Row>
          <Row>
            <Col className={classes.bankaccount_details_label}>{t('iban')}</Col>
            <Col>{formatIbanString(bankaccount.iban)}</Col>
          </Row>
          {bankaccount?.bankData?.bankName ? (
            <Row>
              <Col className={classes.bankaccount_details_label}>{t('bank')}</Col>
              <Col flex="auto">
                <div className={classes.bankaccount_details_value}>{bankaccount.bankData.bankName}</div>
              </Col>
            </Row>
          ) : null}
          {bankaccount?.bankData?.bankCity ? (
            <Row>
              <Col className={classes.bankaccount_details_label}>{t('bankCity')}</Col>
              <Col flex="auto">
                <div className={classes.bankaccount_details_value}>{bankaccount.bankData.bankCity}</div>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col className={classes.bankaccount_details_label}>{t('sepa')}</Col>
            <Col>
              <SEPAValue sepaLink={bankaccount.sepaDocumentLink} hasSepa={bankaccount.hasSepaMandate} />
            </Col>
          </Row>
        </div>
        <div className={classes.bankaccount_card_action_buttons}>{/* For action buttons */}</div>
      </div>
    </Card>
  );
};

export default BankaccountCard;
