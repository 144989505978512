import { Col, Row } from 'antd';
import ClientAddressAdditionField from './ClientAddressAdditionField';
import ClientStreetField from './ClientStreetField';
import ClientHouseNumberField from './ClientHouseNumberField';
import ClientPostalCodeField from './ClientPostalCodeField';
import ClientCityField from './ClientCityField';
import ClientAddressCountryField from './ClientAddressCountryField';

/**
 * Address fields for client form
 * @returns {JSX.Element} Client address fields
 */
const ClientAddressFields = () => {
  return (
    <>
      <ClientAddressAdditionField />
      <Row gutter={32}>
        <Col span={20}>
          <ClientStreetField />
        </Col>
        <Col span={4}>
          <ClientHouseNumberField />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={4}>
          <ClientPostalCodeField />
        </Col>
        <Col span={20}>
          <ClientCityField />
        </Col>
      </Row>
      <ClientAddressCountryField />
    </>
  );
};

export default ClientAddressFields;
