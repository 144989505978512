import { message } from 'antd';
import generateMessageConfig from 'components/common/CloseableMessage';
import apollo from 'graphql/apollo';
import { updateCompanyMutation } from 'graphql/mutations';
import { userCompanyQuery } from 'graphql/queries';
import i18n from 'i18n';

/**
 * Edits a client to the tenant instance remotely
 * @param {object} clientValues - Data of the new client
 * @param {string} clientValues.clientId - Id of the client
 * @param {string} clientValues.name - Name of client
 * @param {string} clientValues.identifier - Identifier of the client
 * @param {string} clientValues.type - Type of the client
 * @param {object} clientValues.address - Address of the client
 * @param {object} clientValues.address.country - Country of the client
 * @param {object} clientValues.address.city - City of the client
 * @param {object} clientValues.address.cityCode - City code of the client
 * @param {object} clientValues.address.street - Street of the client
 * @param {object} clientValues.address.houseNumber - House number of the client
 * @param {object} clientValues.importFields - Import fields of the client
 * @param {object} clientValues.importFields.commercialObject - Commercial object of the client (for companies)
 * @returns {Promise<void>} A promise that resolves when the client is editted or rejects with an error
 */
const editClient = async ({ name, identifier, type, address, importFields, clientId }) => {
  return apollo
    .mutate({
      mutation: updateCompanyMutation,
      variables: {
        _id: clientId,
        modifier: {
          name,
          identifier,
          type,
          address,
          commercialObject: importFields.commercialObject,
        },
      },
      refetchQueries: [{ query: userCompanyQuery, variables: { _id: clientId } }],
    })
    .then(() => {
      message.success(
        generateMessageConfig(i18n.t('ClientsListPage.addClientModal.editSuccessMessage'), 'editClientSuccess', 5),
      );
    });
};

export default editClient;
