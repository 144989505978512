import { useQuery } from '@apollo/client';
import { getActionsOfClientQuery } from 'graphql/queries';
import { useMemo } from 'react';

/**
 * Get history of client (currently only project card actions of project cards of client)
 * @param {string} clientId - Id of client
 * @returns {{ history: object[], loading: boolean, error: object }} - History of client, loading state, error state
 */
const useClientHistory = (clientId) => {
  const { data, loading, error } = useQuery(getActionsOfClientQuery, {
    variables: { clientId },
    fetchPolicy: 'cache-and-network',
  });

  const sortedData = useMemo(() => {
    if (!data || data?.getActionsOfClient?.length === 0) return null;
    return [...data.getActionsOfClient].reverse();
  }, [data]);

  return {
    history: sortedData || [],
    loading,
    error,
  };
};

export default useClientHistory;
