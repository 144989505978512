import { Card as CardAntd } from 'antd';
import concatClassNames from 'utils/classNames';
import classes from './CardV2.module.less';

/**
 * Card component with cleaner look for general use on pages in app
 * @param {object} inputProperties - The input properties for the component
 * @returns {JSX.Element} Card component with cleaner look
 * @component
 */
const CardV2 = ({ title, children, bordered = false, size = 'default', ...props }) => {
  return (
    <CardAntd
      className={concatClassNames(classes.card, size === 'small' ? classes.card__small : classes.card__default)}
      title={title}
      bordered={bordered}
      id={classes.cardv2}
      {...props}
    >
      {children}
    </CardAntd>
  );
};

export default CardV2;
