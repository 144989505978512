import i18n from 'i18n';
import { deleteClient } from 'graphql/methods';
import confirmModal from 'utils/confirmModal';
import { toastGraphQLError } from 'utils/helpers';
import { message } from 'antd';
import generateMessageConfig from 'components/common/CloseableMessage';

/**
 * Deletes client with confirmation
 * @param {string} clientId - id of client
 */
const deleteClientWithConfirmation = async (clientId) => {
  confirmModal({
    okText: i18n.t('ClientsListPage.deleteClient.ok'),
    cancelText: i18n.t('ClientsListPage.deleteClient.cancel'),
    title: i18n.t('ClientsListPage.deleteClient.title'),
    content: <p style={{ color: 'red', fontWeight: 'bold' }}>{i18n.t('ClientsListPage.deleteClient.content')}</p>,
    okType: 'danger',
    onOk: () =>
      deleteClient(clientId)
        .then(
          message.success(
            generateMessageConfig(i18n.t('ClientsListPage.deleteClient.success'), `deleteClientSuccess_{clientId}`, 5),
          ),
        )
        .catch(toastGraphQLError),
    maskClosable: true,
    width: '800px',
  });
};

export default deleteClientWithConfirmation;
