import { EnterOutlined } from '@ant-design/icons';
import classes from './ArrowIcon.module.less';

/**
 * ArrowIcon component that renders the EnterOutlined icon from Ant Design, used to visually indicate focus.
 * @returns {JSX.Element} Arrow icon
 * @component
 */
const ArrowIcon = () => {
  return <EnterOutlined className={classes.arrowIcon} />;
};

export default ArrowIcon;
