import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { SortDirection } from 'components/common/List/SortBar/SortBarEntry';
import clientEntries from 'graphql/queries/user/clientEntriesQuery';

/**
 * Get client entries from the server as paginated data
 * @param {string} queryState - Query string to search for
 * @param {number} limit - Number of entries to fetch
 * @param {number} skip - Number of entries to skip
 * @param {string[]} sortState - Sort state [field, direction]
 * @param {string[][]} filterState - Filter state [[field, value], ...]
 * @returns {object} Data, loading and error state (Data contains entries and count)
 */
const useClientEntries = (queryState, limit, skip, sortState, filterState) => {
  const clientType = useMemo(() => {
    const clientTypeFilter = !filterState || filterState.length === 0 ? undefined : filterState[0][1];
    return clientTypeFilter === 'all' ? undefined : clientTypeFilter;
  }, [filterState]);
  const { data, error, loading } = useQuery(clientEntries, {
    variables: {
      query: queryState,
      limit,
      skip,
      sortField: sortState?.[0],
      sortOrder: sortState?.[1] === SortDirection.ASC ? 1 : -1,
      clientType,
    },
    fetchPolicy: 'cache-and-network',
  });

  const modifiedData = useMemo(() => {
    if (!data) {
      return data;
    }
    return {
      entries: data.clientEntries.clientEntries,
      count: data.clientEntries.count,
    };
  }, [data]);

  return { data: modifiedData, loading, error };
};

export default useClientEntries;
