import equal from 'fast-deep-equal/es6/react';
import { useFormikField } from 'hooks/common/useFormikField';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import { adminCompanyTypesQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import confirmModal from 'utils/confirmModal';
import i18n from 'i18n';
import { useCategoriesContext } from 'components/user/shoppingCart/context';
import { useFormikContext, Field } from 'formik';
import { useFunctionToRefCB } from 'memo';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { Form } from 'formik-antd';
import { FaUserTie } from 'react-icons/fa';
import Card from 'components/common/Card';
import Switch from 'components/common/Switch';
import { VATType } from 'constants/shoppingCart';
import { FieldSearchDropdown } from './ContactData/components/Inputs';
import { deselectItems } from './utils';
import classes from './CompanyType.module.less';

export function showElement(companyTypeIds, companyTypeId) {
  return !companyTypeId || companyTypeIds.find((e) => e === companyTypeId);
}

const SELF_BOOKER = 'SelfBooker';

const confirmChangeCompanyType =
  (onConfirm) =>
  (...props) =>
    confirmModal({
      cancelText: i18n.t('common.cancel'),
      okText: i18n.t('common.ok'),
      okType: 'danger',
      onOk: () => onConfirm(...props),
      title: i18n.t('user.ShoppingCart.CompanyType.confirmChange'),
    });
const CompanyType = ({
  onChangeDropdown,
  onChangeSwitch,
  dropdownValue,
  switchValue,
  dropdownOptions,
  loading,
  displaySwitch,
  nextButton: NextButton,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <FieldSearchDropdown
        className={classes.companyTypeSelect}
        placeholder={t('user.ShoppingCart.CompanyType.inputs.companyType.placeholder')}
        loading={loading}
        value={dropdownValue}
        onChange={onChangeDropdown}
        options={dropdownOptions}
        // eslint-disable-next-line react/no-unstable-nested-components
        notFoundContent={() => <div>{t('user.ShoppingCart.CompanyType.noTypes')}</div>}
      />
      <div className="margin-top-16">
        {displaySwitch && (
          <Switch
            label={t('user.ShoppingCart.CompanyType.inputs.selfBooker')}
            checked={switchValue}
            onChange={onChangeSwitch}
          />
        )}
        {NextButton ? <NextButton /> : null}
      </div>
    </>
  );
};
const CompanyTypeMemo = memo(CompanyType, equal);

function CompanyTypeContainer({ nextButton, changeVAT }) {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(adminCompanyTypesQuery);
  const companyTypes = useMemo(() => grabFirstGQLDataResult(data), [data]);
  const categories = useCategoriesContext();
  const { values, setFieldValueAndTouched } = useFormikContext();

  const { value, onChange: setCompanyTypeId } = useFormikField('companyTypeId');

  const isSelfBooker = useMemo(() => value?.includes(SELF_BOOKER), [value]);
  const currentCompanyType = useMemo(() => find(companyTypes, { _id: value }), [companyTypes, value]);

  const options = useMemo(() => {
    if (!companyTypes) return [];
    const filterConditionalVisibilityTypes =
      value === 'privatePerson' || !value
        ? companyTypes
        : companyTypes.filter(({ _id }) => {
            return (isSelfBooker ? _id.includes(SELF_BOOKER) : !_id.includes(SELF_BOOKER)) || _id === 'privatePerson';
          });
    return filterConditionalVisibilityTypes.map((e) => ({ _id: e._id, label: t(e.label) }));
  }, [companyTypes, isSelfBooker, t, value]);
  const onChange = useCallback(
    (newValue) => {
      setCompanyTypeId(newValue);
      const isPrivatePerson = newValue === 'privatePerson';
      changeVAT && setFieldValueAndTouched('showVat', isPrivatePerson);
      changeVAT && setFieldValueAndTouched('vatType', isPrivatePerson ? VATType.PERSONAL : VATType.COMPANY);
      deselectItems({ categories, companyTypeId: newValue, setFieldValueAndTouched, values });
    },
    [categories, changeVAT, setCompanyTypeId, setFieldValueAndTouched, values],
  );

  const onSwitch = useCallback(
    (isActive) => {
      if (!value) return;
      onChange(isActive ? `${value}${SELF_BOOKER}` : value.slice(0, value.lastIndexOf(SELF_BOOKER)));
    },
    [onChange, value],
  );
  const checkIfSelectedItems = (e, ifYes, ifNo) => {
    const isItemSelected = find(categories, (category) => find(category?.items, ({ _id }) => values[_id]));
    if (isItemSelected) ifYes(e);
    else ifNo(e);
  };
  const onChangeDropdown = useFunctionToRefCB((e) =>
    checkIfSelectedItems(e, confirmChangeCompanyType(onChange), onChange),
  );
  const onChangeSwitch = useFunctionToRefCB((e) =>
    checkIfSelectedItems(e, confirmChangeCompanyType(onSwitch), onSwitch),
  );
  return (
    <Card icon={<FaUserTie />} title={t('user.ShoppingCart.CompanyType.title')} style={{ padding: 0 }}>
      <Form layout="vertical">
        <FormItem name="companyTypeId" label={t('user.ShoppingCart.CompanyType.inputs.companyType.label')}>
          <Field name="companyTypeId">
            {() => {
              return (
                <CompanyTypeMemo
                  onChangeDropdown={onChangeDropdown}
                  onChangeSwitch={onChangeSwitch}
                  dropdownValue={currentCompanyType ? t(currentCompanyType?.label) : null}
                  switchValue={isSelfBooker}
                  dropdownOptions={options || []}
                  displaySwitch={value && currentCompanyType?._id !== 'privatePerson'}
                  loading={loading}
                  nextButton={nextButton}
                />
              );
            }}
          </Field>
        </FormItem>
      </Form>
    </Card>
  );
}

export default memo(CompanyTypeContainer, equal);
