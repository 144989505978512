import { Tooltip } from 'antd';
import { useNavigate } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import {
  DeleteFilled,
  EditFilled,
  MailFilled,
  TagFilled,
  WarningFilled,
  CarryOutFilled,
  CloseSquareFilled,
  ExclamationCircleFilled,
  ClockCircleFilled,
  ControlFilled,
} from '@ant-design/icons';
import { FaSignature } from 'react-icons/fa';

import concatClassNames from 'utils/classNames';
import { Button } from 'components/common/Button';
import { RenderDate, RenderDateTime } from 'components/common/List/FieldRenderers';
import { useModal } from 'components/common/Modal/Modal';
import Timeline from 'components/common/Timeline';
import ICONS_BY_STATE from 'components/user/SigningStateIcon';
import openViewProjectCard from 'ProjectCardManagement/ProjectCardManagement/methods/openViewProjectCard';
import InlineCollapsable from 'components/common/InlineCollapsable';
import { SENDED_EMAIL_ACTIONS } from 'pages/shoppingCartManagement/ShoppingCartView/ShoppingCartHistoryItems';
import SmallViewButton from 'components/common/SmallViewButton';
import EmailModal from './EmailModal';

import classes from './TimelineItem.module.less';

/**
 * Maps action types to icons
 */
const ACTION_ICONS = {
  'ShoppingCart.changeStatus': <TagFilled />,
  'ShoppingCart.created': <EditFilled />,
  'ShoppingCart.deleted': <DeleteFilled />,
  'DigitalSignature.started': ICONS_BY_STATE.STARTED(),
  'DigitalSignature.revoked': ICONS_BY_STATE.REVOKED(),
  'DigitalSignature.successfulFinished': ICONS_BY_STATE.SIGNED(),
  'DigitalSignature.allSignedFailed': <ExclamationCircleFilled />,
  'DdigitalSignature.startFailedPdf': <ExclamationCircleFilled />,
  'dDgitalSignature.linkRequested': <WarningFilled />,
  'DigitalSignature.passwordRequested': <WarningFilled />,
  'DigitalSignature.newLink': <ControlFilled />,
  'DigitalSignature.newPassword': <ControlFilled />,
  'DigitalSignature.linkExpirationDateChanged': <ClockCircleFilled />,
  'DigitalSignature.signed': <FaSignature />,
  'DigitalSignature.autoSigned': <FaSignature />,
  'DigitalSignature.monthlyPaymentDecisionTakenYes': <CarryOutFilled />,
  'DigitalSignature.monthlyPaymentDecisionTakenNo': <CloseSquareFilled />,
  'DigitalSignature.bookmarkedItemsAdded': <CarryOutFilled />,
  'DigitalSignature.sepaMandateNotGranted': <CloseSquareFilled />,
  'DigitalSignature.sepaMandateGranted': <CarryOutFilled />,
};

/**
 * Get action label for action
 * @param {object} action - Action object
 * @param {string} action.actionType - Action type
 * @param {object} action.variables - Action variables
 * @param {Func} t - Translation function
 * @returns {JSX.Element} Action label
 */
const getActionLabel = (action, t) => {
  if (action.actionType === 'ShoppingCart.created') {
    if (action.variables.revision) {
      return t('actionLabels.ShoppingCart.createdRevision');
    }
    return t('actionLabels.ShoppingCart.created');
  }

  if (action.actionType === 'DigitalSignature.linkExpirationDateChanged') {
    return (
      <Trans
        i18nKey="ClientViewPage.clientHistoryTab.actionLabels.DigitalSignature.linkExpirationDateChanged"
        values={{
          newDate: RenderDateTime(action.variables.newDate),
          oldDate: RenderDateTime(action.variables.oldDate),
        }}
        components={{
          gray: <span className={classes.gray_text} />,
        }}
      />
    );
  }

  if (action.actionType !== 'ShoppingCart.sentByEmail' && SENDED_EMAIL_ACTIONS.includes(action.actionType)) {
    return (
      <Trans
        i18nKey={`ClientViewPage.clientHistoryTab.actionLabels.${action.actionType}`}
        values={{
          ...action.variables,
          recipient: action.variables.recipients[0].email,
        }}
        components={{
          gray: <span className={classes.gray_text} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey={`ClientViewPage.clientHistoryTab.actionLabels.${action.actionType}`}
      values={{
        ...action.variables,
      }}
      components={{
        gray: <span className={classes.gray_text} />,
      }}
    />
  );
};

/**
 * Item for the timeline for client history in client view
 * @param {object} inputProperties - Input properties for the component
 * @param {object} inputProperties.action - Action object
 * @param {string} inputProperties.action.actionType - Action type
 * @param {object} inputProperties.action.variables - Action variables
 * @param {Date} inputProperties.action.createdAt - Created at date
 * @returns {JSX.Element} Timeline item
 * @component
 */
const TimelineItem = ({ action }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientHistoryTab' });
  const icon = ACTION_ICONS[action.actionType] || <MailFilled />;

  return (
    <Timeline.Item dot={icon}>
      <span>{RenderDate(action.createdAt)}: </span>
      <span>{getActionLabel(action, t)}</span>
      {action.variables?.body && <EmailModalButton emailData={action.variables} />}

      {action.parentId && <ViewProjectCardButton projectCardId={action.parentId} />}
      {getAdditionalInfos(action, t)}
    </Timeline.Item>
  );
};

/**
 * Get additional infos for action in timeline item
 * @param {object} action - Action object
 * @param {Function} t - Translation function
 * @returns {JSX.Element} Additional infos
 */
const getAdditionalInfos = (action, t) => {
  const { actionType, variables } = action;
  if (actionType === 'DigitalSignature.revoked') {
    return <AdditionalInfos title={t('additionalTextTitle.revoked')}>{variables.internalNote}</AdditionalInfos>;
  }
  if (actionType === 'DigitalSignature.sepaMandateGranted') {
    return (
      <AdditionalInfos title={t('additionalTextTitle.sepaMandateGranted')}>
        <div>
          {t('additionalTextLabels.accountOwnerName')}: {variables.accountOwnerName}
        </div>
        <div>
          {t('additionalTextLabels.iban')}: {variables.iban}
        </div>
        <div>
          {t('additionalTextLabels.bic')}: {variables.bic}
        </div>
      </AdditionalInfos>
    );
  }
  if (actionType === 'DigitalSignature.bookmarkedItemsAdded') {
    return (
      <AdditionalInfos title={t('additionalTextTitle.bookmarkedItemsAdded')}>
        <ul>
          {variables.bookmarkedItems.map((item) => (
            <li key={item.name}>
              {item.categoryName} {item.name}
            </li>
          ))}
        </ul>
      </AdditionalInfos>
    );
  }
  return null;
};

/**
 * Collapsable additional infos for timeline item
 * @param {object} inputProperties - Input properties for the component
 * @returns {JSX.Element} Additional infos
 */
const AdditionalInfos = ({ title, children }) => {
  return (
    <InlineCollapsable header={<span className={classes.additional_info_text}>{title}</span>} small>
      {children}
    </InlineCollapsable>
  );
};

/**
 * Show email modal button for timeline item
 * @param {object} inputProperties - Input properties for the component
 * @param {object} inputProperties.emailData - Email data
 * @returns {JSX.Element} Email modal button
 * @component
 */
const EmailModalButton = ({ emailData }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientHistoryTab' });
  const [emailModalVisible, showEmailModal, closeEmailModal] = useModal();

  return (
    <>
      <Tooltip title={t('email')}>
        <Button
          type="link"
          icon={<MailFilled size={16} className={classes.button_icon} />}
          onClick={showEmailModal}
          className={concatClassNames(classes.email_button, classes.button)}
        />
      </Tooltip>
      <EmailModal emailData={emailData} visible={emailModalVisible} onClose={closeEmailModal} />
    </>
  );
};

/**
 * View project card button for timeline item
 * @param {object} inputProperties - Input properties for the component
 * @param {string} inputProperties.projectCardId - Project card id
 * @returns {JSX.Element} View project card button
 * @component
 */
const ViewProjectCardButton = ({ projectCardId }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientHistoryTab' });
  const navigate = useNavigate();

  return (
    <SmallViewButton
      onClick={() => openViewProjectCard(projectCardId, navigate)}
      tooltip={t('viewProjectCard')}
      className={classes.view_project_card_button}
    />
  );
};

export default TimelineItem;
