import { useRef } from 'react';
import { Alert, Button, Modal, Typography } from 'antd';
import { Input } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { userShoppingCartQuery } from 'graphql/queries';
import { revokeDigitalSignatureMutation } from 'graphql/mutations';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import Switch from 'components/common/Switch';
import { useMutation } from '@apollo/client';
import classes from './RevokeDigitalSignatureProcessModal.module.less';

/**
 * Component, which represents button bar for digital signature revoke modal with two buttons
 * @param {Object} inputParameters - Input parameters for the component
 * @param {Function} inputParameters.onYes - Function for on yes clicked event
 * @param {Function} inputParameters.onNo - Function for on no clicked event
 * @param {Boolean} isForEditShoppingCart - Is the modal opened for editing shopping cart
 * @returns {JSX.Element} ButtonBar component for digital signature revoke modal
 * @component
 */
const ButtonBar = ({ onYes, onNo, isForEditShoppingCart }) => {
  const { t: translation } = useTranslation();
  return (
    <div>
      <Button type="default" onClick={onNo}>
        {translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.buttons.no')}
      </Button>
      <Button type="primary" onClick={onYes}>
        {isForEditShoppingCart
          ? translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.isForEditShoppingCart.buttons.yes')
          : translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.buttons.yes')}
      </Button>
    </div>
  );
};

const { TextArea } = Input;
const { Title } = Typography;

/**
 * Component, which represents modal for digital signature revoke for a shopping cart
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Function} inputParameters.onCloseModal - Function, which makes the modal disappear
 * @param {String} inputParameters.shoppingCartId - Database id of related shopping cart object
 * @param {Function} inputParameters.onRevokeStarted - Function called, when revoke process has been started
 * @param {Boolean} inputParameters.isForEditShoppingCart - Is the modal opened for editing shopping cart
 * @returns {JSX.Element} RevokeDigitalSignatureProcessModal component
 * @component
 */
const RevokeDigitalSignatureProcessModal = ({
  onCloseModal,
  shoppingCartId,
  onRevokeStarted,
  isForEditShoppingCart = false,
}) => {
  const { t: translation } = useTranslation();
  const formRef = useRef();
  const [revokeDigitalSignature] = useMutation(revokeDigitalSignatureMutation, {
    refetchQueries: [{ query: userShoppingCartQuery, variables: { _id: shoppingCartId } }],
  });

  const initialValues = {
    internalNote: '',
    shouldNotifySignees: true,
  };

  const onYes = () => {
    const { internalNote, shouldNotifySignees } = formRef.current.values;
    revokeDigitalSignature({
      variables: {
        shoppingCartId,
        internalNote,
        shouldNotifySignees,
      },
    }).then(() => {
      if (onRevokeStarted) onRevokeStarted();
    });
    onCloseModal && onCloseModal();
  };

  const onNo = () => {
    onCloseModal && onCloseModal();
  };

  return (
    <Modal
      title={
        isForEditShoppingCart
          ? translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.isForEditShoppingCart.title')
          : translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.title')
      }
      visible
      className={classes.modal}
      footer={<ButtonBar onYes={onYes} onNo={onNo} isForEditShoppingCart={isForEditShoppingCart} />}
      onCancel={onCloseModal}
    >
      {isForEditShoppingCart ? (
        <Alert
          type="info"
          description={translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.editModeAlert')}
          className="alert-info"
          showIcon
        />
      ) : null}
      <Title level={4} className={isForEditShoppingCart ? classes.heading : null}>
        {translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.title')}
      </Title>
      <I18nFormik initialValues={initialValues} ref={formRef}>
        <div className={classes.internalNote}>
          <label htmlFor="internalNote">
            {translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.internalNoteLabel')}
          </label>
          <TextArea rows={4} key="internalNote" className={classes.internalNoteTextArea} name="internalNote" />
        </div>
        <div className={classes.attachmentsSwitchContainer}>
          <Switch
            name="shouldNotifySignees"
            className={classes.attachmentsSwitch}
            label={translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.notificationSwitch.label')}
            checkedChildren={translation('viewer.ShoppingCartView.RevokeDigitalSignatureModal.notificationSwitch.on')}
            unCheckedChildren={translation(
              'viewer.ShoppingCartView.RevokeDigitalSignatureModal.notificationSwitch.off',
            )}
          />
        </div>
      </I18nFormik>
    </Modal>
  );
};

export default RevokeDigitalSignatureProcessModal;
