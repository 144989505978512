import { Input } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

export const SignaturePageOptionYesText = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.MonthlyPaymentPreferences.signatureSettings.signaturePageOptionYes.label')}
      tooltip={t('admin.Setting.MonthlyPaymentPreferences.signatureSettings.signaturePageOptionYes.tooltip')}
      name="signatureSettings.optionPayMonthlyPaymentText"
    >
      <Input name="signatureSettings.optionPayMonthlyPaymentText" />
    </FormItem>
  );
};
