/**
 * Sets search parameter in URL by pushing new history item to url history stack
 * @param {string} key - Search parameter key
 * @param {string} value - Search parameter value
 */
export const modifySearchParameters = (key, value) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url);
};
