import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';

/**
 * Commerical Object input field for client form
 * @returns {JSX.Element} Commerical Object input field
 * @component
 */
const ClientCommercialObjectField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="importFields.commercialObject"
      label={t('ClientsListPage.addClientModal.form.commercialObject.label')}
      placeholder={t('ClientsListPage.addClientModal.form.commercialObject.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ClientCommercialObjectField;
