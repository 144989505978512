import { useEffect, useRef, useState } from 'react';

/**
 * Lazy loads list elements infinity scroll like
 * The returned reference has to be attached to a fake element at the end of the list for load more detection
 * @param {object[]} dataArray - Data array to be rendered
 * @param {(item: object) => JSX.Element} mappingFunc - Mapping function to map data to JSX
 * @param {number} elementsAtOnce - Optional: Number of elements to render at once
 * @param {boolean} enabled - Optional: Whether the lazy loading is enabled
 * @returns {{ mappedData: JSX.Element[], loadMoreRef: React.MutableRefObject<null> }} - Mapped data and reference to load more
 */
const useLazyListRendering = (dataArray, mappingFunc, elementsAtOnce = 20, enabled = true) => {
  const [mappedData, setMappedData] = useState([]);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    if (!enabled) {
      setMappedData(dataArray.map((item) => mappingFunc(item)));
    }
  }, [enabled, dataArray, mappingFunc]);

  useEffect(() => {
    if (!dataArray.length) {
      setMappedData([]);
      return;
    }

    if (!enabled && mappedData.length === 0) {
      return;
    }

    const scrollDetection = loadMoreRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const nextIndex = Math.min(mappedData.length + elementsAtOnce, dataArray.length);
          const newMappedData = dataArray.slice(mappedData.length, nextIndex).map((item) => mappingFunc(item));
          setMappedData((prevData) => [...prevData, ...newMappedData]);
        }
      },
      { threshold: 1 },
    );

    if (scrollDetection) {
      observer.observe(scrollDetection);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (scrollDetection) {
        observer.unobserve(scrollDetection);
      }
    };
  }, [dataArray, mappedData.length, mappingFunc, elementsAtOnce, enabled]);

  return { mappedData, loadMoreRef };
};

export default useLazyListRendering;
