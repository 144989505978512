import { Select } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { useFormikContext } from 'formik';
import i18n from 'i18n';
import { find } from 'lodash';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import confirmModal from 'utils/confirmModal';
import * as Yup from 'yup';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import SubjectFormikInput from 'TenantInstanceConfiguration/EmailTemplateManagement/components/SubjectFormikInput';
import BodyFormikInput from 'TenantInstanceConfiguration/EmailTemplateManagement/components/BodyFormikInput';
import { useRecipientsContext } from 'components/user/shoppingCart/context';
import { SignatureRichEditor } from 'pages/admin/EmailSetting/SignatureCard';
import { FieldSearchDropdown } from '../ContactData/components/Inputs';
import classes from './SendEmail.module.less';

export const emailTemplatesSchema =
  (customBodyValidation = { message: '', test: () => true }) =>
  () => {
    return Yup.object().shape({
      emailTemplateId: Yup.string(),
      subject: Yup.string()
        .transform((value) => (RichText.isRichTextEmpty(value) ? null : value))
        .nullable()
        .required()
        .label(i18n.t('user.ShoppingCart.SendEmail.fields.subject.label')),
      body: Yup.string()
        .transform((value) => (RichText.isRichTextEmpty(value) ? null : value))
        .nullable()
        .required()
        .label(i18n.t('user.ShoppingCart.SendEmail.fields.body.label'))
        .test(customBodyValidation),
      recipients: Yup.array().min(1).label(i18n.t('user.ShoppingCart.SendEmail.fields.recipients.label')),
      shouldSendAttachments: Yup.boolean(),
    });
  };

const itemRender = (option) => {
  return option.name;
};

const changeEmailTemplatesWithConfirmation = ({ onOk }) =>
  confirmModal({
    maskClosable: true,
    onOk: () => onOk(),
    title: i18n.t('user.ShoppingCart.SendEmail.changeEmailTemplatesConfirmation'),
  });

const EmailTemplatesFormikSelect = ({ emailTemplates }) => {
  const { t } = useTranslation();
  const defaultRichEditorValueSubject = useMemo(() => RichText.getDefaultRichEditorValue(), []);
  const defaultRichEditorValueBody = useMemo(
    () => RichText.getDefaultRichEditorValue({ rootElement: 'paragraph' }),
    [],
  );
  const {
    values: { emailTemplateId, subject, body },
    setValues,
  } = useFormikContext();
  useEffect(() => {
    if (emailTemplates && subject === defaultRichEditorValueSubject && body === defaultRichEditorValueBody) {
      const defaultEmailTemplate = find(emailTemplates, { _id: emailTemplateId });
      if (defaultEmailTemplate)
        setValues((values) => ({
          ...values,
          emailTemplateId: defaultEmailTemplate._id,
          subject: defaultEmailTemplate.subject,
          body: defaultEmailTemplate.body,
          shouldSendAttachments: defaultEmailTemplate.shouldSendAttachments,
        }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplates, setValues]);
  const onSelect = useCallback(
    (_id) => {
      const emailTemplate = find(emailTemplates, { _id });
      const onSetValues = () =>
        setValues((values) => {
          return {
            ...values,
            emailTemplateId: _id,
            subject: emailTemplate.subject,
            body: emailTemplate.body,
            shouldSendAttachments: emailTemplate?.shouldSendAttachments,
          };
        });

      if (subject || body) {
        const onOk = async () => {
          onSetValues();
        };

        changeEmailTemplatesWithConfirmation({ onOk });
      } else {
        onSetValues();
      }
    },
    [body, emailTemplates, setValues, subject],
  );
  return (
    <FormItem name="emailTemplateId" label={t('user.ShoppingCart.SendEmail.fields.emailTemplates.label')}>
      <FieldSearchDropdown
        options={emailTemplates}
        value={emailTemplateId}
        onChange={onSelect}
        itemRender={itemRender}
      />
    </FormItem>
  );
};

export const RecipientsFormikSelect = memo(() => {
  const recipients = useRecipientsContext();
  const { t } = useTranslation();
  const {
    values: { notificationMode },
  } = useFormikContext();
  return (
    <FormItem
      name="recipients"
      label={`${t('user.ShoppingCart.SendEmail.fields.recipients.label')}: `}
      className={classes.recipientsInput}
    >
      <Select name="recipients" mode="multiple" disabled={notificationMode}>
        {recipients?.map(({ _id, firstName, lastName, email }) => (
          <Select.Option key={_id} value={_id}>
            {[firstName, lastName, email].join(' ')}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
}, equal);

const SendEmailForm = ({ emailTemplates }) => {
  const { t } = useTranslation();
  return (
    <>
      <EmailTemplatesFormikSelect emailTemplates={emailTemplates} />
      <SubjectFormikInput />
      <BodyFormikInput />
      <SignatureRichEditor label={t('user.ShoppingCart.SendEmail.fields.signature.label')} />
    </>
  );
};

export default memo(SendEmailForm, equal);
