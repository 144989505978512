import { useTranslation } from 'react-i18next';
import MoreInfoWidget from 'components/common/MoreInfoWidget';

/**
 * Help widget for the client management page
 * @param {object} inputProperties - The properties of the component
 * @param {string} inputProperties.buttonClassName - The class name for the button, which opens the help modal
 * @returns {JSX.Element} The ClientManagementHelperWidget component
 * @component
 */
const ClientManagementHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('ClientsListPage.help.buttonLabel')}
      title={t('ClientsListPage.help.title')}
      helpText={t('ClientsListPage.help.helpText')}
      videoCaption={t('ClientsListPage.help.videoCaption')}
      videoUrl={t('ClientsListPage.help.videoUrl')}
      imageUrl={t('ClientsListPage.help.imageUrl')}
    />
  );
};

export default ClientManagementHelperWidget;
