import { MailOutlined } from '@ant-design/icons';
import { useCachedQuery } from 'graphql/utils';
import Card from 'components/common/Card';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import equal from 'fast-deep-equal/es6/react';
import { Select } from 'formik-antd';
import { adminEmailTemplatesQuery } from 'graphql/queries';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';

const { Option } = Select;

const SetDefaultEmailTemplate = () => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(adminEmailTemplatesQuery, {
    variables: { isLibrary: false },
    fetchPolicy: 'cache-and-network',
  });
  const emailTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);

  return (
    <Card icon={<MailOutlined />} title={t('admin.Setting.ShoppingCartPreferences.selectDefaultEmailTemplate')}>
      <FormItem name="emailTemplateId" label="">
        <Select
          name="emailTemplateId"
          loading={loading}
          disabled={loading}
          placeholder={t('admin.Setting.ShoppingCartPreferences.selectDefaultEmailTemplate')}
          allowClear
        >
          {loading ||
            emailTemplates?.map(({ _id, name }) => (
              <Option key={_id} value={_id}>
                {name}
              </Option>
            ))}
        </Select>
      </FormItem>
    </Card>
  );
};
export default memo(SetDefaultEmailTemplate, equal);
