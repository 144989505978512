import { useTranslation } from 'react-i18next';
import InputNumberWithLabel from 'components/common/FormComponents/Formik/InputNumberWithLabel';

/**
 * Postal code input field for address for client form
 * @returns {JSX.Element} Postal code input field
 */
const ClientPostalCodeField = () => {
  const { t } = useTranslation();
  return (
    <InputNumberWithLabel
      name="address.cityCode"
      label={t('ClientsListPage.addClientModal.form.address.cityCode.label')}
      placeholder={t('ClientsListPage.addClientModal.form.address.cityCode.placeholder')}
      inputProps={{
        min: 0,
        step: 1,
        controls: false,
        stringMode: true,
        parser: (value) => value.replace(/\D/g, ''),
        autoComplete: 'off',
      }}
    />
  );
};

export default ClientPostalCodeField;
