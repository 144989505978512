import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';
import { useTranslation } from 'react-i18next';

/**
 * House number input field for address for client form
 * @returns {JSX.Element} House number input field
 */
const ClientHouseNumberField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="address.houseNumber"
      label={t('ClientsListPage.addClientModal.form.address.houseNumber.label')}
      placeholder={t('ClientsListPage.addClientModal.form.address.houseNumber.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ClientHouseNumberField;
