import { useTranslation } from 'react-i18next';
import Card from 'components/common/CardV2';
import { GraphQLLoadingWrapper } from 'components/common/GraphQLLoadingWrapper';
import ClientDetails from './ClientDetails';
import ContactList from './ContactList';
import BankAccountList from './BankAccountList';
import classes from './ClientDetailsCard.module.less';
import ButtonBar from './ButtonBar';

/**
 * Card to show client details
 * @param {object} inputProperties - The input properties for the component
 * @param {string} inputProperties.clientId - The client ID
 * @returns {JSX.Element} Card with client details
 * @component
 */
const ClientDetailsCard = ({ client, loading, error }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });

  return (
    <Card title={t('cardTitle')}>
      <GraphQLLoadingWrapper data={client} loading={loading} error={error}>
        <div>
          <div className={classes.client_details_card_content}>
            <ClientDetails client={client} />
            <ContactList contacts={client?.contacts} />
            <BankAccountList bankAccounts={client?.bankAccounts} />
          </div>
          <ButtonBar client={client} />
        </div>
      </GraphQLLoadingWrapper>
    </Card>
  );
};

export default ClientDetailsCard;
