import { Form } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import * as Yup from 'yup';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import { textsSchema } from 'components/admin/itemModal/schema';
import {
  NameFormikInput,
  InfoTextFormikInput,
  InternalNoteFormikRichEditor,
  BenefitsFormikInput,
  PleaseNoteFormikInput,
  NotesToEmployeeFormikInput,
  SubTitleFormikInput,
  GuidanceVideoFormikInput,
  GuidanceTextFormikRichEditor,
  DescriptionForContractFormikInput,
  NotesForFeeAgreementFormikInput,
} from 'components/admin/itemModal/components';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import { useLibraryContext } from 'contexts/LibraryContext';
import { guidanceVideoIdSchema } from 'components/admin/TextsTab';
import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import { useTranslation } from 'react-i18next';

export const textsValidationSchema = () =>
  Yup.object().shape({
    ...textsSchema({}),
    ...guidanceVideoIdSchema({}),
  });

const TextsHelperWidget = ({ buttonClassName }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={buttonClassName}
      buttonText={t('admin.itemModal.TextsHelperWidget.howUseButton')}
      title={t('admin.itemModal.TextsHelperWidget.modalInfo.title')}
      helpText={t('admin.itemModal.TextsHelperWidget.modalInfo.helpText')}
      videoCaption={t('admin.itemModal.TextsHelperWidget.modalInfo.videoCaption')}
      videoUrl={t('admin.itemModal.TextsHelperWidget.modalInfo.videoUrl')}
      imageUrl={t('admin.itemModal.TextsHelperWidget.modalInfo.imageUrl')}
    />
  );
};

const TextsHelperWidgetMemo = memo(TextsHelperWidget, equal);

const TextsTab = ({ initialValues, onSubmit, formikRef }) => {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  return (
    <I18nFormik
      initialValues={initialValues}
      validationSchema={textsValidationSchema}
      onSubmit={(values, formik) => onSubmit(values, formik)}
      enableReinitialize
      ref={formikRef}
    >
      <Form layout="vertical">
        <div className="margin-16 margin-top-0">
          <TextsHelperWidgetMemo />
          <AlertFromFormik />
          {isLibrary ? <InternalNoteFormikRichEditor /> : null}
          <NameFormikInput tooltip={t('admin.itemModal.inputs.name.tooltip')} />
          <SubTitleFormikInput tooltip={t('admin.itemModal.inputs.subTitle.tooltip')} />
          <InfoTextFormikInput />
          <BenefitsFormikInput />
          <DescriptionForContractFormikInput />
          <NotesForFeeAgreementFormikInput />
          <PleaseNoteFormikInput />
          <NotesToEmployeeFormikInput />
          {isLibrary ? (
            <>
              <GuidanceTextFormikRichEditor />
              <GuidanceVideoFormikInput />
            </>
          ) : null}
          <SaveChangesButton initialValues={initialValues} />
        </div>
      </Form>
    </I18nFormik>
  );
};

export default memo(TextsTab, equal);
