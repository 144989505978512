import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { RichEditorField } from 'components/common/RichEditor';

/**
 * SepaMandateTextCompany is a React functional component that provides a form item with a rich text editor.
 * This is intended for users to edit the mandate text that applies to companies for SEPA.
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {function} props.t - The translation function from i18next, used for internationalization.
 * @returns {ReactElement} The rendered component.
 *
 * @example
 * <SepaMandateTextCompany t={translationFunction} />
 */
export const SepaMandateTextCompany = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.DigitalSignaturePreferences.sepa.mandateCompany.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.sepa.mandateCompany.tooltip')}
      name="sepaMandateCompanyText"
    >
      <RichEditorField
        name="sepaMandateCompanyText"
        modifyPlaceholderSchema={(option) => {
          const tenantCompany = option.find((o) => o.name === 'tenantCompany');
          const company = option.find((o) => o.name === 'company');
          return [
            {
              ...company,
              fields: company.fields.filter((field) => ['shoppingCartCompanyOrFullname'].includes(field.name)),
            },
            {
              ...tenantCompany,
              fields: tenantCompany.fields.filter((field) =>
                ['tenantCompanyCreditorIdentNr', 'tenantCompanyName'].includes(field.name),
              ),
            },
          ];
        }}
      />
    </FormItem>
  );
};
