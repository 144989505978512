import { Tooltip } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import concatClassNames from 'utils/classNames';
import { Button } from './Button';

import classes from './SmallViewButton.module.less';

const SmallViewButton = ({ onClick, className, tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        type="link"
        icon={<EyeFilled size={16} className={classes.button_icon} />}
        onClick={onClick}
        className={concatClassNames(className, classes.button)}
      />
    </Tooltip>
  );
};

export default SmallViewButton;
