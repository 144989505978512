import { generatePath } from 'react-router';
import routePaths from 'router/route-paths';

/**
 * Open the view project card page for a specific project card
 * @param {string} projectCardId - Id of the project card
 * @param {(*) => void} navigate - Function to navigate to a new page
 * @returns {void}
 */
const openViewProjectCard = (projectCardId, navigate) =>
  navigate(generatePath(routePaths.shoppingCartView, { id: projectCardId }));

export default openViewProjectCard;
