import { Timeline as AntdTimeline } from 'antd';

/**
 * Timeline component to show timeline items in a timeline (See 'https://4x.ant.design/components/timeline/#API' for more information on timeline properties)
 * @param {object} inputProperties - Input properties for the component
 * @param {React.ReactNode} inputProperties.children - Children of the component
 * @param {boolean} inputProperties.reverse - Reverse the timeline items
 * @returns {JSX.Element} Timeline
 * @component
 */
const Timeline = ({ children, reverse }) => {
  return <AntdTimeline reverse={reverse}>{children}</AntdTimeline>;
};

Timeline.Item = AntdTimeline.Item;

export default Timeline;
