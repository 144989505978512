import { Badge as AntBadge } from 'antd';
import classes from './Badge.module.less';

/**
 * Badge/Tag component with custom text and tenant theme styling
 * @param {object} inputProperties - The input properties for the component
 * @param {string} inputProperties.text - The text to display in the badge
 * @returns {JSX.Element} Badge/Tag component
 * @component
 */
const Badge = ({ text }) => {
  return <AntBadge count={text} className={classes.badge} />;
};

export default Badge;
