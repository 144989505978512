import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormItem as OriginalFormItem } from 'formik-antd';
import concatClassNames from 'utils/classNames';

import classes from './FormItem.module.less';

/**
 * Form item, which handles the rendering of the label and the tooltip
 * Gets the input component as a child
 * @param {object} inputProperties
 * @param {string} inputProperties.name - Name of the field in the form data
 * @param {string} inputProperties.label - Label of the input
 * @param {string} inputProperties.sublabel - Sublabel of the input
 * @param {string} inputProperties.tooltip - Tooltip of the input
 * @param {boolean} inputProperties.labelUnderlined - Whether the label should be underlined
 * @returns {JSX.Element} Label and inputted input component
 * @component
 */
const FormItem = ({ containerProps, label, sublabel, tooltip, wideTooltip, labelUnderlined, className, ...props }) => {
  const { name } = props;
  let formLabel = label;

  if (tooltip) {
    formLabel = (
      <div>
        <div className="align-center">
          <span>{label}</span>
          <span style={{ marginLeft: '8px' }}>
            <Tooltip title={tooltip} autoAdjustOverflow overlayStyle={wideTooltip ? { maxWidth: '500px' } : undefined}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        </div>
        {sublabel ? <div>{sublabel}</div> : null}
      </div>
    );
  }

  return (
    <div fieldname={name} {...containerProps}>
      <OriginalFormItem
        {...props}
        label={formLabel}
        className={concatClassNames(className, labelUnderlined ? classes.UnderlinedLabel : '')}
      />
    </div>
  );
};

export default FormItem;
