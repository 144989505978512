import concatClassNames from 'utils/classNames';
import Loading from './Loading';
import classes from './LoadingOverlay.module.less';

/**
 * LoadingOverlay component displays a fullscreen loading indicator.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.isFixed=false] - Determines if the loading overlay should use `position: fixed` (true) or `position: absolute` (false, default).
 * This controls whether the overlay remains fixed to the viewport (on scroll) or positioned relative to its nearest positioned ancestor.
 * @returns {JSX.Element} A loading overlay that covers the entire screen.
 */
const LoadingOverlay = ({ isFixed = false }) => (
  <div className={concatClassNames(classes.fullscreen, isFixed ? classes.fixed : '')}>
    <Loading />
  </div>
);

export default LoadingOverlay;
