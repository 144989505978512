import { EditFilled } from '@ant-design/icons';

import { Button } from 'components/common/Button';
import EditModalHandler from './EditModalHandler';

import classes from './EditProjectCardActionButton.module.less';

/**
 * Action button for editing project cards
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.disabled - Whether the button is disabled
 * @param {boolean} inputProperties.loading - Whether the button is loading
 * @param {Function} inputProperties.onClick - On click handler
 * @returns {JSX.Element} Action button for editing project cards
 */
const ActionButton = ({ disabled, loading, onClick }) => {
  return (
    <Button
      icon={<EditFilled />}
      onClick={onClick}
      className={classes.edit_project_card_action_button}
      loading={loading}
      disabled={disabled}
    />
  );
};

/**
 * Action button for editing project cards with modal handler
 * @param {object} inputProperties - Input properties of the component
 * @param {object} inputProperties.item - Project card object
 * @returns {JSX.Element} Edit action button with modal handler
 * @component
 */
const EditProjectCardActionButton = ({ item: projectCard }) => {
  return <EditModalHandler editButton={ActionButton} _id={projectCard._id} projectCard={projectCard} />;
};

export default EditProjectCardActionButton;
