import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';
import { useTranslation } from 'react-i18next';

/**
 * Client identifier field for the add client form
 * @returns {JSX.Element} A field to enter the client number
 * @component
 */
const ClientNumberField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="identifier"
      label={t('ClientsListPage.addClientModal.form.identifier.label')}
      placeholder={t('ClientsListPage.addClientModal.form.identifier.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ClientNumberField;
