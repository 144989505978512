import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';
import { getProjectCardsOfClientQuery } from 'graphql/queries';
import { RenderDateTime } from 'components/common/List/FieldRenderers';
import { InlineListLayout } from 'components/common/List';
import { totalValueToString } from 'utils/formatValues';
import DigitalSignatureStateWithIcon from 'ProjectCardManagement/ProjectCardManagement/components/DigitalSignatureStateWithIcon';
import ProjectCardStateWithChangeButton from 'ProjectCardManagement/ProjectCardManagement/components/ProjectCardStateWithChangeButton';
import { ViewButton } from 'components/common/List/DefaultActionButtons';
import openViewProjectCard from 'ProjectCardManagement/ProjectCardManagement/methods/openViewProjectCard';
import EditProjectCardActionButton from 'ProjectCardManagement/ProjectCardManagement/components/EditButton/EditProjectCardActionButton';

const generateListItemConfig = (t, navigate) => ({
  rows: [
    {
      rowColumns: [
        { fieldName: 'name', fieldLabel: t('listLabels.name') },
        { fieldName: 'clientNumber', fieldLabel: t('listLabels.clientNumber') },
        {
          fieldName: 'clientName',
          fieldLabel: t('listLabels.clientName'),
        },
        {
          fieldName: 'status',
          fieldLabel: t('listLabels.state'),
          renderValue: (value, projectCard) => (
            <ProjectCardStateWithChangeButton status={value} projectCardId={projectCard._id} />
          ),
        },
        {
          fieldName: 'digitalSignatureState',
          fieldLabel: t('listLabels.digitalSignatureState'),
          renderValue: (value) => <DigitalSignatureStateWithIcon digitalSignatureState={value} />,
        },
      ],
      alignLastEntryRight: true,
    },
    {
      rowColumns: [
        {
          fieldName: 'contacts',
          fieldLabel: t('listLabels.contactPersons'),
          renderValue: (value) => {
            if (value) {
              return value.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(', ');
            }
            return '';
          },
        },
        {
          fieldName: 'oneOff',
          fieldLabel: t('listLabels.oneOff'),
          renderValue: (value, projectCard) =>
            totalValueToString({ paymentIntervalValues: value, showDigits: projectCard.showDigits }),
        },
        {
          fieldName: 'monthly',
          fieldLabel: t('listLabels.monthly'),
          renderValue: (value, projectCard) =>
            totalValueToString({ paymentIntervalValues: value, showDigits: projectCard.showDigits }),
        },
        {
          fieldName: 'yearly',
          fieldLabel: t('listLabels.yearly'),
          renderValue: (value, projectCard) =>
            totalValueToString({ paymentIntervalValues: value, showDigits: projectCard.showDigits }),
        },
        {
          fieldName: 'createdAt',
          fieldLabel: t('listLabels.createdAt'),
          renderValue: RenderDateTime,
        },
      ],
      alignLastEntryRight: true,
    },
  ],
  onClick: (projectCard) => openViewProjectCard(projectCard._id, navigate),
  actionButtons: [
    { component: EditProjectCardActionButton, key: 'edit', onClick: () => {} },
    ViewButton((projectCard) => openViewProjectCard(projectCard._id, navigate)),
  ],
  bordered: false,
});

const generateUseFetchProjectCardsOfClient = (client) => () => {
  const { data, loading, error } = useQuery(getProjectCardsOfClientQuery, { variables: { clientId: client._id } });

  const projectCards = useMemo(() => {
    if (!data) return null;
    return data.getProjectCardsOfClient.map((projectCard) => {
      return { ...projectCard, clientName: client.name, clientNumber: client.identifier };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, client.identifier, client.name]);

  return { data: projectCards, loading, error };
};

/**
 * Tab for client view with project cards of a client
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.client - The client object
 * @param {string} inputProperties.client._id - The client id
 * @param {string} inputProperties.client.name - The client name
 * @returns {JSX.Element} Tab with project cards of a client
 * @component
 */
const ProjectCardsTab = ({ client }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.projectCardTab' });
  const navigate = useNavigate();

  const listItemConfig = useMemo(() => generateListItemConfig(t, navigate), [t, navigate]);
  const initSort = useMemo(() => ['createdAt', 'DESC'], []);
  const useFetchProjectCardsOfClient = useMemo(
    () => generateUseFetchProjectCardsOfClient(client),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client.name, client.identifier, client],
  );

  return (
    <InlineListLayout
      listItemConfig={listItemConfig}
      useDataFetching={useFetchProjectCardsOfClient}
      initSort={initSort}
      bigList
    />
  );
};

export default ProjectCardsTab;
