import { useMemo } from 'react';
import ListLayout from './ListLayout';

/**
 * Inline list layout with sortbar and list items
 * @param {object} listItemConfig - Configuration for list items
 * @param {object[]} listItemConfig.rows - Rows to show in the list
 * @param {object[]} listItemConfig.rows.rowColumns - Columns to show in the row
 * @param {string} listItemConfig.rows.rowColumns.fieldName - Field name of the item
 * @param {string} listItemConfig.rows.rowColumns.fieldLabel - Label for the column
 * @param {(value: *) => JSX.Element} listItemConfig.rows.rowColumns.renderValue - Optional: Function to render the value
 * @param {boolean} listItemConfig.rows.alignLastEntryRight - Should the last entry of the row be aligned to the right
 * @param {object[]} listItemConfig.actionButtons - Action buttons for the list items
 * @param {React.ReactNode} listItemConfig.actionButtons.icon - Icon for the action button
 * @param {string} listItemConfig.actionButtons.key - Key for the action button
 * @param {(item: object) => void} listItemConfig.actionButtons.onClick - Function to call when the action button is clicked
 * @param {boolean} listItemConfig.actionButtons.danger - Should the action button in danger style
 * @param {(item: object) => void} listItemConfig.onClick - Function to call when the list item is clicked
 * @param {boolean} listItemConfig.collapseContent - Collapse content of the list items
 * @param {boolean} listItemConfig.collapsable - List items are collapsable
 * @param {boolean} listItemConfig.selectable - List items are selectable
 * @param {boolean} listItemConfig.draggable - List items are draggable
 * @param {boolean} listItemConfig.showLabel - Show label for the list items
 * @param {object} searchCardConfig - Configuration for search card
 * @param {object[]} searchCardConfig.filters - Filters to show in the search card
 * @param {string} searchCardConfig.filters.fieldName - Field name of item for the filter
 * @param {string} searchCardConfig.filters.label - Label for the filter
 * @param {string} searchCardConfig.filters.options - Options for the filter
 * @param {string} searchCardConfig.filters.options.value - Value for the filter option
 * @param {string} searchCardConfig.filters.options.label - Label for the filter option
 * @param {(item: object, filterValue: string) => boolean} searchCardConfig.filters.applyFilter - Optional: Function to apply the filter
 * @param {object[]} searchCardConfig.queryFields - Fields to search in
 * @param {string} searchCardConfig.searchPlaceholder - Placeholder for the search input
 * @param {object} sortBarConfig - Configuration for sort bar
 * @param {object[]} sortBarConfig.sortFields - Fields to sort by
 * @param {string} sortBarConfig.sortFields.fieldName - Field name of item to sort by
 * @param {string} sortBarConfig.sortFields.label - Label for the sort field
 * @param {(itemA: object, itemB: object) => number} sortBarConfig.sortFields.compareFunc - Optional: Function to compare the sort field
 * @param {object} sortBarConfig.initSort - Initial sort configuration [fieldName, direction]
 * @param {() => object | (query: string, pageSize: number, skip: number, sort: string[], filter: string[][]) => object} useDataFetching - Hook to fetch data, which returns an object with data, error and loading
 * @param {boolean} shouldUseSearchQueryParams - Should the list encode search parameters in the URL
 * @param {boolean} paginated - Should the list be paginated (Needs a fetch hook, which supports pagination)
 * @param {object} dragAndDropConfig - Configuration for drag and drop
 * @param {string} dragAndDropConfig.orderField - Field name for the order
 * @param {string} dragAndDropConfig.orderDirection - Order direction for the drag and drop
 * @param {(item: object) => void} dragAndDropConfig.onOrderChange - Function to call when the order changes with updated item
 * @param {string} identifier - Identifier field for the list items (Default: '_id')
 * @param {boolean} bigList - Whether the list is big and should be rendered with lazy loading
 * @param {object} lazyLoadDataConfig - Configuration for lazy loading of data
 * @param {number} lazyLoadDataConfig.elementsAtOnce - Number of elements to load at once (Default: 20)
 * @returns {JSX.Element} inline list layout
 * @component
 */
const InlineListLayout = ({
  listItemConfig,
  initSort = ['_id', 'ASC'],
  useDataFetching,
  shouldUseSearchQueryParams,
  paginated = false,
  dragAndDropConfig,
  identifier = '_id',
  bigList = false,
  lazyLoadDataConfig,
}) => {
  // eslint-disable-next-line no-param-reassign
  listItemConfig.bordered = listItemConfig.bordered !== undefined ? listItemConfig.bordered : true;

  const searchCardConfig = useMemo(
    () => ({
      filters: [],
      queryFields: [],
      searchPlaceholder: '',
    }),
    [],
  );

  const sortBarConfig = useMemo(() => ({ sortFields: [], initSort }), [initSort]);

  return (
    <ListLayout
      listItemConfig={listItemConfig}
      searchCardConfig={searchCardConfig}
      sortBarConfig={sortBarConfig}
      useDataFetching={useDataFetching}
      shouldUseSearchQueryParams={shouldUseSearchQueryParams}
      paginated={paginated}
      dragAndDropConfig={dragAndDropConfig}
      identifier={identifier}
      bigList={bigList}
      lazyLoadDataConfig={lazyLoadDataConfig}
      inline
    />
  );
};

export default InlineListLayout;
