import React, { memo, useCallback } from 'react';
import defaults from 'lodash/defaults';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography, Divider, Alert } from 'antd';
import { Form } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import pick from 'lodash/pick';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import ButtonColorPreview from 'pages/admin/CorporateIdentitySetting/components/ButtonColorPreview';
import { ColorPickerFormikInput, FontFormikInput } from 'pages/admin/CorporateIdentitySetting/components/Inputs';
import CorporateLogo from 'pages/admin/CorporateIdentitySetting/CorporateLogo';
// import FormikImage from 'components/common/FormikImage';
import corporateSettingsDefaults from 'constants/corporate-settings';
import * as Yup from 'yup';
import equal from 'fast-deep-equal/es6/react';
import { useFormikField } from 'hooks/common/useFormikField';
import i18n from 'i18n';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';
import c from './CorporateIdentity.module.less';
import { useOnboardingContext } from '../OnboardingContext';

const schema = () =>
  Yup.object().shape({
    primaryColor: Yup.string().required(),
    color: Yup.string().required(),
    logo: Yup.string()
      .transform((value) => value || '')
      .required()
      .label(i18n.t('admin.Setting.CorporateIdentity.TenantLogo.logo')),
    titleFontFamily: Yup.string().required(),
    fontFamily: Yup.string().required(),
  });
const defaultValues = {
  primaryColor: corporateSettingsDefaults.primaryColor,
  color: corporateSettingsDefaults.color,
  titleFontFamily: corporateSettingsDefaults.fontFamily,
  fontFamily: corporateSettingsDefaults.fontFamily,
  logo: null,
};

const CorporateLogoWrapper = () => {
  const { value, onChange } = useFormikField('logo');
  const onChangeLogo = useCallback(
    (img = null) => {
      if (value !== img) onChange(img);
    },
    [onChange, value],
  );

  return (
    <CorporateLogo
      containerClassName={c.logoContainer}
      uploadClassName={c.logoUploadClassName}
      noCardWrap
      name="logo"
      autosaveEnabled
      hideFallback
      onChange={onChangeLogo}
    />
  );
};
const CorporateLogoWrapperMemo = memo(CorporateLogoWrapper, equal);

export default function CorporateIdentity() {
  const { t } = useTranslation();
  const { onSubmit, formValuesRef } = useOnboardingContext();

  const initialValues = React.useMemo(
    () => defaults({}, pick(formValuesRef.current, Object.keys(defaultValues)), defaultValues),
    [formValuesRef],
  );
  return (
    <I18nFormik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={schema()}
    >
      {({ values, isSubmitting }) => (
        <Form layout="vertical">
          <OnboardingStepTemplate
            title={t('onboarding.corporateIdentity.title')}
            description={t('onboarding.corporateIdentity.textAboveVideo')}
            videoUrl={t('onboarding.corporateIdentity.videoUrl')}
            videoCaption={t('onboarding.corporateIdentity.videoCaption')}
            isSubmitting={isSubmitting}
            onPrev={() => {
              formValuesRef.current = { ...formValuesRef.current, ...values };
            }}
          >
            <ol>
              <h2>
                <li>{t('onboarding.corporateIdentity.pickCompanyColors')}</li>
              </h2>
              <Row gutter={20}>
                <Col flex="0 1 200px">
                  <FormItem name="primaryColor" label={t('onboarding.corporateIdentity.primaryColorInputLabel')}>
                    <ColorPickerFormikInput name="primaryColor" fast={false} />
                  </FormItem>
                  <FormItem name="color" label={t('onboarding.corporateIdentity.colorInputLabel')}>
                    <ColorPickerFormikInput fast={false} name="color" />
                  </FormItem>
                </Col>
                <Col>
                  <Typography.Text type="secondary">
                    <i>{t('onboarding.corporateIdentity.preview')}</i>
                  </Typography.Text>
                  <br />
                  <ButtonColorPreview
                    {...pick(values, ['primaryColor', 'color', 'fontFamily'])}
                    text={t('onboarding.corporateIdentity.colorPreview')}
                  />
                </Col>
              </Row>
              <Divider />
              <h2>
                <li>{t('onboarding.corporateIdentity.uploadLogoTitle')}</li>
              </h2>
              <FormItem name="logo">
                <Alert type="info" message={t('onboarding.corporateIdentity.uploadLogoAlert')} />
                <CorporateLogoWrapperMemo />
              </FormItem>
              <Divider />
              <h2>
                <li>{t('onboarding.corporateIdentity.chooseFontsTitle')}</li>
              </h2>
              <Row gutter={20} wrap="none">
                <Col md={10}>
                  <FormItem name="titleFontFamily" label={t('onboarding.corporateIdentity.titleFontFamilyInputLabel')}>
                    <FontFormikInput name="titleFontFamily" />
                  </FormItem>
                  <FormItem name="fontFamily" label={t('onboarding.corporateIdentity.fontFamilyInputLabel')}>
                    <FontFormikInput name="fontFamily" />
                  </FormItem>
                </Col>
                <Col md={14}>
                  <Typography.Text type="secondary">
                    <i>{t('onboarding.corporateIdentity.preview')}</i>
                    <br />
                  </Typography.Text>
                  <div>
                    <h3 style={{ fontFamily: values.titleFontFamily }}>
                      {t('onboarding.corporateIdentity.titleFontPreview')}
                    </h3>
                    <p style={{ fontFamily: values.fontFamily }}>{t('onboarding.corporateIdentity.textFontPreview')}</p>
                  </div>
                </Col>
              </Row>
            </ol>
          </OnboardingStepTemplate>
        </Form>
      )}
    </I18nFormik>
  );
}
