import { DownloadOutlined } from '@ant-design/icons';
import useDownloadFile from 'hooks/signing/useDownloadFile';
import ActionButton from './ActionButton';

/**
 * Action button for lists and card to download a file
 * @param {object} inputProperties - The input properties for the component
 * @param {string} inputProperties.url - The url of the file to download
 * @param {string} inputProperties.fileType - The type of the file
 * @param {string} inputProperties.fileName - The name of the file
 * @returns {JSX.Element} Button for downloading a file
 * @component
 */
const DownloadFileActionButton = ({ url, fileType, fileName }) => {
  const { downloadFile } = useDownloadFile(url, fileType, fileName);
  return <ActionButton icon={<DownloadOutlined />} onClick={() => downloadFile()} />;
};

export default DownloadFileActionButton;
