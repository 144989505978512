import { Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import { DocumentTable } from 'components/common/DocumentsCard/DocumentTable';
import OneButtonModal from 'components/common/Modal/OneButtonModal';
import htmr from 'htmr';
import {
  BCC,
  Recipients,
} from 'pages/shoppingCartManagement/ShoppingCartView/ShoppingCartHistoryItems/SendedEmailCard';
import { useTranslation } from 'react-i18next';
import { FaFile } from 'react-icons/fa';

import classes from './EmailModal.module.less';

/**
 * Modal to display an email sent for a project card used for client view
 * @param {object} inputProperties - Input properties of the component
 * @param {object} inputProperties.emailData - Email data
 * @param {string} inputProperties.emailData.subject - Email subject
 * @param {string} inputProperties.emailData.body - Email body
 * @param {string} inputProperties.emailData.signature - Email signature
 * @param {string} inputProperties.emailData.bcc - Email bcc
 * @param {string} inputProperties.emailData.recipients - Email recipients
 * @param {boolean} inputProperties.emailData.shouldSendAttachments - Should send attachments
 * @param {object} inputProperties.emailData.attachments - Email attachments
 * @param {string} inputProperties.emailData.originalId - Email original id
 * @param {Function} inputProperties.onClose - On close function
 * @param {boolean} inputProperties.visible - Visibility of the modal
 * @returns {JSX.Element} Email modal
 * @component
 */
const EmailModal = ({
  emailData: { subject, body, signature, bcc, recipients, shouldSendAttachments, attachments, originalId },
  onClose,
  visible,
}) => {
  const { t } = useTranslation();

  const filterDocuments = (documents) => {
    if (!documents) return documents;
    if (!attachments) return documents;
    const filteredDocuments = [];
    attachments.forEach((pdf) => {
      const document = documents.find((d) => d._id === pdf._id);
      if (!document) return;
      filteredDocuments.push({ ...document, ...pdf, isSigned: pdf.isSigned });
    });
    return filteredDocuments;
  };

  return (
    <OneButtonModal title={subject} size="wide" visible={visible} closable onCancel={onClose}>
      <div style={{ fontWeight: 600 }}>{subject}</div>
      <Recipients recipients={recipients} />
      <BCC bcc={bcc} />
      <Divider />
      <div>
        {body.split('<br/>').map((el) => (
          <div>{htmr(el || '&#xFEFF;')}</div>
        ))}
        {signature ? signature.split('<br/>').map((el) => <div>{htmr(el || '&#xFEFF;')}</div>) : null}
      </div>
      <Divider />
      {shouldSendAttachments && (
        <div>
          <div>
            <Title className={classes.attachments_title}>
              {t('ProjectCardManagement.ClientManagement.emailModal.attachments')}
            </Title>
            <DocumentTable
              filterDocuments={filterDocuments}
              shoppingCartId={originalId}
              icon={<FaFile />}
              title={t('user.ShoppingCart.SendEmail.attachments')}
            />
          </div>
        </div>
      )}
    </OneButtonModal>
  );
};

export default EmailModal;
