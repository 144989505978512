import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import ContactCard from './ContactCard';
import classes from './ContactList.module.less';

/**
 * List of contacts of client
 * @param {object} inputProperties - The input properties for the component
 * @param {object[]} inputProperties.contacts - The contacts to display
 * @returns {JSX.Element} List of contacts of client
 * @component
 */
const ContactList = ({ contacts }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });

  if (!contacts) return null;

  return (
    <div className={classes.contact_list}>
      <h3>{t('label.contactPersons')}</h3>
      <div className={concatClassNames(classes.contact_list_list, 'custom-scrollbar')}>
        {contacts.length === 0 && <p>{t('noContacts')}</p>}
        {contacts.map((contact) => (
          <ContactCard key={contact._id} contact={contact} />
        ))}
      </div>
    </div>
  );
};

export default ContactList;
