import { Form, Input } from 'formik-antd';
import { memo, useRef } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import i18n from 'i18n';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import toast from 'utils/toast';
import confirmModal from 'utils/confirmModal';
import equal from 'fast-deep-equal/es6/react';
import { DeleteOutlined } from '@ant-design/icons';
import { deletePerson } from 'graphql/methods/admin/person';
import updatePerson from 'graphql/methods/admin/person/updatePerson';
import FormikImage from 'components/common/FormikImage';
import { uploadFile } from 'graphql/methods';
import { PersonSchema } from './PersonSchema';

const deletePersonWithConfirmation = ({ _id }) =>
  confirmModal({
    okText: i18n.t('admin.ManagePersons.deleteConfirmModal.ok'),
    cancelText: i18n.t('admin.ManagePersons.deleteConfirmModal.cancel'),
    okType: 'danger',
    onOk: async () => {
      deletePerson({ _id }).catch(() => toast.error(i18n.t('common.toast.errors.user.delete')));
    },
    title: i18n.t('admin.ManagePersons.deleteConfirmModal.title'),
  });

const PersonForm = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={10}>
      <Col xs={24} md={12}>
        <FormItem name="name" label={t('admin.ManagePersons.fields.name.label')}>
          <Input name="name" autoFocus placeholder={t('admin.ManagePersons.fields.name.placeholder')} />
        </FormItem>
        <FormItem name="position" label={t('admin.ManagePersons.fields.position.label')}>
          <Input name="position" autoFocus placeholder={t('admin.ManagePersons.fields.position.placeholder')} />
        </FormItem>
      </Col>
      <Col xs={24} md={12}>
        <FormItem
          name="position"
          label={t('admin.ManagePersons.fields.signature.label')}
          tooltip={t('admin.ManagePersons.fields.signature.tooltip')}
        >
          <FormikImage name="signature" uploadLabel={t('admin.ManagePersons.uploadLabel')} />
        </FormItem>
      </Col>
    </Row>
  );
};
const Person = ({ _id, name, position, signature }) => {
  const initialValues = {
    name,
    position,
    signature,
  };
  const onSubmit = async (_values, formik) => {
    const { signature: signatureFile, ...values } = _values;
    if (signatureFile && typeof signatureFile !== 'string') {
      await uploadFile({
        name: `PersonSignature_${new Date().toISOString()}`,
        parentId: _id,
        parentType: 'persons',
        size: signatureFile.size,
        file: signatureFile,
      });
    }
    await updatePerson({ _id, modifier: values }).catch(formik.setErrors);
  };

  const formikRef = useRef();
  const { t } = useTranslation();
  return (
    <Card
      title={t('admin.ManagePersons.cardTitle')}
      style={{ marginBottom: '16px' }}
      extra={
        <Button type="danger" ghost icon={<DeleteOutlined />} onClick={() => deletePersonWithConfirmation({ _id })} />
      }
    >
      <I18nFormik
        ref={formikRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={PersonSchema}
        enableReinitialize
      >
        <Form layout="vertical">
          <AlertFromFormik />
          <PersonForm />
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </Card>
  );
};
export default memo(Person, equal);
