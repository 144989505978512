import apollo from 'graphql/apollo';
import { deleteClientMutation } from 'graphql/mutations';

/**
 * Delete client remotely
 * @param {string} clientId - id of client
 * @returns {Promise} - Promise object represents the result of the mutation
 */
const deleteClient = async (clientId) => {
  return apollo.mutate({
    mutation: deleteClientMutation,
    variables: {
      id: clientId,
    },
    refetchQueries: ['clientEntries'],
  });
};

export default deleteClient;
