import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import classes from './htmlModal.module.less';

/**
 * Component which renders a help button in the footer of the HtmlModal component.
 * When the button is clicked, a modal with a video, help text and option to book a meeting is shown.
 *
 * @returns {JSX.Element} The rendered MoreInfoWidget component.
 */
const HtmlModalFooterInfoWidget = () => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonClassName={classes.infoWidgetButton}
      buttonText={t('common.htmlModalFooterInfoWidget.infoButtonText')}
      title={t('common.htmlModalFooterInfoWidget.modalInfo.title')}
      helpText={{
        key: 'common.htmlModalFooterInfoWidget.modalInfo.helpText',
      }}
      videoCaption={t('common.htmlModalFooterInfoWidget.modalInfo.videoCaption')}
      videoUrl={t('common.htmlModalFooterInfoWidget.modalInfo.videoUrl')}
      imageUrl={t('common.htmlModalFooterInfoWidget.modalInfo.imageUrl')}
    />
  );
};

/**
 * Component which renders the footer of the HtmlModal component.
 * The footer includes a MoreInfoWidget (with a help button) and an OK button.
 *
 * @param {Object} inputProperties - The component props.
 * @param {Function} inputProperties.closeModal - The function to call when the OK button is clicked.
 * @returns {JSX.Element} The rendered component.
 */
const HtmlModalFooterComponent = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.documentPreviewInfoWidget}>
      <div /> {/* Empty div to center the Widget */}
      <HtmlModalFooterInfoWidget />
      <Button
        type="primary"
        onClick={() => {
          closeModal();
        }}
      >
        {t('common.ok')}
      </Button>
    </div>
  );
};

export default HtmlModalFooterComponent;
