import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import roles from 'constants/roles';
import changeProjectCardState from '../methods/changeProjectCardState';
import classes from './ProjectCardStateWithChangeButton.module.less';

/**
 * Project card state value with a change button, if the user is not a viewer
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.status - The status of the project card
 * @param {string} inputProperties.status._id - The id of the status
 * @param {string} inputProperties.status.name - The name of the status
 * @param {string} inputProperties.projectCardId - The id of the project card
 * @returns {JSX.Element} The project card state with a change button
 * @component
 */
const ProjectCardStateWithChangeButton = ({ status, projectCardId }) => {
  const [me] = useCurrentUser();
  const { t } = useTranslation();

  return (
    <div>
      {status ? status.name : t(`ShoppingCartEntriesPage.noStatus`)}
      {me?.role === roles.VIEWER ? null : (
        <>
          {' '}
          <Button
            type="primary"
            className={classes.change_project_card_state_button}
            ghost
            icon={<EditOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              return changeProjectCardState(projectCardId, status?._id);
            }}
          />
        </>
      )}
    </div>
  );
};

export default ProjectCardStateWithChangeButton;
