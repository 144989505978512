import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { Input, Select } from 'formik-antd';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import toast from 'utils/toast';
import * as Yup from 'yup';
import inputConfirmModal from 'utils/inputConfirmModal';
import { InputNumber } from 'components/common/InputNumber';
import { createTenant, updateTenant } from 'graphql/methods';
import InputDate from 'components/common/InputDate';
import Switch from 'components/common/Switch';
import AccessGroupsMultiSelect from 'components/common/AccessGroupsMultiSelect';
import classes from './TenantForm.module.less';

const sharedSchema = () => ({
  name: Yup.string().required().label(i18n.t('superAdmin.ManageTenants.fields.tenantName.label')),
  nrOfAllowedCompaniesByPricingPlan: Yup.number()
    .required()
    .label(i18n.t('superAdmin.ManageTenants.fields.nrOfAllowedCompaniesByPricingPlan.label'))
    .typeError(i18n.t('superAdmin.ManageTenants.fields.nrOfAllowedCompaniesByPricingPlan.errorMessage')),
  nrOfAllowedUsersByPricingPlan: Yup.number()
    .required()
    .label(i18n.t('superAdmin.ManageTenants.fields.nrOfAllowedUsersByPricingPlan.label'))
    .typeError(i18n.t('superAdmin.ManageTenants.fields.nrOfAllowedUsersByPricingPlan.errorMessage')),
});

export const tenantSchema = () =>
  Yup.object().shape({
    ...sharedSchema(),
    rootAdminFirstName: Yup.string()
      .required()
      .label(i18n.t('superAdmin.ManageTenants.fields.rootAdminFirstName.label')),
    rootAdminLastName: Yup.string().required().label(i18n.t('superAdmin.ManageTenants.fields.rootAdminLastName.label')),
    rootAdminEmail: Yup.string()
      .email()
      .required()
      .label(i18n.t('superAdmin.ManageTenants.fields.rootAdminEmail.label')),
  });

export const editTenantSchema = () => Yup.object().shape(sharedSchema());

export const NrOfAllowedCompaniesByPricingPlanFormikInputNumber = () => {
  const { t } = useTranslation();
  return (
    <FormItem
      name="nrOfAllowedCompaniesByPricingPlan"
      label={t('superAdmin.ManageTenants.fields.nrOfAllowedCompaniesByPricingPlan.label')}
    >
      <InputNumber
        name="nrOfAllowedCompaniesByPricingPlan"
        placeholder={t('superAdmin.ManageTenants.fields.nrOfAllowedCompaniesByPricingPlan.placeholder')}
      />
    </FormItem>
  );
};
export const NrOfAllowedUsersByPricingPlanFormikInputNumber = () => {
  const { t } = useTranslation();
  return (
    <FormItem
      name="nrOfAllowedUsersByPricingPlan"
      label={t('superAdmin.ManageTenants.fields.nrOfAllowedUsersByPricingPlan.label')}
    >
      <InputNumber
        name="nrOfAllowedUsersByPricingPlan"
        placeholder={t('superAdmin.ManageTenants.fields.nrOfAllowedUsersByPricingPlan.placeholder')}
      />
    </FormItem>
  );
};

export const LicenseStartedFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="licenseStarted" label={t('superAdmin.ManageTenants.fields.licenseStarted.label')}>
      <InputDate name="licenseStarted" format="DD/MM/YYYY" formatTo="YYYY-MM-DD" />
    </FormItem>
  );
};

export const TenantNameFormikInputNumber = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="name" label={t('superAdmin.ManageTenants.fields.tenantName.label')}>
      <Input name="name" placeholder={t('superAdmin.ManageTenants.fields.tenantName.placeholder')} />
    </FormItem>
  );
};

const LocaleFormikInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem name="locale" label={t('superAdmin.ManageTenants.fields.locale.label')}>
      <Select
        name="locale"
        defaultValue={locales[0]}
        showSearch
        placeholder={t('superAdmin.ManageTenants.fields.locale.placeholder')}
      >
        {locales.map((locale) => (
          <Select.Option value={locale} key={locale}>
            {t(`superAdmin.ManageTenants.fields.locale.values.${locale}`)}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

const locales = ['de', 'en'];

export const TenantForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <TenantNameFormikInputNumber />
      <FormItem name="rootAdminFirstName" label={t('superAdmin.ManageTenants.fields.rootAdminFirstName.label')}>
        <Input
          name="rootAdminFirstName"
          placeholder={t('superAdmin.ManageTenants.fields.rootAdminFirstName.placeholder')}
        />
      </FormItem>
      <FormItem name="rootAdminLastName" label={t('superAdmin.ManageTenants.fields.rootAdminLastName.label')}>
        <Input
          name="rootAdminLastName"
          placeholder={t('superAdmin.ManageTenants.fields.rootAdminLastName.placeholder')}
        />
      </FormItem>
      <FormItem name="rootAdminEmail" label={t('superAdmin.ManageTenants.fields.rootAdminEmail.label')}>
        <Input name="rootAdminEmail" placeholder={t('superAdmin.ManageTenants.fields.rootAdminEmail.placeholder')} />
      </FormItem>
      <LocaleFormikInput />
      <NrOfAllowedCompaniesByPricingPlanFormikInputNumber />
      <NrOfAllowedUsersByPricingPlanFormikInputNumber />
      <AccessGroupsMultiSelect
        fieldName="commonLibraryAccessGroups"
        label={t('superAdmin.ManageTenants.fields.accessGroups.label')}
        tooltip={t('superAdmin.ManageTenants.fields.accessGroups.tooltip')}
        placeholder={t('superAdmin.ManageTenants.fields.accessGroups.placeholder')}
        className={classes.commonLibraryAccessGroups}
      />
      <LicenseStartedFormikInput />
    </>
  );
};
export const createTenantModal = () =>
  inputConfirmModal({
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    formContent: () => <TenantForm />,
    fields: [],
    headerText: i18n.t('superAdmin.ManageTenants.addTenantModalTitle'),
    onSubmit: ({
      name,
      rootAdminFirstName,
      rootAdminLastName,
      rootAdminEmail,
      nrOfAllowedCompaniesByPricingPlan,
      nrOfAllowedUsersByPricingPlan,
      licenseStarted,
      locale,
      commonLibraryAccessGroups,
    }) =>
      createTenant({
        name,
        firstName: rootAdminFirstName,
        lastName: rootAdminLastName,
        email: rootAdminEmail,
        nrOfAllowedCompaniesByPricingPlan,
        nrOfAllowedUsersByPricingPlan,
        licenseStarted,
        locale,
        commonLibraryAccessGroups,
      }).then(toast.successDefault),
    value: {
      name: '',
      rootAdminFirstName: '',
      rootAdminLastName: '',
      rootAdminEmail: '',
      nrOfAllowedCompaniesByPricingPlan: '',
      nrOfAllowedUsersByPricingPlan: '',
      licenseStarted: new Date().toISOString(),
      commonLibraryAccessGroups: [],
    },
    validationSchema: tenantSchema,
    errorResolver: { Duplicated: ['name', i18n.t('common.duplicatedErrorMessage', { name: 'Name' })] },
    width: '600px',
  });

export const editTenantModal = (tenant) =>
  inputConfirmModal({
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    formContent: () => (
      <>
        <TenantNameFormikInputNumber />
        <NrOfAllowedCompaniesByPricingPlanFormikInputNumber />
        <NrOfAllowedUsersByPricingPlanFormikInputNumber />
        <LicenseStartedFormikInput />
        <LocaleFormikInput />
        <AccessGroupsMultiSelect
          fieldName="commonLibraryAccessGroups"
          label={i18n.t('superAdmin.ManageTenants.fields.accessGroups.label')}
          tooltip={i18n.t('superAdmin.ManageTenants.fields.accessGroups.tooltip')}
          placeholder={i18n.t('superAdmin.ManageTenants.fields.accessGroups.placeholder')}
          className={classes.commonLibraryAccessGroups}
        />
        <Switch
          label={i18n.t('superAdmin.ManageTenants.fields.disabled.label')}
          name="disabled"
          checkedChildren={i18n.t('superAdmin.ManageTenants.fields.disabled.values.disabled')}
          unCheckedChildren={i18n.t('superAdmin.ManageTenants.fields.disabled.values.enabled')}
        />
      </>
    ),
    fields: [],
    headerText: i18n.t('superAdmin.ManageTenants.editTenantModalTitle'),
    onSubmit: ({
      nrOfAllowedCompaniesByPricingPlan,
      name,
      licenseStarted,
      locale,
      disabled,
      nrOfAllowedUsersByPricingPlan,
      commonLibraryAccessGroups,
    }) =>
      updateTenant({
        _id: tenant._id,
        nrOfAllowedCompaniesByPricingPlan,
        nrOfAllowedUsersByPricingPlan,
        name,
        licenseStarted,
        locale,
        disabled,
        commonLibraryAccessGroups,
      }).then(toast.successDefault),
    value: {
      name: tenant.name || '',
      nrOfAllowedCompaniesByPricingPlan: tenant.nrOfAllowedCompaniesByPricingPlan || '',
      nrOfAllowedUsersByPricingPlan: tenant.nrOfAllowedUsersByPricingPlan || '',
      licenseStarted: tenant.licenseStarted,
      locale: tenant?.rootAdmin?.locale,
      disabled: tenant.disabled || false,
      commonLibraryAccessGroups: tenant.commonLibraryAccessGroups || [],
    },
    validationSchema: editTenantSchema,
    errorResolver: { Duplicated: ['name', i18n.t('common.duplicatedErrorMessage', { name: 'Name' })] },
    width: '600px',
  });
