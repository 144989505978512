import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'formik-antd';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { userGetStatusesQuery } from 'graphql/queries';
import SelectWithLabel from 'components/common/FormComponents/Formik/SelectWithLabel';

/**
 * Modal content for change project card state
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.status - Status id of the project card
 * @returns {JSX.Element} The content of the modal
 * @component
 */
const ChangeProjectCardStateModalContent = ({ status: initialValue }) => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(userGetStatusesQuery, { variables: { type: 'shoppingCart' } });
  const statuses = useMemo(() => grabFirstGQLDataResult(data), [data]);

  return (
    <SelectWithLabel
      name="status"
      inputProps={{
        loading,
        defaultValue: initialValue && t(`ShoppingCartEntriesPage.statusModal.placeholder`),
        disabled: loading,
        showSearch: true,
        style: { width: '100%' },
      }}
      placeholder={t('ShoppingCartEntriesPage.statusModal.placeholder')}
    >
      {loading
        ? null
        : statuses.map((status) => (
            <Select.Option value={status._id} key={status._id}>
              {status.name}
            </Select.Option>
          ))}
    </SelectWithLabel>
  );
};

export default ChangeProjectCardStateModalContent;
