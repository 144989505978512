import React from 'react';
import { SubmitButton, Input, Form } from 'formik-antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import useLogin from 'hooks/auth/useLogin';
import * as Yup from 'yup';
import routePaths from 'router/route-paths';
import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import { getOriginalMessageFromGraphQLError } from 'utils/helpers';
import classes from './LoginForm.module.less';

const ForgotPasswordButton = () => {
  const { t } = useTranslation();
  return (
    <Link className="grey-link margin-top-16 margin-bottom-16 text-align-center" to={routePaths.resetPassword}>
      {t('Login.resetPassword')}
    </Link>
  );
};

const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string().label('Email').email().required(),
    password: Yup.string().label('Password').min(6).max(64).required(),
  });

const initialValues = {
  email: '',
  password: '',
};

export default function LoginForm() {
  const login = useLogin();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = async ({ email, password }, formik) => {
    try {
      formik.setSubmitting(true);
      await login({ email, password });
      formik.resetForm();
      navigate(routePaths.home, { replace: true });
    } catch (error) {
      console.error(error);
      formik.setErrors(
        setGraphqlErrors({
          error,
          // eslint-disable-next-line no-shadow
          errorResolver: ({ message, error }) => {
            if (getOriginalMessageFromGraphQLError(error).includes('userNotFound')) return ['email', message];
            if (message.includes('IncorrectPasswordError')) return ['password', t('Login.inputs.password.error')];
            return undefined;
          },
          showErrorsInFormForValues: ['email'],
        }),
      );
    } finally {
      formik.setSubmitting(false);
    }
  };
  return (
    <I18nFormik initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginSchema()}>
      <Form layout="vertical" className={classes.form}>
        <AlertFromFormik />
        <FormItem name="email">
          <Input
            className="auth-input"
            prefix={<UserOutlined className="site-form-item-icon" />}
            name="email"
            placeholder={t('Login.inputs.email.placeholder')}
            autoComplete="username"
          />
        </FormItem>
        <FormItem name="password">
          <Input.Password
            className="auth-input"
            prefix={<LockOutlined className="site-form-item-icon" />}
            name="password"
            placeholder={t('Login.inputs.password.placeholder')}
            autoComplete="current-password"
          />
        </FormItem>
        <SubmitButton>{t('Login.submit')}</SubmitButton>
        <ForgotPasswordButton />
      </Form>
    </I18nFormik>
  );
}
