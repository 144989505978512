import { Suspense } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import ClientViewPageHelperWidget from '../components/ClientView/ClientViewPageHelperWidget';
import ClientDetailsCard from '../components/ClientView/ClientDetails/ClientDetailsCard';
import ClientDataTabs from '../components/ClientView/ClientDataTabs/ClientDataTabs';
import useClient from '../hooks/useClient';

/**
 * Page for showing details of a client (Uses id parameter from URL to specify the client and search parameters for setting default open tab)
 * @returns {JSX.Element} The ClientViewPage component
 * @component
 */
const ClientViewPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { client, error, loading } = useClient(id);

  return (
    <PageContainer title={t('ClientViewPage.title')} left={<ClientViewPageHelperWidget />}>
      <ClientDetailsCard client={client} error={error} loading={loading} />
      {client ? (
        <Suspense>
          <ClientDataTabs client={client} />
        </Suspense>
      ) : null}
    </PageContainer>
  );
};

export default ClientViewPage;
