import { useQuery } from '@apollo/client';
import { userCompanyQuery } from 'graphql/queries';

const useClient = (clientId, fetchPolicy = 'cache-and-network') => {
  const { data, error, loading } = useQuery(userCompanyQuery, { variables: { _id: clientId }, fetchPolicy });
  const client = data?.companyById;
  return { client, error, loading };
};

export default useClient;
