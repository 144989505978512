import React from 'react';
import defaults from 'lodash/defaults';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { TableMemo } from 'memo';
import { Form } from 'formik-antd';
import pick from 'lodash/pick';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { PricingTable, HourlyRatesTable, OtherSettingsTable } from './PricingLevel.utils';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';
import { useOnboardingContext } from '../OnboardingContext';

const arrToObj = (arr, value) =>
  arr.reduce((acc, name) => {
    acc[name] = typeof value === 'function' ? value(name) : value;
    return acc;
  }, {});
const schema = () =>
  Yup.object().shape({
    ...arrToObj(PricingTable.rows, (name) =>
      Yup.number()
        .label(i18n.t(`onboarding.pricingLevel.pricingTable.${name}`))
        .nullable()
        .required(''),
    ),
    ...arrToObj(HourlyRatesTable.rows, (name) =>
      Yup.number()
        .label(i18n.t(`onboarding.pricingLevel.hourlyRatesTable.${name}`))
        .nullable()
        .required(''),
    ),
    ...arrToObj(OtherSettingsTable.rows, (name) =>
      Yup.string()
        .label(i18n.t(`onboarding.pricingLevel.otherSettingsTable.${name}`))
        .nullable()
        .required(''),
    ),
  });

const defaultValues = {
  ...arrToObj([...PricingTable.rows, ...HourlyRatesTable.rows, ...OtherSettingsTable.rows], null),
};

export default function PricingLevel() {
  const { onSubmit, formValuesRef } = useOnboardingContext();
  const { t } = useTranslation();

  const initialValues = React.useMemo(
    () => defaults({}, pick(formValuesRef.current, Object.keys(defaultValues)), defaultValues),
    [formValuesRef],
  );

  return (
    <I18nFormik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={schema()}
    >
      {({ values, isSubmitting }) => (
        <Form layout="vertical">
          <OnboardingStepTemplate
            title={t('onboarding.pricingLevel.title')}
            description={t('onboarding.pricingLevel.textAboveVideo')}
            videoUrl={t('onboarding.pricingLevel.videoUrl')}
            videoCaption={t('onboarding.pricingLevel.videoCaption')}
            isSubmitting={isSubmitting}
            onPrev={() => {
              formValuesRef.current = { ...formValuesRef.current, ...values };
            }}
          >
            {/* {Object.keys(errors).length !== 0 ? (
              <>
                <ErrorComponent description={t('onboarding.pricingLevel.validationError')} />
              </>
            ) : null} */}
            <h2>{t('onboarding.pricingLevel.pricingTable.title')}</h2>
            <TableMemo
              bordered={false}
              dataSource={PricingTable.rows.map((name) => ({ name, value: values[name], key: name }))}
              pagination={false}
              columns={PricingTable.getColumns({ t, unitName: '/ 10' })}
            />

            <div style={{ marginTop: 40 }} />

            <h2>{t('onboarding.pricingLevel.hourlyRatesTable.title')}</h2>
            <TableMemo
              bordered={false}
              dataSource={HourlyRatesTable.rows.map((name) => ({ name, value: values[name], key: name }))}
              pagination={false}
              columns={HourlyRatesTable.getColumns({ t, unitName: '€' })}
            />

            <div style={{ marginTop: 40 }} />

            <h2>{t('onboarding.pricingLevel.otherSettingsTable.title')}</h2>
            <TableMemo
              bordered={false}
              dataSource={OtherSettingsTable.rows.map((name) => ({ name, value: values[name], key: name }))}
              pagination={false}
              columns={OtherSettingsTable.getColumns({ t, unitName: '€' })}
            />
          </OnboardingStepTemplate>

          <FormikErrorFocus
            // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
            offset={-200}
            align="top"
            focusDelay={200}
            ease="linear"
            duration={300}
          />
        </Form>
      )}
    </I18nFormik>
  );
}
