import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import routePaths from 'router/route-paths';
import { ListLayout } from 'components/common/List';
import { RenderDateTime } from 'components/common/List/FieldRenderers';
import ICONS_BY_STATE from 'components/user/SigningStateIcon';
import IconWithText from 'components/common/IconWithText';
import { DeleteButton } from 'components/common/List/DefaultActionButtons';
import deleteClientWithConfirmation from '../methods/deleteClientWithConfirmation';
import useClientEntries from '../hooks/useClientEntries';

/**
 * Generates search card configuration for list layout
 * @param {function} t - translation function
 * @returns {object} search card configuration
 */
const generateSearchCardConfig = (t) => ({
  searchPlaceholder: t('ClientsListPage.list.searchPlaceholder'),
  filters: [
    {
      fieldName: 'type',
      label: t('ClientsListPage.list.clientType.label'),
      options: [
        {
          label: t('ClientsListPage.list.clientType.all'),
          value: 'all',
        },
        {
          label: t('ClientsListPage.list.clientType.privatePerson'),
          value: 'individual',
        },
        {
          label: t('ClientsListPage.list.clientType.company'),
          value: 'company',
        },
      ],
    },
  ],
});

/**
 * Generates sort bar configuration for list layout
 * @param {function} t - translation function
 * @returns {object} sort bar configuration
 */
const generateSortBarConfig = (t) => ({
  initSort: ['name', 'ASC'],
  sortFields: [
    { fieldName: 'name', label: t('ClientsListPage.list.name') },
    { fieldName: 'identifier', label: t('ClientsListPage.list.clientNumber') },
    { fieldName: 'countOfActiveDSProcesses', label: t('ClientsListPage.list.digitalSignatureState') },
    { fieldName: 'contactNames', label: t('ClientsListPage.list.contactPersons') },
    { fieldName: 'updatedAt', label: t('ClientsListPage.list.updatedAt') },
  ],
});

/**
 * Generates renderer for digital signature state of a client
 * @param {function} t - translation function
 * @returns {function} render function for digital signature state
 */
const renderDSState = (t) => (value) => {
  if (value === -1)
    return (
      <IconWithText
        icon={ICONS_BY_STATE.NO_DIGITAL_SIGNATURE({ size: '16' })}
        text={t('ClientsListPage.list.digitalSignatureStateValues.noSignatureProcess')}
      />
    );
  if (value === 0)
    return (
      <IconWithText
        icon={ICONS_BY_STATE.SIGNED({ size: '16' })}
        text={t('ClientsListPage.list.digitalSignatureStateValues.allSigned')}
      />
    );
  if (value === 1)
    return (
      <IconWithText
        icon={ICONS_BY_STATE.STARTED({ size: '16' })}
        text={t('ClientsListPage.list.digitalSignatureStateValues.oneActive')}
      />
    );
  return (
    <IconWithText
      icon={ICONS_BY_STATE.STARTED({ size: '16' })}
      text={t('ClientsListPage.list.digitalSignatureStateValues.multipleActive', { count: value })}
    />
  );
};

/**
 * Generates list item configuration for list layout
 * @param {function} t - translation function
 * @returns {object} list item configuration
 */
const generateListItemConfig = (t, navigate) => ({
  rows: [
    {
      rowColumns: [
        { fieldName: 'name', fieldLabel: t('ClientsListPage.list.name') },
        { fieldName: 'identifier', fieldLabel: t('ClientsListPage.list.clientNumber') },
        {
          fieldName: 'type',
          fieldLabel: t('ClientsListPage.list.clientType.label'),
          renderValue: (value) => t(`ClientsListPage.list.clientType.${value}`),
        },
        {
          fieldName: 'countOfActiveDSProcesses',
          fieldLabel: t('ClientsListPage.list.digitalSignatureState'),
          renderValue: renderDSState(t),
        },
      ],
      alignLastEntryRight: true,
    },
    {
      rowColumns: [
        { fieldName: 'contactNames', fieldLabel: t('ClientsListPage.list.contactPersons') },
        { fieldName: 'updatedAt', fieldLabel: t('ClientsListPage.list.updatedAt'), renderValue: RenderDateTime },
      ],
      alignLastEntryRight: true,
    },
  ],
  actionButtons: [DeleteButton((client) => deleteClientWithConfirmation(client._id))],
  onClick: (client) => {
    navigate(generatePath(routePaths.client, { id: client._id }));
  },
});

/**
 * List with all clients of a tenant for the client management
 * @returns {JSX.Element} Clients list page
 * @component
 */
const ClientsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const listItemConfig = useMemo(() => generateListItemConfig(t, navigate), [t, navigate]);
  const searchCardConfig = useMemo(() => generateSearchCardConfig(t), [t]);
  const sortBarConfig = useMemo(() => generateSortBarConfig(t), [t]);

  return (
    <ListLayout
      listItemConfig={listItemConfig}
      searchCardConfig={searchCardConfig}
      sortBarConfig={sortBarConfig}
      useDataFetching={useClientEntries}
      shouldUseSearchQueryParams
      paginated
    />
  );
};

export default ClientsList;
