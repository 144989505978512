import DefaultListLayoutHandler from './ListHandler/DefaultListLayoutHandler';
import PaginationListLayoutHandler from './ListHandler/PaginationListLayoutHandler';
import Pagination from './Layout/Pagination';
import Layout from './Layout/Layout';
import InlineLayout from './Layout/InlineLayout';
// import classes from './ListLayout.module.less';

/**
 * List layout component to render the list layout with the given configurations
 * @param {object} inputProperties - Input properties of the component
 * @param {object} inputProperties.listItemConfig - Configuration for list items
 * @param {object[]} inputProperties.listItemConfig.rows - Rows to show in the list
 * @param {object[]} inputProperties.listItemConfig.rows.rowColumns - Columns to show in the row
 * @param {string} inputProperties.listItemConfig.rows.rowColumns.fieldName - Field name of the item
 * @param {string} inputProperties.listItemConfig.rows.rowColumns.fieldLabel - Label for the column
 * @param {(value: *) => JSX.Element} inputProperties.listItemConfig.rows.rowColumns.renderValue - Optional: Function to render the value
 * @param {boolean} inputProperties.listItemConfig.rows.alignLastEntryRight - Should the last entry of the row be aligned to the right
 * @param {object[]} inputProperties.listItemConfig.actionButtons - Action buttons for the list items
 * @param {React.ReactNode} inputProperties.listItemConfig.actionButtons.icon - Icon for the action button
 * @param {string} inputProperties.listItemConfig.actionButtons.key - Key for the action button
 * @param {(item: object) => void} inputProperties.listItemConfig.actionButtons.onClick - Function to call when the action button is clicked
 * @param {boolean} inputProperties.listItemConfig.actionButtons.danger - Should the action button in danger style
 * @param {(item: object) => void} inputProperties.listItemConfig.onClick - Function to call when the list item is clicked
 * @param {boolean} inputProperties.listItemConfig.collapseContent - Collapse content of the list items
 * @param {boolean} inputProperties.listItemConfig.collapsable - List items are collapsable
 * @param {boolean} inputProperties.listItemConfig.selectable - List items are selectable
 * @param {boolean} inputProperties.listItemConfig.draggable - List items are draggable
 * @param {boolean} inputProperties.listItemConfig.showLabel - Show label for the list items
 * @param {object} inputProperties.searchCardConfig - Configuration for search card
 * @param {object[]} inputProperties.searchCardConfig.filters - Filters to show in the search card
 * @param {string} inputProperties.searchCardConfig.filters.fieldName - Field name of item for the filter
 * @param {string} inputProperties.searchCardConfig.filters.label - Label for the filter
 * @param {string} inputProperties.searchCardConfig.filters.options - Options for the filter
 * @param {string} inputProperties.searchCardConfig.filters.options.value - Value for the filter option
 * @param {string} inputProperties.searchCardConfig.filters.options.label - Label for the filter option
 * @param {(item: object, filterValue: string) => boolean} inputProperties.searchCardConfig.filters.applyFilter - Optional: Function to apply the filter
 * @param {object[]} inputProperties.searchCardConfig.queryFields - Fields to search in
 * @param {string} inputProperties.searchCardConfig.searchPlaceholder - Placeholder for the search input
 * @param {object} inputProperties.sortBarConfig - Configuration for sort bar
 * @param {object[]} inputProperties.sortBarConfig.sortFields - Fields to sort by
 * @param {string} inputProperties.sortBarConfig.sortFields.fieldName - Field name of item to sort by
 * @param {string} inputProperties.sortBarConfig.sortFields.label - Label for the sort field
 * @param {(itemA: object, itemB: object) => number} inputProperties.sortBarConfig.sortFields.compareFunc - Optional: Function to compare the sort field
 * @param {string[]} inputProperties.sortBarConfig.initSort - Initial sort configuration [fieldName, direction]
 * @param {() => object | (query: string, pageSize: number, skip: number, sort: string[], filter: string[][]) => object} inputProperties.useDataFetching - Hook to fetch data, which returns an object with data, error and loading
 * @param {boolean} inputProperties.shouldUseSearchQueryParams - Should the list encode search parameters in the URL
 * @param {boolean} inputProperties.paginated - Should the list be paginated (Needs a fetch hook, which supports pagination)
 * @param {object} inputProperties.dragAndDropConfig - Configuration for drag and drop
 * @param {string} inputProperties.dragAndDropConfig.orderField - Field name for the order
 * @param {string} inputProperties.dragAndDropConfig.orderDirection - Order direction for the drag and drop
 * @param {(item: object) => void} inputProperties.dragAndDropConfig.onOrderChange - Function to call when the order changes with updated item
 * @param {string} inputProperties.identifier - Identifier field for the list items (Default: '_id')
 * @param {boolean} inputProperties.inline - Should the list be rendered inline
 * @param {boolean} inputProperties.bigList - Should the list be rendered as a big list
 * @param {object} inputProperties.lazyLoadDataConfig - Configuration for lazy loading of data
 * @param {number} inputProperties.lazyLoadDataConfig.elementsAtOnce - Number of elements to load at once (Default: 20)
 * @returns {JSX.Element} List layout with search card, sort bar and list items (and pagination if paginated)
 * @component
 */
const ListLayout = ({
  listItemConfig,
  searchCardConfig,
  sortBarConfig,
  useDataFetching,
  shouldUseSearchQueryParams,
  paginated = false,
  dragAndDropConfig,
  identifier = '_id',
  inline = false,
  bigList = false,
  lazyLoadDataConfig = { elementsAtOnce: 20 },
}) => {
  if (!paginated)
    return (
      <DefaultListLayoutHandler
        useDataFetching={useDataFetching}
        shouldUseSearchQueryParams={shouldUseSearchQueryParams}
        initSort={sortBarConfig.initSort}
        sortSettings={sortBarConfig.sortFields}
        filterSettings={searchCardConfig.filters}
        queryFields={searchCardConfig.queryFields}
        dragAndDropSettings={dragAndDropConfig}
        identifier={identifier}
      >
        {(
          sortedAndFilteredData,
          error,
          loading,
          sortState,
          setSort,
          filterState,
          setFilter,
          queryState,
          setQuery,
          selectAll,
          selectOne,
          selected,
          selectAllActive,
          resetSortAndFilter,
          dragEnabled,
          moveData,
        ) => {
          if (inline)
            return (
              <InlineLayout
                data={sortedAndFilteredData}
                error={error}
                loading={loading}
                listItemConfig={listItemConfig}
                sortBarConfig={sortBarConfig}
                dragAndDropConfig={dragAndDropConfig}
                currentSort={sortState}
                onSortChange={setSort}
                selected={selected}
                selectAll={selectAll}
                selectOne={selectOne}
                selectAllActive={selectAllActive}
                resetSortAndFilter={resetSortAndFilter}
                dragEnabled={dragEnabled}
                moveData={moveData}
                identifier={identifier}
                bigList={bigList}
                lazyLoadDataConfig={lazyLoadDataConfig}
              />
            );
          return (
            <Layout
              data={sortedAndFilteredData}
              listItemConfig={listItemConfig}
              searchCardConfig={searchCardConfig}
              sortBarConfig={sortBarConfig}
              dragAndDropConfig={dragAndDropConfig}
              onFilterChange={setFilter}
              onQueryChange={setQuery}
              currentQuery={queryState}
              activeFilters={filterState}
              currentSort={sortState}
              onSortChange={setSort}
              error={error}
              loading={loading}
              selected={selected}
              selectAll={selectAll}
              selectOne={selectOne}
              selectAllActive={selectAllActive}
              resetSortAndFilter={resetSortAndFilter}
              dragEnabled={dragEnabled}
              moveData={moveData}
              identifier={identifier}
              bigList={bigList}
              lazyLoadDataConfig={lazyLoadDataConfig}
            />
          );
        }}
      </DefaultListLayoutHandler>
    );
  return (
    <PaginationListLayoutHandler
      useDataFetching={useDataFetching}
      shouldUseSearchQueryParams={shouldUseSearchQueryParams}
      initSort={sortBarConfig.initSort}
      sortSettings={sortBarConfig.sortFields}
      queryFields={searchCardConfig.queryFields}
      identifier={identifier}
    >
      {(
        sortedAndFilteredData,
        error,
        loading,
        sortState,
        setSort,
        filterState,
        setFilter,
        queryState,
        setQuery,
        selectAll,
        selectOne,
        selected,
        selectAllActive,
        resetSortAndFilter,
        dragEnabled,
        totalItemCount,
        currentPage,
        currentPageSize,
        changePage,
      ) => (
        <>
          {inline ? (
            <InlineLayout
              data={sortedAndFilteredData}
              error={error}
              loading={loading}
              listItemConfig={listItemConfig}
              sortBarConfig={sortBarConfig}
              currentSort={sortState}
              onSortChange={setSort}
              selected={selected}
              selectAll={selectAll}
              selectOne={selectOne}
              selectAllActive={selectAllActive}
              resetSortAndFilter={resetSortAndFilter}
              dragEnabled={dragEnabled}
              identifier={identifier}
            />
          ) : (
            <Layout
              data={sortedAndFilteredData}
              listItemConfig={listItemConfig}
              searchCardConfig={searchCardConfig}
              sortBarConfig={sortBarConfig}
              onFilterChange={setFilter}
              onQueryChange={setQuery}
              currentQuery={queryState}
              activeFilters={filterState}
              currentSort={sortState}
              onSortChange={setSort}
              error={error}
              loading={loading}
              selected={selected}
              selectAll={selectAll}
              selectOne={selectOne}
              selectAllActive={selectAllActive}
              resetSortAndFilter={resetSortAndFilter}
              dragEnabled={dragEnabled}
              identifier={identifier}
            />
          )}
          <Pagination
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            count={totalItemCount}
            changePage={changePage}
          />
        </>
      )}
    </PaginationListLayoutHandler>
  );
};

export default ListLayout;
