import { Space } from 'antd';
import { Radio as RadioFormik } from 'formik-antd';
import { Radio } from 'components/common/Radio';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { useTranslation } from 'react-i18next';
import { MONTHLY_PAYMENT_DECISION } from 'constants/monthlyPayment';
import classes from './MonthlyPaymentOptionSelection.module.less';

const MonthlyPaymentOptionSelection = ({ questionText }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.questionTextLabel}>
        <label htmlFor="monthlyPaymentDecision">{questionText}</label>
      </div>
      <FormItem
        name="monthlyPaymentDecision"
        id="monthlyPaymentDecision"
        style={{ marginLeft: '16px', marginBottom: '8px' }}
      >
        <RadioFormik.Group name="monthlyPaymentDecision">
          <Space direction="vertical">
            <Radio name="monthlyPaymentDecision" value={MONTHLY_PAYMENT_DECISION.YES}>
              {t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.optionYes')}
            </Radio>
            <Radio name="monthlyPaymentDecision" value={MONTHLY_PAYMENT_DECISION.NO}>
              {t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.optionNo')}
            </Radio>
            <Radio name="monthlyPaymentDecision" value={MONTHLY_PAYMENT_DECISION.DECIDE_LATER}>
              {t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.defaultOption.optionDecideLater')}
            </Radio>
          </Space>
        </RadioFormik.Group>
      </FormItem>
    </div>
  );
};

export default MonthlyPaymentOptionSelection;
