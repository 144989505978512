import { useMemo } from 'react';
import { Select } from 'formik-antd';
import { useCachedQuery } from 'graphql/utils';
import { adminDocumentTemplateListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import SelectWithLabel from './FormComponents/Formik/SelectWithLabel';

const { Option } = Select;

/**
 * Document template select component, which is used for template selection in settings
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.label - Label of the input field
 * @param {string} inputProperties.fieldName - Name of the input field
 * @param {string} inputProperties.placeholder - Placeholder of the input field
 * @param {string} inputProperties.tooltip - Tooltip of the input field
 * @param {boolean} inputProperties.isLibrary - Whether the document template is a library
 * @returns {JSX.Element} Document template select component
 * @component
 */
const DocumentTemplateSelect = ({ label, fieldName, placeholder, tooltip, isLibrary = false }) => {
  const { data, loading } = useCachedQuery(adminDocumentTemplateListQuery, {
    variables: { isLibrary },
  });
  const documentTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);

  return (
    <SelectWithLabel
      name={fieldName}
      label={label}
      tooltip={tooltip}
      placeholder={placeholder}
      inputProps={{
        loading,
        disabled: loading,
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'title',
      }}
    >
      {loading ||
        documentTemplates?.map(({ _id, name }) => (
          <Option key={_id} value={_id} title={name}>
            {name}
          </Option>
        ))}
    </SelectWithLabel>
  );
};

export default DocumentTemplateSelect;
