import { gql } from '@apollo/client';

const clientEntries = gql`
  query clientEntries(
    $query: String
    $limit: Int!
    $skip: Int
    $sortField: String
    $sortOrder: Int
    $clientType: String
  ) {
    clientEntries(
      query: $query
      limit: $limit
      skip: $skip
      sortField: $sortField
      sortOrder: $sortOrder
      clientType: $clientType
    ) {
      _id
      count
      clientEntries {
        _id
        name
        type
        identifier
        countOfActiveDSProcesses
        contactNames
        updatedAt
      }
    }
  }
`;

export default clientEntries;
