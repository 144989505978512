import { Button } from 'components/common/Button';
import classes from './ActionButton.module.less';

/**
 * Action button with icon for lists and cards
 * @param {object} inputProperties - Input properties of the component
 * @param {React.ReactNode} inputProperties.icon - Icon of the button
 * @param {string} inputProperties.type - Type of the button
 * @param {boolean} inputProperties.loading - Whether the button is loading
 * @param {boolean} inputProperties.disabled - Whether the button is disabled
 * @param {Function} inputProperties.onClick - On click handler
 * @returns {JSX.Element} Action button
 * @component
 */
const ActionButton = ({ icon, type = 'primary', loading, disabled, onClick }) => {
  return (
    <Button
      className={classes.action_button}
      ghost
      icon={icon}
      type={type}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default ActionButton;
