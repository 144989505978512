import { Empty } from 'antd';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import SortBar from '../SortBar/SortBar';
import ListItems from './ListItems';
import Loading from './Loading';

/**
 * Layout with the components for the inline layout list like the sort bar and the list items
 * @param {object} inputProperties - Input properties of the component
 * @param {object[]} inputProperties.data - Data entries for the list
 * @param {object} inputProperties.listItemConfig - Configuration for list items
 * @param {object[]} inputProperties.listItemConfig.rows - Rows to show in the list
 * @param {object[]} inputProperties.listItemConfig.rows.rowColumns - Columns to show in the row
 * @param {string} inputProperties.listItemConfig.rows.rowColumns.fieldName - Field name of the item
 * @param {string} inputProperties.listItemConfig.rows.rowColumns.fieldLabel - Label for the column
 * @param {(value: *) => JSX.Element} inputProperties.listItemConfig.rows.rowColumns.renderValue - Optional: Function to render the value
 * @param {boolean} inputProperties.listItemConfig.rows.alignLastEntryRight - Should the last entry of the row be aligned to the right
 * @param {object[]} inputProperties.listItemConfig.actionButtons - Action buttons for the list items
 * @param {React.ReactNode} inputProperties.listItemConfig.actionButtons.icon - Icon for the action button
 * @param {string} inputProperties.listItemConfig.actionButtons.key - Key for the action button
 * @param {(item: object) => void} inputProperties.listItemConfig.actionButtons.onClick - Function to call when the action button is clicked
 * @param {boolean} inputProperties.listItemConfig.actionButtons.danger - Should the action button in danger style
 * @param {(item: object) => void} inputProperties.listItemConfig.onClick - Function to call when the list item is clicked
 * @param {boolean} inputProperties.listItemConfig.collapseContent - Collapse content of the list items
 * @param {boolean} inputProperties.listItemConfig.collapsable - List items are collapsable
 * @param {boolean} inputProperties.listItemConfig.selectable - List items are selectable
 * @param {boolean} inputProperties.listItemConfig.draggable - List items are draggable
 * @param {boolean} inputProperties.listItemConfig.showLabel - Show label for the list items
 * @param {object} inputProperties.sortBarConfig - Configuration for the sort bar
 * @param {object[]} inputProperties.sortBarConfig.sortFields - Fields to sort by
 * @param {string} inputProperties.sortBarConfig.sortFields.fieldName - Field name of item to sort by
 * @param {string} inputProperties.sortBarConfig.sortFields.label - Label for the sort field
 * @param {(itemA: object, itemB: object) => number} inputProperties.sortBarConfig.sortFields.compareFunc - Optional: Function to compare the sort field
 * @param {object} inputProperties.sortBarConfig.initSort - Initial sort configuration [fieldName, direction]
 * @param {object} inputProperties.dragAndDropConfig - Configuration for drag and drop
 * @param {string} inputProperties.dragAndDropConfig.orderField - Field to order by
 * @param {string} inputProperties.dragAndDropConfig.orderDirection - Order direction
 * @param {(item: object) => void} inputProperties.dragAndDropConfig.onOrderChange - Function to call when the order changes
 * @param {string[]} inputProperties.currentSort - Current sort settings [fieldName, direction]
 * @param {(fieldName: string, direction: string) => void} inputProperties.onSortChange - Function to call when sort changes
 * @param {object[]} inputProperties.selected - Selected items
 * @param {() => void} inputProperties.selectAll - Function to select all items
 * @param {(item: object, select: boolean) => void} inputProperties.selectOne - Function to select one item
 * @param {boolean} inputProperties.selectAllActive - Whether all items are selected
 * @param {() => void} inputProperties.resetSortAndFilter - Function to reset sort and filter
 * @param {boolean} inputProperties.dragEnabled - Whether drag conditions are given
 * @param {(dragged: object, target: object) => void} inputProperties.moveData - Function to move data
 * @param {string} inputProperties.identifier - Identifier for drag and drop
 * @returns {JSX.Element} Inline layout with components for the inline layout list
 * @component
 */
const InlineLayout = ({
  data,
  loading,
  error,
  listItemConfig,
  sortBarConfig,
  dragAndDropConfig = {},
  currentSort,
  onSortChange,
  selected,
  selectAll,
  selectOne,
  selectAllActive,
  resetSortAndFilter,
  dragEnabled,
  moveData,
  identifier,
  bigList,
  lazyLoadDataConfig,
}) => {
  const { rows, actionButtons, showLabel, onClick, collapseContent, collapsable, selectable, draggable, bordered } =
    listItemConfig;
  const { sortFields } = sortBarConfig;
  const { orderField, orderDirection, onOrderChange } = dragAndDropConfig;

  return (
    <>
      <SortBar
        fields={sortFields}
        currentSortField={currentSort && currentSort.length === 2 ? currentSort[0] : null}
        currentSortDirection={currentSort && currentSort.length === 2 ? currentSort[1] : null}
        onChange={onSortChange}
        selectable={selectable}
        onSelectAll={selectAll}
        selectAllActive={selectAllActive}
        draggable={draggable}
        resetSortAndFilter={resetSortAndFilter}
        dragEnabled={dragEnabled}
      />
      {data && (
        <ListItems
          data={data}
          rows={rows}
          actionButtons={actionButtons}
          showLabel={showLabel}
          onClick={onClick}
          collapseContent={collapseContent}
          collapsable={collapsable}
          selectable={selectable}
          bordered={bordered}
          onSelect={selectOne}
          draggable={draggable && dragEnabled}
          selected={selected}
          moveData={moveData}
          onOrderChange={onOrderChange}
          orderField={orderField}
          orderDirection={orderDirection}
          identifier={identifier}
          bigList={bigList}
          lazyLoadDataConfig={lazyLoadDataConfig}
        />
      )}
      {loading && <Loading />}
      {error && <QueryErrorAlert error={error} />}
      {!loading && (!data || data.length === 0) && !error && <Empty />}
    </>
  );
};

export default InlineLayout;
