import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PageContainerActionButton from 'components/common/PageContainerActionButton';
import { useModal } from 'components/common/Modal/Modal';
import AddOrEditClientModal from './AddOrEditClientModal';

/**
 * Add new client button with modal to add a new client
 * @returns {JSX.Element} A button to add a new client
 * @component
 */
const AddNewClientButton = () => {
  const [addModalVisible, showAddModal, hideAddModal] = useModal();
  const { t } = useTranslation();

  return (
    <>
      <PageContainerActionButton icon={<PlusOutlined />} onClick={showAddModal} type="primary">
        {t('ClientsListPage.addClient')}
      </PageContainerActionButton>
      <AddOrEditClientModal closeModal={hideAddModal} visible={addModalVisible} />
    </>
  );
};

export default AddNewClientButton;
