import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';
import { useModal } from 'components/common/Modal/Modal';
import classes from './ButtonBar.module.less';
import AddOrEditClientModal from '../../AddOrEditClientModal';

/**
 * ButtonBar for client details card
 * @param {object} props - Properties of the component
 * @param {object} props.client - Client object
 * @param {string} props.client._id - Client ID
 * @returns {JSX.Element} ButtonBar for client details card
 * @component
 */
const ButtonBar = ({ client }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });
  const [editClientModalVisible, showEditClientModal, hideEditClientModal] = useModal();

  return (
    <>
      <div className={classes.button_bar}>
        {/* <Button type="primary" size="small" shape="round">
        {t('buttons.addProjectCard')}
      </Button> */}
        <Button type="default" size="small" shape="round" onClick={showEditClientModal}>
          {t('buttons.editClient')}
        </Button>
      </div>
      <AddOrEditClientModal clientId={client._id} closeModal={hideEditClientModal} visible={editClientModalVisible} />
    </>
  );
};

export default ButtonBar;
