import { gql } from '@apollo/client';

const getDocumentsOfClientQuery = gql`
  query getDocumentsOfClient($clientId: ID!) {
    getDocumentsOfClient(clientId: $clientId) {
      _id
      type
      link
      isGenerating
      size
      name
      isSigned
      createdAt
      parentId
    }
  }
`;

export default getDocumentsOfClientQuery;
