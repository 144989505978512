import { InputNumber as AntInputNumber } from 'formik-antd';

/**
 * Number input field with formik support
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.name - Name of the input field
 * @param {string} inputProperties.placeholder - Placeholder of the input field
 * @returns {JSX.Element} Input number field
 * @component
 */
const InputNumber = ({ name, placeholder, ...props }) => {
  return <AntInputNumber name={name} placeholder={placeholder} {...props} />;
};

export default InputNumber;
