import { gql } from '@apollo/client';

const getActionsOfClientQuery = gql`
  query getActionsOfClientQuery($clientId: ID!) {
    getActionsOfClient(clientId: $clientId) {
      _id
      actionType
      createdAt
      parentId
      variables
    }
  }
`;

export default getActionsOfClientQuery;
