import classes from './IconWithText.module.less';

/**
 * Renders normal text with an icon on the left side with icon aligned to the center of the text
 * @param {object} inputProperties - Input properties of the component
 * @param {JSX.Element} inputProperties.icon - Icon element
 * @param {string} inputProperties.text - Text to display
 * @returns {JSX.Element} Icon with text
 * @component
 */
const IconWithText = ({ icon, text }) => {
  return (
    <div className={classes.Container}>
      {icon}
      <span className={classes.Text}>{text}</span>
    </div>
  );
};

export default IconWithText;
