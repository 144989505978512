import FormItem from './FormItem';
import Input from './Input';

/**
 * Input as form item for formik forms (with label, tooltip, ...)
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.name - Name of the field in the form data
 * @param {string} inputProperties.label - Label of the input
 * @param {string} inputProperties.tooltip - Tooltip of the input
 * @param {string} inputProperties.placeholder - Placeholder text for the input
 * @param {boolean} inputProperties.labelUnderlined - Whether the label should be underlined
 * @param {object} inputProperties.inputProps - Additional input properties (See antd design doc for input component for more information)
 * @param {object} inputProperties.formItemProps - Additional form item properties (See antd design doc for form item component for more information)
 * @returns {JSX.Element} Input with label component
 * @component
 */
const InputWithLabel = ({ name, label, tooltip, placeholder, labelUnderlined, inputProps, formItemProps }) => {
  return (
    <FormItem name={name} label={label} tooltip={tooltip} labelUnderlined={labelUnderlined} {...formItemProps}>
      <Input name={name} placeholder={placeholder} {...inputProps} />
    </FormItem>
  );
};

export default InputWithLabel;
