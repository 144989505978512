import FormItem from './FormItem';
import InputNumber from './InputNumber';

/**
 * Number input field as form item with label, tooltip, ... with formik support
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.name - Name of the input field
 * @param {string} inputProperties.label - Label of the input field
 * @param {string} inputProperties.tooltip - Tooltip of the input field
 * @param {string} inputProperties.placeholder - Placeholder of the input field
 * @param {object} inputProperties.inputProps - Additional input properties See ant design documentation for more information
 * @param {object} inputProperties.formItemProps - Additional form item properties See ant design documentation for more information
 * @returns {JSX.Element} Input number field with label
 * @component
 */
const InputNumberWithLabel = ({ name, label, tooltip, placeholder, inputProps, formItemProps }) => {
  return (
    <FormItem name={name} label={label} tooltip={tooltip} {...formItemProps}>
      <InputNumber name={name} placeholder={placeholder} {...inputProps} />
    </FormItem>
  );
};

export default InputNumberWithLabel;
