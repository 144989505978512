import CountrySelect from './CountrySelect';
import FormItem from './FormItem';

/**
 * Country select input field as form item with label with formik support
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.name - Name of the input field
 * @param {string} inputProperties.placeholder - Placeholder of the input field
 * @param {string} inputProperties.tooltip - Tooltip of the input field
 * @param {string} inputProperties.label - Label of the input field
 * @param {object} inputProperties.formItemProps - Additional form item properties See ant design documentation for more information
 * @param {object} inputProperties.inputProps - Additional input properties See ant design documentation for more information
 * @returns {JSX.Element} Country select input field with label
 * @component
 */
const CountrySelectWithLabel = ({ name, label, tooltip, placeholder, inputProps, formItemProps }) => {
  return (
    <FormItem label={label} tooltip={tooltip} name={name} {...formItemProps}>
      <CountrySelect name={name} placeholder={placeholder} {...inputProps} />
    </FormItem>
  );
};

export default CountrySelectWithLabel;
