import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';

/**
 * Field for adding additional address information to the client form
 * @returns {JSX.Element} A field to enter the client address addition
 */
const ClientAddressAdditionField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="address.addition"
      label={t('ClientsListPage.addClientModal.form.address.addition.label')}
      placeholder={t('ClientsListPage.addClientModal.form.address.addition.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ClientAddressAdditionField;
