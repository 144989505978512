import { message } from 'antd';
import generateMessageConfig from 'components/common/CloseableMessage';
import apollo from 'graphql/apollo';
import { createCompanyMutation } from 'graphql/mutations';
import i18n from 'i18n';

/**
 * Adds a new client to the tenant instance remotely
 * @param {object} clientValues - Data of the new client
 * @param {string} clientValues.name - Name of the client
 * @param {string} clientValues.identifier - Identifier of the client
 * @param {string} clientValues.type - Type of the client
 * @param {object} clientValues.address - Address of the client
 * @param {object} clientValues.address.country - Country of the client
 * @param {object} clientValues.address.city - City of the client
 * @param {object} clientValues.address.cityCode - City code of the client
 * @param {object} clientValues.address.street - Street of the client
 * @param {object} clientValues.address.houseNumber - House number of the client
 * @param {object} clientValues.importFields - Import fields of the client
 * @param {object} clientValues.importFields.commercialObject - Commercial object of the client (for companies)
 * @returns {Promise<void>} A promise that resolves when the client is added or rejects with an error
 */
const addNewClient = async ({ name, identifier, type, address, importFields }) => {
  return apollo
    .mutate({
      mutation: createCompanyMutation,
      variables: {
        company: {
          name,
          identifier,
          type,
          address,
          commercialObject: importFields.commercialObject,
        },
      },
      refetchQueries: ['clientEntries'],
    })
    .then(() => {
      message.success(
        generateMessageConfig(i18n.t('ClientsListPage.addClientModal.successMessage'), 'addClientSuccess', 5),
      );
    });
};

export default addNewClient;
