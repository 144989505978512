/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { createCategoryItem, createDiscount, createInputField, updateCategoryItem } from 'graphql/methods';
import { addCategory, addCommonCategory } from 'graphql/methods/admin/category/createCategory';
import { ObjectId } from 'utils/helpers';

// const findButtonWithText = (text) => [...document.querySelectorAll('button')].find((b) => b.innerHTML.includes(text));
// eslint-disable-next-line consistent-return
const tc = async (fn) => {
  try {
    return await fn();
  } catch (e) {
    console.log(e);
  }
};
const getId = async (obj) => Object.values(Object.values(await obj)?.[0] || {})?.[0]?._id;

const createTestCategories = async ({ isCommonLibrary = false } = {}) => {
  // findButtonWithText('Add category').click()
  const itemDefaults = {
    benefits: '',
    pleaseNote: '',
    notesToEmployee: '',
    notesForFeeAgreement: '',
    infoText: null,
    minPrice: 0,
    maxPrice: 0,
    name: 'a',
    paymentInterval: 'oneOff',
    pricingFormula: '100',
    scalePricingFormulaTitle: '',
    scaleTitle: '',
    scales: [],
    scalesEnabled: false,
  };

  const inpOptions = () => [
    { _id: ObjectId(), name: '0', value: 0, order: 1 },
    { _id: ObjectId(), name: '1', value: 1, order: 2 },
    { _id: ObjectId(), name: '2', value: 2, order: 3 },
    { _id: ObjectId(), name: '3', value: 3, order: 4 },
  ];
  const createIF = ({ name, parentId, parentType = 'category', categoryId = parentId, isSelect }) =>
    tc(async () =>
      getId(
        createInputField({
          name,
          type: isSelect ? 'combo' : 'input',
          options: isSelect ? inpOptions() : undefined,
          parentId,
          parentType,
          categoryId,
        }),
      ),
    );

  const createInputFields = async (parentName, { parentId, parentType, categoryId }) => {
    const inp1i = await createIF({ name: `${parentName}.inp1i`, parentId, parentType, categoryId });
    const inp2i = await createIF({ name: `${parentName}.inp2i`, parentId, parentType, categoryId });
    const inp3c = await createIF({ name: `${parentName}.inp3c`, parentId, parentType, categoryId, isSelect: true });
    return [inp1i, inp2i, inp3c];
  };

  /// //////////////////////////////////
  const categoryIds = [];
  const categoryNames = isCommonLibrary ? ['cl1', 'cl2', 'cl3'] : ['c1', 'c2', 'c3'];
  for (const categoryName of categoryNames) {
    const c1 = await tc(async () => getId((isCommonLibrary ? addCommonCategory : addCategory)({ name: categoryName })));
    categoryIds.push(c1);
    const c1_inpIds = await createInputFields(categoryName, { parentId: c1, parentType: 'category', categoryId: c1 });

    await getId(createDiscount({ categoryId: c1, name: `${categoryName}.dp10`, value: 10, type: 'percent' }));
    await getId(createDiscount({ categoryId: c1, name: `${categoryName}.da1`, value: 1, type: 'absolute' }));

    const item = (parentName, { name, ...props }) => {
      return getId(
        createCategoryItem({
          categoryId: c1,
          modifier: { ...itemDefaults, name: `${parentName}.${name}`, ...props },
        }),
      );
    };
    const monthlyItem = (parentName, props) => item(parentName, { ...props, paymentInterval: 'monthly' });
    const yearlyItem = (parentName, props) => item(parentName, { ...props, paymentInterval: 'yearly' });
    const oneOffItem = (parentName, props) => item(parentName, { ...props, paymentInterval: 'oneOff' });

    const promises = [];
    [oneOffItem, monthlyItem, yearlyItem].forEach((fn, fnIndex) => {
      new Array(3)
        .fill(null)
        .forEach((_, index) => promises.push(fn(categoryName, { name: `it${fnIndex * 3 + index + 1}` })));
    });
    if (categoryIds.length > 1)
      oneOffItem(categoryName, {
        name: `itReferenceOf${categoryNames[categoryIds.length - 2]}`,
        pricingFormula: `100+@{{${categoryIds[categoryIds.length - 2]}}}`,
      });
    const inputIds = await Promise.all(promises);
    const inputFieldIds = await Promise.all(
      inputIds.map((id, index) =>
        createInputFields(`${categoryName}.it${index}`, {
          parentId: id,
          parentType: 'categoryItem',
          categoryId: c1,
        }),
      ),
    );
    await Promise.all(
      inputIds.map((id, index) =>
        updateCategoryItem({
          _id: id,
          pricingFormula: `100+@{{${c1_inpIds[index % 3]}}}+@{{${inputFieldIds[index][index % 3]}}}`,
        }),
      ),
    );
    // updateCategoryItem({ _id: c1_it2, pricingFormula: `100+@{{${c1_inpIds[1]}}}+@{{${c1_it2_inpIds[1]}}}` });
    // updateCategoryItem({ _id: c1_it3, pricingFormula: `100+@{{${c1_inpIds[2]}}}+@{{${c1_it3_inpIds[2]}}}` });
    console.log('please reload the page');
  }
};

console.log(
  'use window.createTestCategories() or createTestCategoriesCommonLibrary() to generate sample categories with data (previously remove categories c1 c2 c3)',
);

window.createTestCategories = createTestCategories;
window.createTestCategoriesCommonLibrary = () => createTestCategories({ isCommonLibrary: true });
