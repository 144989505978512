import FormItem from './FormItem';
import RadioButtons from './RadioButtons';

/**
 * Radio buttons as form item for formik forms (with label, tooltip, ...)
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.label - Label of the input
 * @param {string} inputProperties.tooltip - Tooltip of the input
 * @param {string} inputProperties.name - Name of the field in the form data
 * @param {array} inputProperties.options - Options for the radio buttons
 * @param {string} inputProperties.options.label - Label of the radio button
 * @param {string} inputProperties.options.value - Value of the radio button
 * @param {boolean} inputProperties.labelUnderlined - Whether the label should be underlined
 * @param {object} inputProperties.groupProps - Additional group properties (See antd design doc for radio component for more information)
 * @param {object} inputProperties.buttonProps - Additional button properties (See antd design doc for radio component for more information)
 * @param {object} inputProperties.formItemProps - Additional form item properties (See antd design doc for form item component for more information)
 * @returns {JSX.Element} Input with label component
 * @component
 */
const RadioButtonsWithLabel = ({
  label,
  tooltip,
  name,
  options,
  labelUnderlined,
  groupProps,
  buttonProps,
  formItemProps,
}) => {
  return (
    <FormItem name={name} label={label} tooltip={tooltip} labelUnderlined={labelUnderlined} {...formItemProps}>
      <RadioButtons name={name} options={options} groupProps={groupProps} buttonProps={buttonProps} />
    </FormItem>
  );
};

export default RadioButtonsWithLabel;
