import { Input } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

/**
 * Renders an input form item for entering the account owner's name.
 *
 * This component creates a labeled input field specifically for the account owner's name.
 * The label and placeholder for the input field are localized using the provided translation function.
 * The input field can be set to disabled state.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.t - Translation function for localizing the label and placeholder.
 * @param {boolean} [props.disabled=false] - Indicates whether the input field should be disabled.
 * @returns {ReactElement} A form item containing a labeled input field for the account owner's name.
 */
const AccountOwnerInput = ({ t, disabled, prefilledOwner }) => {
  return (
    <FormItem name="accountOwnerName" label={t('accountOwnerName.label')}>
      <Input
        disabled={disabled}
        autoFocus
        name="accountOwnerName"
        placeholder={prefilledOwner || t('accountOwnerName.placeholder')}
      />
    </FormItem>
  );
};

export default AccountOwnerInput;
