import { Form as AntForm } from 'antd';

/**
 * Container for form items
 * @param {object} inputProperties - Input properties of the component
 * @param {React.ReactNode} inputProperties.children - Children of the component
 * @param {string} inputProperties.layout - Layout of the form (Default: 'vertical')
 * @returns {JSX.Element} Form component
 */
const Form = ({ children, layout = 'vertical', ...props }) => {
  return (
    <AntForm layout={layout} {...props}>
      {children}
    </AntForm>
  );
};

export default Form;
