import { Button, Image, Modal } from 'antd';
import { memo, useCallback } from 'react';
import ReactPlayer from 'react-player';
import cn from 'classnames';
import { CalendarOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import classes from './MoreInfoWidget.module.less';

/**
 * Renders the content for the MoreInfoWidget modal, including video, image, help text, and a booking button for training sessions.
 *
 * @param {Object} inputProperties - The input properties for the MoreInfoWidgetContent component.
 * @param {string} [inputProperties.videoUrl] - URL of the video to be displayed.
 * @param {string|Object} [inputProperties.helpText] - Text or translation key and values for additional information.
 * @param {string} [inputProperties.videoCaption] - Caption for the video.
 * @param {string} [inputProperties.imageUrl] - URL of the image to be displayed.
 * @param {React.ReactNode} [inputProperties.children] - Additional content to be rendered.
 * @param {Object} [inputProperties.playerProps] - Additional props for the ReactPlayer component.
 * @param {boolean} [inputProperties.hideBook] - Whether to hide the booking button.
 * @param {string} [inputProperties.className] - Additional CSS class for the component.
 * @returns {JSX.Element} The rendered MoreInfoWidgetContent component.
 */
export function MoreInfoWidgetContent({
  videoUrl,
  helpText,
  videoCaption,
  imageUrl,
  children,
  playerProps = {},
  hideBook,
  className,
}) {
  const { t } = useTranslation();

  const getHelpText = () => {
    if (typeof helpText === 'string') {
      return helpText;
    }

    return (
      <Trans
        i18nKey={helpText.key}
        values={{ ...helpText.values }}
        components={{
          italic: <i />,
          bold: <strong />,
          underline: <u />,
          nl: <br />,
        }}
      />
    );
  };

  return (
    <div className={cn(classes.modalHelper, className)}>
      {videoUrl ? (
        <div className={classes.videoWrapper}>
          <ReactPlayer controls url={videoUrl} {...playerProps} />
        </div>
      ) : null}
      {videoCaption ? <p>{videoCaption}</p> : null}
      {imageUrl && (
        <div className={classes.imageContainer}>
          <Image
            wrapperClassName={classes.imageWrapper}
            src={imageUrl}
            preview={
              imageUrl
                ? {
                    zIndex: 10001,
                  }
                : false
            }
          />
        </div>
      )}
      {helpText ? <p>{getHelpText()}</p> : null}

      {children}
      {hideBook ? null : (
        <div className="book-calendly-in-more-info">
          <h4>{t('common.MoreInfoWidget.book.title')}</h4>
          <a href={t('common.MoreInfoWidget.book.calendlyUrl')} target="_blank" rel="noreferrer">
            <Button type="primary" icon={<CalendarOutlined />}>
              {t('common.MoreInfoWidget.book.calendly')}
            </Button>
          </a>
        </div>
      )}
    </div>
  );
}

/**
 * A button component that opens a modal with additional information, including video, image, and help text.
 *
 * @param {Object} inputProperties - The input properties for the MoreInfoWidget component.
 * @param {string} [inputProperties.buttonClassName] - Additional CSS class for the button.
 * @param {string} inputProperties.buttonText - Text to be displayed on the button.
 * @param {string} inputProperties.title - Title of the modal.
 * @param {string|Object} [inputProperties.helpText] - Text or translation key and values for additional information.
 * @param {string} [inputProperties.videoCaption] - Caption for the video.
 * @param {string} [inputProperties.videoUrl] - URL of the video to be displayed in the modal.
 * @param {string} [inputProperties.imageUrl] - URL of the image to be displayed in the modal.
 * @param {React.ReactNode} [inputProperties.children] - Additional content to be rendered in the modal.
 * @returns {JSX.Element} The rendered MoreInfoWidget component.
 */
function MoreInfoWidget({ buttonClassName, buttonText, title, helpText, videoCaption, videoUrl, imageUrl, children }) {
  const [modal, contextHolder] = Modal.useModal();
  const onOpenInfoModal = useCallback(() => {
    modal.info({
      maskClosable: true,
      title,
      content: (
        <MoreInfoWidgetContent
          videoUrl={videoUrl}
          helpText={helpText}
          videoCaption={videoCaption}
          imageUrl={imageUrl}
          // eslint-disable-next-line react/no-children-prop
          children={children}
        />
      ),
      width: 800,
    });
  }, [modal, title, videoUrl, helpText, videoCaption, imageUrl, children]);
  let isVideoUrl = true;
  try {
    // eslint-disable-next-line no-new
    new URL(videoUrl);
  } catch {
    isVideoUrl = false;
  }
  return (
    <>
      {contextHolder}
      <Button
        type="link"
        className={cn(classes.button, buttonClassName)}
        icon={isVideoUrl && <PlayCircleOutlined />}
        onClick={onOpenInfoModal}
      >
        {buttonText}
      </Button>
    </>
  );
}

export default memo(MoreInfoWidget);
