import { useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import Card from 'components/common/Card';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import Switch from 'components/common/Switch';
import SpinForCard from 'components/common/SpinForCard';
import { userDigitalSignatureEnabledGlobalStateQuery } from 'graphql/queries';
import { superAdminSwitchDigitalSignatureEnabledGloballyMutation } from 'graphql/mutations';
import classes from './DigitalSignatureKillSwitchCard.module.less';

/**
 * Digital signature kill switch card component to enable/disable digital signature globally for ManageDigitalSignature page
 * @returns {JSX.Element} DigitalSignatureKillSwitchCard component to enable/disable digital signature globally
 * @component
 */
const DigitalSignatureKillSwitchCard = () => {
  const { t } = useTranslation();
  const { loading, data: digitalSignatureEnabledGlobally } = useQuery(userDigitalSignatureEnabledGlobalStateQuery);
  const [switchDigitalSignatureEnabledGlobally] = useMutation(superAdminSwitchDigitalSignatureEnabledGloballyMutation, {
    refetchQueries: [userDigitalSignatureEnabledGlobalStateQuery],
  });
  const formikRef = useRef();

  const initValues = {
    enableDigitalSignature: digitalSignatureEnabledGlobally?.getGlobalDigitalSignatureEnabledState !== false,
  };

  const changeDigitalSignatureGloballyEnabledState = async (values) => {
    await switchDigitalSignatureEnabledGlobally({
      variables: { newDigitalSignatureEnabled: values.enableDigitalSignature },
    });
  };

  return (
    <Card title={t('superAdmin.ManageDigitalSignature.digitalSignatureKillSwitch.title')}>
      {loading && !digitalSignatureEnabledGlobally ? <SpinForCard /> : null}
      {digitalSignatureEnabledGlobally ? (
        <I18nFormik ref={formikRef} initialValues={initValues} onSubmit={changeDigitalSignatureGloballyEnabledState}>
          {formikRef?.current?.values?.enableDigitalSignature ? (
            <Alert
              type="warning"
              message={t('superAdmin.ManageDigitalSignature.digitalSignatureKillSwitch.warningSwitchOff')}
            />
          ) : (
            <Alert
              type="warning"
              message={t('superAdmin.ManageDigitalSignature.digitalSignatureKillSwitch.warningSwitchOn')}
            />
          )}
          <Switch
            label={t('superAdmin.ManageDigitalSignature.digitalSignatureKillSwitch.switchLabel')}
            name="enableDigitalSignature"
            className={classes.enableDigitalSignatureSwitch}
          />
          <SaveChangesButton initialValues={initValues} />
        </I18nFormik>
      ) : null}
    </Card>
  );
};

export default DigitalSignatureKillSwitchCard;
