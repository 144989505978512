import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { useFormikContext } from 'formik';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { Form, Input } from 'formik-antd';
import { updateTheme } from 'graphql/methods';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import { ColorPickerFormikInput } from './components/Inputs';
import classes from './CorporateIdentitySetting.module.less';

const coloursForTableColumns = ({ t }) => [
  {
    key: 'name',
    dataIndex: 'name',
    render: (name) => t(`admin.Setting.CorporateIdentity.TableTheme.inputs.${name}`),
    width: 170,
  },
  {
    title: t('admin.Setting.CorporateIdentity.TableTheme.field.background'),
    key: 'font',
    render: ({ name }) => {
      return <ColorPickerFormikInput className={classes.picker} name={`${name}.background`} />;
    },
    width: 215,
  },
  {
    title: t('admin.Setting.CorporateIdentity.TableTheme.field.color'),
    key: 'colour',
    render: ({ name }) => {
      return <ColorPickerFormikInput className={classes.picker} name={`${name}.color`} />;
    },
    width: 215,
  },
];

const ColoursForTable = ({ styles }) => {
  const { t } = useTranslation();
  return (
    <TableMemo
      className={classes.tableCorporatePrimaryColour}
      bordered={false}
      dataSource={styles}
      pagination={false}
      columns={coloursForTableColumns({ t })}
      scroll={{
        x: 600,
      }}
    />
  );
};
const ColoursForTableMemo = memo(ColoursForTable, equal);

const ColoursForTableWrapper = () => {
  const { values } = useFormikContext();
  const styles = useMemo(() => {
    return [
      { ...values.tableHeaderPrimary, name: 'tableHeaderPrimary' },
      { ...values.tableHeaderSecondary, name: 'tableHeaderSecondary' },
      { ...values.tableContent, name: 'tableContent' },
      { ...values.tableFooter, name: 'tableFooter' },
      { ...values.tableLeftColumn, name: 'tableLeftColumn' },
    ];
  }, [
    values.tableContent,
    values.tableFooter,
    values.tableHeaderPrimary,
    values.tableHeaderSecondary,
    values.tableLeftColumn,
  ]);
  return <ColoursForTableMemo styles={styles} />;
};

const ColoursForTableWrapperMemo = memo(ColoursForTableWrapper, equal);

const StylesForTable = () => {
  const name = 'table';
  const { t } = useTranslation();

  return (
    <div className={classes.table}>
      <h4>{t(`admin.Setting.CorporateIdentity.TableTheme.inputs.table`)}</h4>
      <Form layout="vertical" className={classes.tableFields}>
        <FormItem name={`${name}.fontSize`} label={t('admin.Setting.CorporateIdentity.TableTheme.field.fontSize')}>
          <Input name={`${name}.fontSize`} />
        </FormItem>
        <FormItem name={`${name}.marginTop`} label={t('admin.Setting.CorporateIdentity.TableTheme.field.marginTop')}>
          <Input name={`${name}.marginTop`} />
        </FormItem>
        <FormItem
          name={`${name}.marginBottom`}
          label={t('admin.Setting.CorporateIdentity.TableTheme.field.marginBottom')}
        >
          <Input name={`${name}.marginBottom`} />
        </FormItem>
      </Form>
    </div>
  );
};

const StylesForTableMemo = memo(StylesForTable, equal);

const setDefaultStyles = (field = {}) => ({
  background: field?.background || '#ffffff',
  color: field?.color || '#000000',
});

function TableTheme({ table, tableHeaderPrimary, tableHeaderSecondary, tableContent, tableFooter, tableLeftColumn }) {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      tableHeaderPrimary: setDefaultStyles(tableHeaderPrimary),
      tableHeaderSecondary: setDefaultStyles(tableHeaderSecondary),
      tableContent: setDefaultStyles(tableContent),
      tableFooter: setDefaultStyles(tableFooter),
      tableLeftColumn: setDefaultStyles(tableLeftColumn),
      table: {
        fontSize: table?.fontSize || '11pt',
        marginTop: table?.marginTop || '11pt',
        marginBottom: table?.marginBottom || '30pt',
      },
    }),
    [table, tableContent, tableFooter, tableHeaderPrimary, tableHeaderSecondary, tableLeftColumn],
  );
  return (
    <Card title={t('admin.Setting.CorporateIdentity.TableTheme.title')}>
      <I18nFormik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(modifier) => {
          updateTheme({ pdf: modifier });
        }}
      >
        <div>
          <ColoursForTableWrapperMemo />
          <StylesForTableMemo />
          <SaveChangesButton initialValues={initialValues} />
        </div>
      </I18nFormik>
    </Card>
  );
}

export default memo(TableTheme, equal);
