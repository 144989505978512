import { useMemo } from 'react';
import ConfigurationSwitch from 'components/admin/DocumentTemplate/Block/Configuration/ConfigurationSwitch';
import ConfigurationImage from 'components/admin/DocumentTemplate/Block/Configuration/ConfigurationImage';
import ConfigurationTabs from 'components/admin/DocumentTemplate/Block/Configuration/ConfigurationTabs';
import { Input } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import i18n from 'i18n';
import { RichEditorField } from 'components/common/RichEditor';
import { useFormikContext } from 'formik';
import { PLACEHOLDER_USE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { PersonAllowedToSignFormikMultiSelect } from './Inputs';
// eslint-disable-next-line import/no-cycle
import ConfigurationSubtitleWithFields from './ConfigurationSubtitleWithFields';
import ConfigurationHeading from './ConfigurationHeading';
import ConfigurationParagraph from './ConfigurationParagraph';
import { ConfigurationShowHideItems } from './ConfigurationShowHideItems';

const validateShowIf = (showIf, formValues) => {
  if (!showIf) return true;
  if (!formValues) return true;
  return (
    Object.entries(showIf).filter(([key, operations]) => {
      if (key in formValues) {
        if ('equal' in operations && formValues[key] === operations.equal) return false;
      }
      return true;
    }).length === 0
  );
};

/**
 * ConfigurationFieldRenderer component renders property field for a document template block
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Function} inputParameters.setPreviewBlockDescriptor - Function, which is called for properties tabs
 * @param {Object} inputParameters.fieldDescriptor - Field descriptor for the block property
 * @returns {JSX.Element} form item for document template block properties
 * @component
 */
const ConfigurationFieldRenderer = ({ setPreviewBlockDescriptor, fieldDescriptor }) => {
  const { values } = useFormikContext();
  const label = fieldDescriptor.displayName || fieldDescriptor.name;

  const buildTabs = ({ subtitle, fields }) => {
    return <ConfigurationSubtitleWithFields subtitle={subtitle} fields={fields} />;
  };

  const showField = useMemo(() => validateShowIf(fieldDescriptor.showIf, values), [values, fieldDescriptor]);

  if (!showField) return null;

  if (fieldDescriptor.dontShow) return null;

  if (fieldDescriptor.type === 'multi-line-text') {
    return <RichEditorField {...fieldDescriptor} label={label} allowedUses={[PLACEHOLDER_USE.DOCUMENTS]} />;
  }
  if (fieldDescriptor.type === 'transfer-show-hide-items') {
    return (
      <ConfigurationShowHideItems name={fieldDescriptor.name} showStaticItems={values.showStaticItemsOfCategories} />
    );
  }
  if (fieldDescriptor.type === 'signatures-select') {
    return (
      <FormItem name={fieldDescriptor.name} label={label}>
        <PersonAllowedToSignFormikMultiSelect name={fieldDescriptor.name} />
      </FormItem>
    );
  }
  if (fieldDescriptor.type === 'boolean') {
    return <ConfigurationSwitch fieldDescriptor={fieldDescriptor} label={label} i18n={i18n} />;
  }
  if (fieldDescriptor.type === 'photo') {
    return <ConfigurationImage fieldDescriptor={fieldDescriptor} label={label} />;
  }
  if (fieldDescriptor.type === 'text') {
    return (
      <FormItem name={fieldDescriptor.name} label={label} tooltip={fieldDescriptor.tooltip}>
        <Input name={fieldDescriptor.name} />
      </FormItem>
    );
  }
  if (fieldDescriptor.type === 'heading') {
    return <ConfigurationHeading fieldDescriptor={fieldDescriptor} />;
  }
  if (fieldDescriptor.type === 'paragraph') {
    return <ConfigurationParagraph fieldDescriptor={fieldDescriptor} />;
  }
  if (fieldDescriptor.type === 'tab') {
    return (
      <ConfigurationTabs
        setPreviewBlockDescriptor={setPreviewBlockDescriptor}
        fieldDescriptor={fieldDescriptor}
        buildTabs={buildTabs}
      />
    );
  }
  return null;
};

export default ConfigurationFieldRenderer;
