import { Select as FormikSelect } from 'formik-antd';

/**
 * Select component for forms with formik support
 * @param {object} inputProperties - Input properties of the component (For more properties, see: https://4x.ant.design/components/select/)
 * @param {string} inputProperties.name - Name of the input
 * @param {string} inputProperties.placeholder - Placeholder of the input
 * @param {React.ReactNode} inputProperties.children - Children of the component (Options)
 * @returns {JSX.Element} Select component
 * @component
 */
const Select = ({ name, placeholder, children, ...props }) => {
  return (
    <FormikSelect name={name} placeholder={placeholder} {...props}>
      {children}
    </FormikSelect>
  );
};

Select.Option = FormikSelect.Option;

export default Select;
