import React from 'react';
import defaults from 'lodash/defaults';
import { useTranslation } from 'react-i18next';
// import { Row, Col, Typography, Divider } from 'antd';
import { Form } from 'formik-antd';
import pick from 'lodash/pick';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import {
  CompanySettingsFormFields,
  updateCompanySettingSchema,
  defaultValues,
} from 'pages/admin/CompanySetting/CompanySetting';
import OnboardingStepTemplate from '../components/OnboardingStepTemplate';
import { useOnboardingContext } from '../OnboardingContext';

export { defaultValues };

export default function CompanyInfo() {
  const { t } = useTranslation();
  const { onSubmit, formValuesRef } = useOnboardingContext();

  const initialValues = React.useMemo(
    () => defaults({}, pick(formValuesRef.current, Object.keys(defaultValues)), defaultValues),
    [formValuesRef],
  );
  return (
    <I18nFormik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await onSubmit(values);
      }}
      validationSchema={(props) => updateCompanySettingSchema({ ...props, isOnboarding: true })}
    >
      {({ values, isSubmitting }) => (
        <Form layout="vertical">
          <OnboardingStepTemplate
            title={t('onboarding.companyInfo.title')}
            description={t('onboarding.companyInfo.textAboveVideo')}
            isSubmitting={isSubmitting}
            onPrev={() => {
              formValuesRef.current = { ...formValuesRef.current, ...values };
            }}
          >
            <CompanySettingsFormFields />
          </OnboardingStepTemplate>
        </Form>
      )}
    </I18nFormik>
  );
}
