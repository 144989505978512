import { useState } from 'react';
import { Button, Typography } from 'antd';
import { MailOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import { FaFile } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import htmr from 'htmr';
import { DocumentTable } from 'components/common/DocumentsCard/DocumentTable';
import Card from 'components/common/Card';
import concatClassNames from 'utils/classNames';
import classes from './SendedEmailCard.module.less';

const { Title } = Typography;

/**
 * Recipients component to show recipients of an email
 * @param {Object} inputParameters - Input parameters of component
 * @returns {JSX.Element} recipients of a sended email
 * @component
 */
export const Recipients = ({ recipients }) => {
  const { t } = useTranslation();
  if (!recipients?.length) return null;
  return (
    <div>
      {t('user.ShoppingCart.SendEmail.fields.recipients.label')}: {recipients.map((r) => r.email).join(', ')}
    </div>
  );
};
/**
 * BCC component to show bcc of an email
 * @param {Object} inputParameters - Input parameters of component
 * @returns {JSX.Element} bcc of a sended email
 * @component
 */
export const BCC = ({ bcc }) => {
  const { t } = useTranslation();
  if (!bcc) return null;
  return (
    <div>
      {t('user.ShoppingCart.SendEmail.bcc')}: {bcc.toString().replace(',', ', ')}
    </div>
  );
};

/**
 * SendedEmailCardSubtitle component which shows the email recipients and the bcc for the sended email card
 * @param {Object} inputParameters - Input parameters of component
 * @param {String[]} inputParameters.recipients - Collection of recipients of the email
 * @param {String} inputParameters.bcc - BCC of the email
 * @returns {JSX.Element} subtitle for the sended email card to show recipients and additional bcc
 * @component
 */
const SendedEmailCardSubtitle = ({ recipients, bcc }) => {
  return (
    <div className={classes.Subtitle}>
      <Recipients recipients={recipients} />
      <BCC bcc={bcc} />
    </div>
  );
};

/**
 * SendedEmailCardContent component for the sended email card to show the email message and a second section for the attachments
 * @param {Object} inputParameters - Input parameters of component
 * @param {String} inputParameters.body - Body of the email message
 * @param {String} inputParameters.signature - Signature of the email message
 * @param {String} inputParameters.shoppingCartId - Id of shopping cart for which the email was sent
 * @returns {JSX.Element} card content for the sended email card to show the content of the email
 * @component
 */
const SendedEmailCardContent = ({ body, signature, shoppingCartId, shouldSendAttachments, attachments }) => {
  const { t } = useTranslation();

  const filterDocuments = (documents) => {
    if (!documents) return documents;
    if (!attachments) return documents;
    const filteredDocuments = [];
    attachments.forEach((pdf) => {
      const document = documents.find((d) => d._id === pdf._id);
      if (!document) return;
      filteredDocuments.push({ ...document, ...pdf, isSigned: pdf.isSigned });
    });
    return filteredDocuments;
  };

  return (
    <>
      <div className={classes.Message}>
        {body.split('<br/>').map((el) => (
          <div>{htmr(el || '&#xFEFF;')}</div>
        ))}
        {signature ? signature.split('<br/>').map((el) => <div>{htmr(el || '&#xFEFF;')}</div>) : null}
      </div>
      {shouldSendAttachments && (
        <div className={classes.AttachmentsWrapper}>
          <div className={classes.Attachments}>
            <Title className={classes.sectionTitle}>Anhänge</Title>
            <DocumentTable
              filterDocuments={filterDocuments}
              shoppingCartId={shoppingCartId}
              icon={<FaFile />}
              title={t('user.ShoppingCart.SendEmail.attachments')}
            />
          </div>
        </div>
      )}
    </>
  );
};

/**
 * SendedEmailCard component shows a collapsable card with the informations of a sended email
 * @param {Object} inputParameters - Input parameters of component
 * @param {Object} inputParameters.emailData - Data of the sended email
 * @param {String} inputParameters.emailData.body - Body of the email message
 * @param {String} inputParameters.emailData.signature - Signature of the email message
 * @param {String} inputParameters.emailData.subject - Subject of the email
 * @param {String} inputParameters.emailData.bcc - BCC of the email
 * @param {String[]} inputParameters.emailData.recipients - Recipients of the email
 * @param {Object[]} inputParameters.emailData.attachments - Collection of attachments sent with the email
 * @param {String} inputParameters.shoppingCartId - Id of shopping cart for which the email was sent
 * @param {String} inputParameters.className - Additional classname for the card
 * @param {Boolean} inputParameters.initOpen - Should the card be open by default
 * @returns {JSX.Element} card with informations about the specified sended email
 * @component
 */
const SendedEmailCard = ({ emailData, className, shoppingCartId, initOpen = false }) => {
  const [showCardOpen, setShowCardOpen] = useState(initOpen);
  const { attachments, body, subject, signature, bcc, recipients, shouldSendAttachments } = emailData;
  return (
    <Card
      className={concatClassNames(classes.SendedEmailCard, className)}
      icon={<MailOutlined />}
      title={subject}
      subtitle={<SendedEmailCardSubtitle recipients={recipients} bcc={bcc} />}
      extra={
        <Button
          icon={showCardOpen ? <DownOutlined /> : <RightOutlined />}
          type="text"
          onClick={() => setShowCardOpen((currentOpen) => !currentOpen)}
        />
      }
    >
      {showCardOpen ? (
        <SendedEmailCardContent
          shouldSendAttachments={shouldSendAttachments}
          body={body}
          signature={signature}
          shoppingCartId={shoppingCartId}
          attachments={attachments}
        />
      ) : null}
    </Card>
  );
};

export default SendedEmailCard;
