import { useCallback, useEffect, useMemo, useState } from 'react';
import useParametersFromSearchParams from './useParametersFromSearchParams';
import { getPageFromSkipAndLimit, getSkipFromPageAndLimit, modifySearchParameters } from './utils';
import useSortFilterQuery from './useSortFilterQuery';
import usePaginatedSelectable from './usePaginatedSelectable';

/**
 * Handler for list layout with sorting, filtering, searching, selecting and drag and drop for PAGINATED lists
 * Handles fetching data, state for sorting, filtering, searching, selecting and drag and drop and state for pagination
 * The children functions gets following properties passed:
 *  sortedAndFilteredData: object[] - Data with applied sorting and filtering
 *  error: Error - Error object
 *  loading: boolean - Loading state
 *  sortState: string[] - Current sort settings [fieldName, direction]
 *  setSort: (fieldName: string, direction: string) => void - Function to set sort settings
 *  filterState: string[][] - Current filter settings [[fieldName, value], ...]
 *  setFilter: (fieldName: string, value: string) => void - Function to set filter settings
 *  queryState: string - Current search query
 *  setQuery: (newQuery: string) => void - Function to set search query
 *  selectAll: () => void - Function to select all items
 *  selectOne: (item: object, select: boolean = true) => void - Function to select one item
 *  selected: object[] - Selected items
 *  selectAllActive: boolean - Whether all items are selected
 *  resetSortAndFilter: () => void - Function to reset sort and filter settings for drag and drop
 *  dragEnabled: boolean - Whether drag and drop conditions are given (always false for paginated lists)
 *  total: number - Total count of the items
 *  currentPage: number - Current page of the pagination
 *  currentPageSize: number - Current page size of the pagination
 *  changePage: (page: number, pageSize: number) => void - Function to change the page and or page size
 * @param {object} inputProperties - Input properties of the component
 * @param {(query: string, pageSize: number, skip: number, sort: string[], filter: string[][]) => object} inputProperties.useDataFetching - Hook to fetch data, which retunrs an object with data, error and loading
 * @param {string[]} inputProperties.initSort - Initial sort settings [fieldName, direction]
 * @param {boolean} inputProperties.shouldUseSearchQueryParams - Whether to use search query params
 * @param {React.ReactNode} inputProperties.children - Children to render, which gets following props: sortedAndFilteredData, error, loading, sortState, setSort, filterState, setFilter, queryState, setQuery, selectAll, selectOne, selected, selectAllActive, resetSortAndFilter, dragEnabled, total, currentPage, currentPageSize, changePage
 * @param {(items: object[]) => void} inputProperties.onSelectChange - Function to call when selection changes
 * @returns {JSX.Element} Children with above injected props to handle list layout functionality
 * @component
 */
const PaginationListLayoutHandler = ({
  useDataFetching,
  initSort = [],
  shouldUseSearchQueryParams,
  children,
  onSelectChange = () => {},
  dragAndDropSettings: { orderField = 'order', orderDirection = 'ASC' } = {},
}) => {
  const [, , , limit, skip] = useParametersFromSearchParams();
  const [sortState, setSort, filterState, setFilter, queryState, setQuery, resetSortAndFilter] = useSortFilterQuery({
    initSort,
    shouldUseSearchQueryParams,
    orderField,
    orderDirection,
    shouldModifyData: false,
  });
  const [pageState, setPageState] = useState({
    page: skip && skip > 0 ? getPageFromSkipAndLimit(skip, limit) : 1,
    pageSize: limit || 10,
  });

  const {
    data: dataWithMeta,
    error,
    loading,
  } = useDataFetching(
    queryState,
    pageState.pageSize,
    getSkipFromPageAndLimit(pageState.page, pageState.pageSize),
    sortState,
    filterState,
  );

  const data = useMemo(() => dataWithMeta?.entries || [], [dataWithMeta?.entries]);

  useEffect(() => {
    if (dataWithMeta?.count && dataWithMeta.count / pageState.pageSize < pageState.page) {
      setPageState((prevState) => ({
        page: Math.ceil(dataWithMeta.count / prevState.pageSize),
        pageSize: prevState.pageSize,
      }));
    }
  }, [dataWithMeta, pageState.pageSize, pageState.page]);

  const [selectAll, selectOne, selected, selectAllActive] = usePaginatedSelectable({ onSelectChange, data });

  const total = dataWithMeta?.count || 0;

  const changePage = useCallback(
    (page, pageSize) => {
      setPageState({ page, pageSize });
      if (shouldUseSearchQueryParams) modifySearchParameters('skip', getSkipFromPageAndLimit(page, pageSize));
      if (shouldUseSearchQueryParams) modifySearchParameters('limit', pageSize);
    },
    [shouldUseSearchQueryParams],
  );

  return children(
    data,
    error,
    loading,
    sortState,
    setSort,
    filterState,
    setFilter,
    queryState,
    setQuery,
    selectAll,
    selectOne,
    selected,
    selectAllActive,
    resetSortAndFilter,
    false,
    total,
    pageState.page,
    pageState.pageSize,
    changePage,
  );
};

export default PaginationListLayoutHandler;
