import { useTranslation } from 'react-i18next';
import Card from 'components/common/CardV2';
import getPhoneNumber from 'ProjectCardManagement/ClientManagement/viewModel/getPhoneNumber';
import classes from './ContactCard.module.less';

/**
 * Card with contact details of a client's contact person
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.contact - The contact person details
 * @param {string} inputProperties.contact.salutation - The salutation of the contact person
 * @param {string} inputProperties.contact.firstName - The first name of the contact person
 * @param {string} inputProperties.contact.lastName - The last name of the contact person
 * @param {string} inputProperties.contact.position - The position of the contact person
 * @param {string} inputProperties.contact.email - The email of the contact person
 * @param {string} inputProperties.contact.phoneNumber - The phone number of the contact person
 * @param {string} inputProperties.contact.phoneNumberAlternative - The alternative phone number of the contact person
 * @returns {JSX.Element} Card with contact details
 * @component
 */
const ContactCard = ({ contact }) => {
  const { t } = useTranslation();

  const phoneNumber = getPhoneNumber(contact.phoneNumber);
  const phoneNumberAlternative = getPhoneNumber(contact.phoneNumberAlternative);

  return (
    <Card size="small" bordered>
      <div className={classes.contact_card_content}>
        <div>
          <span className={classes.contact_card_name}>
            {contact.salutation
              ? `${t(`ClientViewPage.clientDetails.contactCard.salutation.${contact.salutation}`)} `
              : ''}
            {contact.firstName} {contact.lastName}{' '}
          </span>
          <br />
          {contact.position ? (
            <>
              {contact.position} <br />{' '}
            </>
          ) : null}
          {contact.email} <br />
          {phoneNumber ? (
            <>
              {phoneNumber} <br />
            </>
          ) : null}
          {phoneNumberAlternative}
        </div>
        <div className={classes.contact_card_action_buttons}>{/* For action buttons */}</div>
      </div>
    </Card>
  );
};

export default ContactCard;
