import Form from '../Form';
import I18nFormik from './I18nFormik';

/**
 * Form with formik control
 * @param {object} inputProperties - Input properties of the component
 * @param {React.ReactNode} inputProperties.children - Children of the component
 * @param {function} inputProperties.onSubmit - On submit function
 * @param {object} inputProperties.initialValues - Initial values of the form
 * @param {boolean} inputProperties.reInitOnSubmit - Reinitialize on submit
 * @param {React.Ref} inputProperties.formikRef - Ref to formik ref
 * @returns {JSX.Element} Formik form
 * @component
 */
const FormikForm = ({ children, onSubmit, initialValues, reInitOnSubmit = true, formikRef, ...props }) => {
  return (
    <I18nFormik
      ref={formikRef}
      onSubmit={onSubmit}
      reInitOnSubmit={reInitOnSubmit}
      initialValues={initialValues}
      {...props}
    >
      <Form>{children}</Form>
    </I18nFormik>
  );
};

export default FormikForm;
