import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormikForm from 'components/common/FormComponents/Formik/FormikForm';
import addNewClient from 'ProjectCardManagement/ClientManagement/methods/addNewClient';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import useClient from 'ProjectCardManagement/ClientManagement/hooks/useClient';
import editClient from 'ProjectCardManagement/ClientManagement/methods/editClient';

/**
 * Schema for new client form
 * @returns {Yup.ObjectSchema<object>}
 */
const ClientSchema = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t('ClientsListPage.addClientModal.form.name.required')),
    type: Yup.string().nullable().required(t('ClientsListPage.addClientModal.form.type.required')),
  });

/**
 * Formik form component for adding a new client, which renders the fields got as childrens
 * @param {object} inputProperties - Input properties of the component
 * @param {React.RefObject} inputProperties.formikRef - Formik reference
 * @param {Function} inputProperties.setSubmitting - Set submitting function
 * @param {React.ReactNode} inputProperties.children - Children of the component
 * @returns {JSX.Element} AddClientForm component
 * @component
 */
const AddOrEditClientForm = ({ children, setSubmitting, formikRef, errorRef, clientId }) => {
  const { t } = useTranslation();
  const { client } = useClient(clientId);
  const [submitError, setSubmitError] = useState(null);

  const initialValues = useMemo(
    () => ({
      clientId: client?._id || null,
      name: client?.name || '',
      identifier: client?.identifier || '',
      type: client?.type || '',
      address: {
        country: client?.address?.country || '',
        city: client?.address?.city || '',
        cityCode: client?.address?.cityCode || '',
        street: client?.address?.street || '',
        houseNumber: client?.address?.houseNumber || '',
      },
      importFields: {
        commercialObject: client?.importFields?.commercialObject || '',
      },
    }),
    [
      client?._id,
      client?.name,
      client?.identifier,
      client?.type,
      client?.address?.country,
      client?.address?.city,
      client?.address?.street,
      client?.address?.houseNumber,
      client?.address?.cityCode,
      client?.importFields?.commercialObject,
    ],
  );

  const clientSchema = useMemo(() => ClientSchema(t), [t]);
  const onSubmit = useCallback(
    (values) => {
      const submitMethod = clientId ? editClient : addNewClient;
      return submitMethod(values)
        .then(setSubmitting(false))
        .catch((error) => {
          setSubmitError(error);
          setSubmitting(false);
          throw error;
        });
    },
    [setSubmitting, clientId],
  );

  useEffect(() => {
    if (errorRef && !errorRef.current) {
      // eslint-disable-next-line no-param-reassign
      errorRef.current = { resetError: () => setSubmitError(null) };
    }
  }, [errorRef]);

  return (
    <FormikForm
      formikRef={formikRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={clientSchema}
      enableReinitialize
    >
      <QueryErrorAlert error={submitError} />
      {children}
    </FormikForm>
  );
};

export default AddOrEditClientForm;
