import { Table } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { memo, useCallback, useRef } from 'react';

export const FormItemMemo = memo(FormItem, equal);
export const TableMemo = memo(Table, equal);

// used to create a static reference to a changeable function
export const useFunctionToRefCB = (func) => {
  const ref = useRef(func);
  ref.current = func;
  return useCallback((...props) => ref.current(...props), []);
};

export const useCreateRefCB = () => {
  const ref = useRef();
  const cb = useCallback((props) => ref.current(props), []);
  return (func) => {
    ref.current = func;
    return cb;
  };
};
