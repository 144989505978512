import { Badge, Tooltip, Typography } from 'antd';
import equal from 'fast-deep-equal/es6/react';
import { formatDateTime } from 'utils/date';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import Card from 'components/common/Card';
import { Trans, useTranslation } from 'react-i18next';
import { useCachedQuery } from 'graphql/utils';
import { userHistoryItemsViewQuery } from 'graphql/queries';
import { useParams } from 'react-router';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import { InfoCircleOutlined } from '@ant-design/icons';
import InformationCard from './ShoppingCartHistoryItems/InformationCard';
import SendedEmailCard from './ShoppingCartHistoryItems/SendedEmailCard';
import SepaMandateGrantedCell from './ShoppingCartHistoryItems/SepaMandateGrantedCell';

import classes from './ShoppingCartView.module.less';

const { Paragraph } = Typography;

const SendingError = ({ error, title }) => {
  const { t } = useTranslation();
  if (error)
    return (
      <>
        <Badge status="error" text={t('viewer.ShoppingCartView.ShoppingCartHistoricItems.sendingError')} />{' '}
        <Tooltip title={getMessageFromGraphQLError(error)} autoAdjustOverflow>
          <InfoCircleOutlined />
        </Tooltip>
      </>
    );
  return <Badge status="success" text={title} />;
};

/**
 * All possible actionTypes, which represents a sent email
 */
export const SENDED_EMAIL_ACTIONS = [
  'ShoppingCart.sentByEmail',
  'DigitalSignature.startMailSendForCompanySignee',
  'DigitalSignature.startMailSendForTenantSignee',
  'DigitalSignature.passwordMailSend',
  'DigitalSignature.signedDocsMailSend',
  'DigitalSignature.revokeNotificationMailSend',
  'DigitalSignature.signeeOpenedNotificationMailSend',
  'DigitalSignature.signeeSignedNotificationMailSend',
  'DigitalSignature.reminderMailSend',
];

/**
 * SendedEmailActionCell component, which represents an action cell of a sent email event
 * @param {Object} inputParameters - Input parameters of component
 * @param {String} inputParameters.actionType - Type of action
 * @param {JSX.Element} inputParameters.title - Action title
 * @param {Object} inputParameters.variables - Parameters of the action
 * @returns {JSX.Element} Shows informations about the sent email, its content and metadata and its status
 * @component
 */
const SendedEmailActionCell = ({ title, variables, actionType }) => {
  return (
    <>
      <div>
        <SendingError error={variables?.error} title={title} />
      </div>
      <SendedEmailCard
        shoppingCartId={variables.originalId}
        className={classes.previewEmailCard}
        {...variables}
        emailData={variables}
        initOpen={actionType === 'ShoppingCart.sentByEmail'}
      />
    </>
  );
};

/**
 * ActionCell component renders the action cell for an action row in the history table of a shopping cart
 * @param {Object} inputParameters - Input parameters for component
 * @param {String} inputParameters.actionType - Action type of the action
 * @param {Object} inputParameters.variables - Parameters of the action
 * @param {string} inputParameters.shoppingCartId - Database id of related shopping cart
 * @returns {JSX.Element} Renders title of the action event and additional infos
 * @component
 */
const ActionCell = ({ actionType, variables, shoppingCartId }) => {
  const { t } = useTranslation();
  const title = (
    <Trans
      i18nKey={`viewer.ShoppingCartView.ShoppingCartHistoricItems.${actionType}`}
      values={{
        ...variables,
        revision: variables.revision ? t('viewer.ShoppingCartView.ShoppingCartHistoricItems.revision') : null,
        oldDate: variables.oldDate ? formatDateTime(variables.oldDate) : undefined,
        newDate: variables.newDate ? formatDateTime(variables.newDate) : undefined,
      }}
      components={{
        gray: <span className={classes.signeeEmail} />,
      }}
    />
  );

  if (SENDED_EMAIL_ACTIONS.find((sendedEmailActionType) => actionType === sendedEmailActionType))
    return <SendedEmailActionCell title={title} variables={variables} actionType={actionType} />;

  return (
    <>
      <div>{title}</div>
      {actionType === 'DigitalSignature.signed' ? (
        <Paragraph className={classes.signee}>
          {variables.signeeFirstName} {variables.signeeLastName}{' '}
          <span className={classes.signeeEmail}>({variables.signeeEmail})</span>
        </Paragraph>
      ) : null}
      {actionType === 'DigitalSignature.revoked' ? (
        <InformationCard message={variables.internalNote} className={classes.previewEmailCard} />
      ) : null}
      {actionType === 'DigitalSignature.sepaMandateGranted' ? (
        <SepaMandateGrantedCell
          {...variables}
          shoppingCartId={shoppingCartId}
          sepaMandateDocumentTemplateId={variables.documentTemplateId}
        />
      ) : null}
      {actionType === 'DigitalSignature.bookmarkedItemsAdded' ? (
        <Paragraph className={classes.signee}>
          <ul>
            {variables.bookmarkedItems.map((item) => (
              <li key={item.name}>
                {item.categoryName} {item.name}
              </li>
            ))}
          </ul>
        </Paragraph>
      ) : null}
    </>
  );
};

/**
 * Generator function to generate the columns for the history table for a shopping cart
 * @param {Object} inputParameters - Input parameters of the function
 * @param {Function} inputParameters.t - Translations function
 * @param {string} inputParameters.shoppingCartId - Database id of related shopping cart
 * @returns {Object[]} list of objects, which are specifing columns of an ant design table
 */
const historicRevisionsColumns = ({ t, shoppingCartId }) => [
  {
    title: t('viewer.ShoppingCartView.columns.createdAt'),
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (createdAt) => formatDateTime(new Date(createdAt)),
    width: '14%',
  },
  {
    title: t('viewer.ShoppingCartView.columns.actions'),
    key: 'action',
    render: ({ actionType, variables }) => (
      <ActionCell actionType={actionType} variables={variables} shoppingCartId={shoppingCartId} />
    ),
  },
  {
    title: t('viewer.ShoppingCartView.columns.createdBy'),
    key: 'createdBy',
    dataIndex: 'createdBy',
    render: (createdBy) => createdBy?.fullName,
    width: '14%',
  },
];

const ShoppingCartHistoryItems = () => {
  const params = useParams();

  const { data, ...rest } = useCachedQuery(userHistoryItemsViewQuery, {
    variables: { _id: params.id },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    pollInterval: 1000 * 60 * 2,
  });
  const historyItems = useMemo(() => {
    const shoppingCart = grabFirstGQLDataResult(data);
    return shoppingCart?.historyItems;
  }, [data]);

  const { t } = useTranslation();

  const _columns = useMemo(() => historicRevisionsColumns({ t, shoppingCartId: params.id }), [t, params.id]);
  return (
    <Card title={t('viewer.ShoppingCartView.ShoppingCartHistoricItems.title')}>
      <GraphQLLoadingWrapper data={data} {...rest}>
        <TableMemo
          rowKey={(record) => record?._id}
          dataSource={historyItems}
          columns={_columns}
          pagination={false}
          scroll={{ x: 450 }}
        />
      </GraphQLLoadingWrapper>
    </Card>
  );
};
export default memo(ShoppingCartHistoryItems, equal);
