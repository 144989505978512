import { generatePath } from 'react-router';
import routePaths from 'router/route-paths';

/**
 * Opens the view client page for a specific client
 * @param {string} clientId - Id of the client
 * @param {Function} navigate - Function to navigate to a new page
 */
const openViewClient = (clientId, navigate) => {
  navigate(generatePath(routePaths.client, { id: clientId }));
};

export default openViewClient;
