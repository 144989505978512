import { Button as AntDButton } from 'antd';

export const Button = ({ children, size, ...props }) => {
  return (
    <AntDButton size={size} {...props} id="button">
      {children}
    </AntDButton>
  );
};

export const RoundedButton = ({ children, size, ...props }) => {
  return (
    <Button size={size} shape="round" {...props}>
      {children}
    </Button>
  );
};
