import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik-antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import { adminEmailPreferencesQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { updateTenantSettings } from 'graphql/methods';
import Card from 'components/common/Card';
import { FaSignature } from 'react-icons/fa';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import { RichText, PLACEHOLDER_USE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import EmailFormikRichEditor from 'TenantInstanceConfiguration/EmailTemplateManagement/components/EmailFormikRichEditor';

/**
 * This is the signature settings card's rich editor
 * @param props - props like 'label'
 * @returns {JSX.Element}
 * @compoent
 */
export const SignatureRichEditor = (props) => (
  <EmailFormikRichEditor
    name="signature"
    {...props}
    allowedUses={[PLACEHOLDER_USE.MAIL_SIGNATURE]}
    rootElement="paragraph"
  />
);

/**
 * This is the signature settings card on the email settings page
 * @returns {JSX.Element}
 * @component
 */
const SignatureCard = () => {
  const result = useCachedQuery(adminEmailPreferencesQuery);
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      signature:
        grabFirstGQLDataResult(result.data)?.emailPreferences?.signature ??
        RichText.getDefaultRichEditorValue({ rootElement: 'paragraph' }),
    }),
    [result.data],
  );
  const onSubmit = useCallback(async ({ signature }) => {
    await updateTenantSettings({
      settings: {
        emailPreferences: {
          signature,
        },
      },
    });
  }, []);
  return (
    <GraphQLLoadingWrapper {...result} countSkeletons={1}>
      <Card icon={<FaSignature />} title={t('admin.Setting.Email.signatureTitle')}>
        <I18nFormik initialValues={initialValues} onSubmit={onSubmit}>
          <Form layout="vertical">
            <AlertFromFormik />
            <SignatureRichEditor label="" />
            <SaveChangesButton initialValues={initialValues} />
          </Form>
        </I18nFormik>
      </Card>
    </GraphQLLoadingWrapper>
  );
};
export const SignatureCardMemo = memo(SignatureCard, equal);
