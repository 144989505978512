import { Alert, Typography } from 'antd';
import { adminTenantSettingsQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { grabFirstGQLDataResult } from 'utils/helpers';
import classes from './PremiumClAdvertisementAlert.module.less';

const { Text } = Typography;

const userIsOnlyBasisUser = (commonLibraryAccessGroups) =>
  commonLibraryAccessGroups?.length === 1 && commonLibraryAccessGroups[0] === 'BASIS';

/**
 * This component will show an alert for users which dont already have access to the premium common library content
 * @returns {JSX.Element | null} The alert component or null if the user already has access to the premium common library
 */
export function PremiumClAdvertisementAlert() {
  const { data, loading, error } = useCachedQuery(adminTenantSettingsQuery);
  const commonLibraryAccessGroups = useMemo(() => grabFirstGQLDataResult(data)?.commonLibraryAccessGroups, [data]);

  return useMemo(() => {
    if (loading || error) return null;
    if (!userIsOnlyBasisUser(commonLibraryAccessGroups)) {
      return null;
    }

    return (
      <Alert
        description={
          <Text className={classes.alertText}>
            <Trans
              i18nKey="admin.premiumClAdvertisement"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
                a: <a className={classes.contactSupportEmail} />,
              }}
            />
          </Text>
        }
        showIcon
        type="info"
        className="alert-info"
      />
    );
  }, [commonLibraryAccessGroups, error, loading]);
}
