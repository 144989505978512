import { useMemo } from 'react';
import { Select } from 'formik-antd';
import { useCachedQuery } from 'graphql/utils';
import { userGetStatusesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import statusTypes from 'constants/statusTypes';

const { Option } = Select;

const CartStateSelect = ({ label, fieldName, placeholder, tooltip }) => {
  const { data, loading } = useCachedQuery(userGetStatusesQuery, {
    variables: { isLibrary: false, type: statusTypes.shoppingCart },
    fetchPolicy: 'cache-and-network',
  });
  const cartStates = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return (
    <FormItem name={fieldName} label={label} tooltip={tooltip}>
      <Select name={fieldName} loading={loading} disabled={loading} placeholder={placeholder} allowClear>
        {loading ||
          cartStates?.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
      </Select>
    </FormItem>
  );
};

export default CartStateSelect;
