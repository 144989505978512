import { useCallback, useState } from 'react';
import { Modal as AntModal } from 'antd';
import classes from './Modal.module.less';

/**
 * Handles the state of a modal.
 * @returns {[boolean, Function, Function]} The modal state, show modal function and close modal function.
 */
export const useModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const showModal = useCallback(() => setModalVisible(true), []);
  const closeModal = useCallback(() => setModalVisible(false), []);

  return [modalVisible, showModal, closeModal];
};

/**
 * Modal component to show a modal (See 'https://4x.ant.design/components/modal/#API' for more information on modal properties)
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.title - Title of the modal
 * @param {boolean} inputProperties.visible - Visibility of the modal
 * @param {React.ReactNode} inputProperties.children - Children of the modal
 * @returns {JSX.Element} Modal
 */
const Modal = ({ visible, title, children, size, className = '', ...props }) => {
  let classNames = className;

  if (size === 'wide') {
    classNames += classes.wideModal;
  } else if (size === 'medium') {
    classNames += classes.mediumModal;
  }

  return (
    <AntModal visible={visible} title={title} className={classNames} {...props}>
      {children}
    </AntModal>
  );
};

export default Modal;
