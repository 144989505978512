import { Calc, numberToLocaleString, RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { Alert, Drawer, Divider } from 'antd';
import { CompareToGlobalProvider, useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { InputNumber } from 'components/common/InputNumber';
import equal from 'fast-deep-equal/es6/react';
import { useMemo, memo, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import confirmModal from 'utils/confirmModal';
import { ErrorMessage, useFormikContext } from 'formik';
import inputConfirmDrawer from 'utils/inputConfirmModal';
import i18n from 'i18n';
import * as Yup from 'yup';
import cn from 'classnames';
import { RichEditorField } from 'components/common/RichEditor';
import Switch from 'components/common/Switch';
import { LinkButton } from 'components/common/LinkButton';
import Slider from 'components/common/Slider';
import ShoppingCartFormItem, {
  RequiredFieldsAlertWrapper,
} from 'pages/shoppingCartManagement/ShoppingCart/components/ShoppingCartFormItem';
import { getDefaultValueFromInternalInputField } from 'pages/shoppingCartManagement/ShoppingCart/utils';
import { isEqual } from 'lodash';
import DifferencesPopover from 'components/user/compareToGlobal/DifferencesPopover';
import concatClassNames from 'utils/classNames';
import classes from './CustomPriceDrawer.module.less';
import { InputNumberFieldMemo } from '../components';
import CustomPriceHelperWidget from './CustomPriceHelperWidget';

const customPriceValidationSchema = ({ internalInputFields }) =>
  Yup.object().shape({
    ...internalInputFields.reduce(
      (acc, { _id, name, allowedRangeMinValue, allowedRangeMaxValue }) => ({
        ...acc,
        [_id]: Yup.number()
          .nullable()
          .min(0)
          .required()
          .test(
            'input-field-is-less-then-min',
            i18n.t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.internalInputField.errors.min', {
              name,
              value: allowedRangeMinValue,
            }),
            (value) => value >= allowedRangeMinValue,
          )
          .test(
            'input-field-is-greater-then-max',
            i18n.t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.internalInputField.errors.max', {
              name,
              value: allowedRangeMaxValue,
            }),
            (value) => value <= allowedRangeMaxValue,
          )
          .label(name),
      }),
      {},
    ),
  });

const usePrice = ({ calcData, variables }) => {
  return useMemo(() => {
    try {
      const { value: priceBeforeDiscount } = Calc.calcItem({
        ...calcData,
        variables: { ...calcData.variables, ...variables },
      });
      const discountedValue = Calc.applyDiscountToValue({ value: priceBeforeDiscount, discount: calcData.discount });
      return {
        priceBeforeDiscount,
        discountedValue,
      };
    } catch (e) {
      console.debug(e);
      return null;
    }
  }, [calcData, variables]);
};
const usePriceTranslation = ({ translationSharedData, name, calcData, variables }) => {
  const { t } = useTranslation();
  const { errors, touched, setFieldError, setFieldTouched } = useFormikContext();
  const value = usePrice({ calcData, variables });
  useEffect(() => {
    let error;
    if (!errors[name] && !Number.isFinite(value?.priceBeforeDiscount))
      error = t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.internalInputField.errors.infinity');
    else if (!errors[name] && !value) error = t(`user.ShoppingCart.TotalPricing.error`);
    if (error) {
      if (!touched[name]) setFieldTouched(name, true);
      setFieldError(name, error);
    }
  }, [setFieldError, setFieldTouched, t, errors, name, value?.priceBeforeDiscount, value, touched]);
  return useMemo(() => {
    if (Number.isFinite(value?.priceBeforeDiscount))
      return Calc.priceInfoAsText({
        ...translationSharedData,
        value,
      });
    return null;
  }, [translationSharedData, value]);
};

const Price = ({ calcData, variables, translationSharedData, name, label, tooltip }) => {
  const { t } = useTranslation();

  const priceTranslation = usePriceTranslation({
    calcData,
    variables,
    name,
    translationSharedData,
  });

  return (
    <FormItem
      name={name}
      label={label}
      tooltip={tooltip}
      className={concatClassNames('ant-form-item-hide-error', classes.priceItem)}
    >
      {priceTranslation && typeof priceTranslation === 'object' ? (
        <Trans
          i18nKey={`sharedPackage.${priceTranslation.code}`}
          components={{ del: <del /> }}
          values={{
            ...priceTranslation.variables,
            newlineOrWhitespace: '<br/>',
            paymentInterval: t(`common.Item.paymentIntervalValue.${priceTranslation.variables.paymentInterval}`, {
              defaultValue: '',
            }),
            separator: ',',
          }}
        />
      ) : (
        priceTranslation
      )}
      <ErrorMessage component={RequiredFieldsAlertWrapper} name={name} />
    </FormItem>
  );
};

const PriceMemo = memo(Price, equal);

const OriginalPrice = ({ calcData, variables, translationSharedData }) => {
  const { t } = useTranslation();

  const showMinMax = Calc.getShowMinMaxValue(calcData?.document?.minPrice, calcData?.document?.maxPrice);

  return (
    <PriceMemo
      calcData={calcData}
      label={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.originalPrice.label')}
      name="originalPrice"
      tooltip={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.originalPrice.tooltip')}
      translationSharedData={{
        ...translationSharedData,
        minPrice: calcData?.document?.minPrice,
        maxPrice: calcData?.document?.maxPrice,
        ...showMinMax,
      }}
      variables={variables}
    />
  );
};

const OriginalPriceMemo = memo(OriginalPrice, equal);

const NewPrice = ({ calcData, translationSharedData, defaultValuesFromInputFields }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const isHideNewPrice = useMemo(
    () => !Object.keys(defaultValuesFromInputFields).find((_id) => defaultValuesFromInputFields[_id] !== values[_id]),
    [defaultValuesFromInputFields, values],
  );
  if (isHideNewPrice) return null;

  const getShowMinMax = Calc.getShowMinMaxValue(calcData?.document?.minPrice, calcData?.document?.maxPrice);

  return (
    <PriceMemo
      name="newPrice"
      label={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.newPrice.label')}
      tooltip={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.newPrice.tooltip')}
      calcData={calcData}
      variables={values}
      translationSharedData={{
        ...translationSharedData,
        minPrice: calcData?.document?.minPrice,
        maxPrice: calcData?.document?.maxPrice,
        ...getShowMinMax,
      }}
    />
  );
};

const NewPriceMemo = memo(NewPrice, equal);

const InputFieldFormikSlider = memo(({ name, allowedRangeMinValue, allowedRangeMaxValue }) => {
  return (
    <div>
      <Slider name={name} debounceTime={500} min={allowedRangeMinValue} max={allowedRangeMaxValue} />
      <div className={cn(classes.allowedRange, classes.inputFieldInfo)}>
        <span>
          {numberToLocaleString(allowedRangeMinValue, i18n.language, {
            isRestrictMaximumDigits: false,
          })}
        </span>
        <span>
          {numberToLocaleString(allowedRangeMaxValue, i18n.language, {
            isRestrictMaximumDigits: false,
          })}
        </span>
      </div>
    </div>
  );
}, equal);

const rows = ({ t, formatter }) => [
  {
    label: t('common.fields.defaultValue.label'),
    render: (inputField) => getDefaultValueFromInternalInputField(inputField, { formatter }),
  },
];

const InputFieldFormikInput = memo(({ inputField }) => {
  const { t } = useTranslation();
  const { isCompare } = useCompareToGlobalContext();
  const { _id, unitName } = inputField;
  return (
    <div>
      <InputNumberFieldMemo name={_id} debounceTime={500} unitName={unitName} label="" />
      <div className={cn(classes.defaultValue, classes.inputFieldInfo)}>
        {t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.defaultValue.label')}:{' '}
        {numberToLocaleString(inputField.value, i18n.language, {
          isRestrictMaximumDigits: false,
        })}
        {isCompare ? <DifferencesPopover data={inputField} rows={rows({ t })} /> : null}
      </div>
    </div>
  );
}, equal);

const CustomPriceForm = ({
  currentFeeTypeMessage,
  initialCustomPrice,
  internalInputFields,
  translationSharedData,
  defaultValuesFromInputFields,
  values,
  calcData,
}) => {
  const { setFieldValueAndTouched, submitForm } = useFormikContext();
  const { t } = useTranslation();
  const oldVariables = useMemo(
    () => ({ ...values, ...defaultValuesFromInputFields }),
    [defaultValuesFromInputFields, values],
  );
  return (
    <div className="shopping-cart">
      <div className="margin-bottom-16">
        <OriginalPriceMemo
          variables={oldVariables}
          calcData={calcData}
          internalInputFields={internalInputFields}
          translationSharedData={translationSharedData}
        />
      </div>
      <Divider />
      {internalInputFields.length ? (
        <>
          <FormItem
            name="internalInputField"
            label={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.internalInputField.label')}
            tooltip={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.internalInputField.tooltip')}
          >
            {internalInputFields.map((internalInputField) => {
              const { _id, allowedRangeMinValue, allowedRangeMaxValue, name } = internalInputField;
              return (
                <ShoppingCartFormItem
                  key={_id}
                  name={_id}
                  label={name}
                  className={cn(Number.isFinite(initialCustomPrice) ? 'form-item-no-margin' : null)}
                >
                  <div className={classes.inputField}>
                    <InputFieldFormikSlider
                      name={_id}
                      allowedRangeMinValue={allowedRangeMinValue}
                      allowedRangeMaxValue={allowedRangeMaxValue}
                    />
                    <InputFieldFormikInput inputField={internalInputField} />
                  </div>
                </ShoppingCartFormItem>
              );
            })}
          </FormItem>
          <NewPriceMemo
            calcData={calcData}
            defaultValuesFromInputFields={defaultValuesFromInputFields}
            translationSharedData={translationSharedData}
          />
          <Divider />
        </>
      ) : null}

      <ShoppingCartFormItem
        name="customPrice"
        label={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.customPrice.label')}
        tooltip={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.customPrice.tooltip')}
        className={cn(Number.isFinite(initialCustomPrice) ? 'form-item-no-margin' : null)}
      >
        <InputNumber name="customPrice" addonAfter="€" />
      </ShoppingCartFormItem>
      {Number.isFinite(initialCustomPrice) ? (
        <LinkButton
          className={classes.removeButton}
          onClick={() =>
            confirmModal({
              cancelText: i18n.t('common.cancel'),
              maskClosable: true,
              okText: i18n.t('common.ok'),
              okType: 'danger',
              onOk: () => {
                setFieldValueAndTouched('customPrice', null);
                submitForm();
              },
              title: t('user.ShoppingCart.Category.Item.customPriceDrawer.deleteCustomPriceConfirmation'),
            })
          }
        >
          {t('user.ShoppingCart.Category.Item.customPriceDrawer.removeCustomPrice')}
        </LinkButton>
      ) : null}
      {currentFeeTypeMessage ? (
        <Alert
          message={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.showCalculatedPrice.disabledTooltip')}
          className="alert-info"
          type="info"
          showIcon
        />
      ) : (
        <Switch
          name="showCalculatedPrice"
          label={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.showCalculatedPrice.label')}
          tooltip={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.showCalculatedPrice.tooltip')}
        />
      )}
      <Divider />
      <RichEditorField
        allowedModifiers={[
          'bold',
          'italic',
          'underline',
          'bulleted-list',
          'numbered-list',
          'checked-list',
          'indent',
          'outdent',
          'reset-formatting',
        ]}
        rootElement="div"
        containerProps={{ className: classes.textInput }}
        hidePlaceholdersButton
        name="officialReasonText"
        label={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.officialReasonText.label')}
      />
      <RichEditorField
        allowedModifiers={[
          'bold',
          'italic',
          'underline',
          'bulleted-list',
          'numbered-list',
          'checked-list',
          'indent',
          'outdent',
          'reset-formatting',
        ]}
        containerProps={{ className: classes.textInput }}
        rootElement="div"
        hidePlaceholdersButton
        name="internalNoteToTeam"
        label={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.internalNoteToTeam.label')}
        tooltip={t('user.ShoppingCart.Category.Item.customPriceDrawer.fields.internalNoteToTeam.tooltip')}
      />
    </div>
  );
};

export const customPriceDrawer = ({
  _id,
  calcData,
  currentFeeTypeMessage,
  customPrice,
  internalInputFields = [],
  internalNoteToTeam,
  isCompare,
  officialReasonText,
  onSubmit,
  showCalculatedPrice,
  translationSharedData,
  values,
}) => {
  const defaultValuesFromInputFields = internalInputFields.reduce(
    (acc, inputField) => ({
      ...acc,
      [inputField._id]: inputField.value,
    }),
    {},
  );
  const onClose = async (e, { formik }) => {
    if (!isEqual(formik.initialValues, formik.values)) {
      return new Promise((resolve) => {
        confirmModal({
          cancelText: i18n.t('common.cancel'),
          maskClosable: true,
          okText: i18n.t('common.ok'),
          okType: 'danger',
          onOk: () => {
            resolve();
          },
          onCancel: () => resolve(false),
          title: i18n.t('user.ShoppingCart.Category.Item.customPriceDrawer.cancelConfirmation'),
        });
      });
    }
    return undefined;
  };
  return inputConfirmDrawer({
    formContent: () => (
      <CompareToGlobalProvider defaultValue={isCompare}>
        <CustomPriceForm
          calcData={calcData}
          currentFeeTypeMessage={currentFeeTypeMessage}
          defaultValuesFromInputFields={defaultValuesFromInputFields}
          initialCustomPrice={customPrice}
          internalInputFields={internalInputFields}
          translationSharedData={translationSharedData}
          values={values}
        />
      </CompareToGlobalProvider>
    ),
    fields: [],
    headerText: (
      <>
        {i18n.t('user.ShoppingCart.Category.Item.customPriceDrawer.title')}
        <br />
        <CustomPriceHelperWidget />
      </>
    ),
    onSubmit: (valuesFormik) => {
      const internalInputFieldIds = internalInputFields.map((inputField) => inputField._id);
      const newValues = Object.entries(valuesFormik).reduce((acc, [key, value]) => {
        const isInternalInputField = internalInputFieldIds.includes(key);
        const data = {
          ...acc,
          [(isInternalInputField ? '' : `${_id}_`) + key]: value,
        };
        if (isInternalInputField)
          data[`${key}_isCustom`] = value !== internalInputFields.find((inputField) => inputField._id === key)?.value;
        return data;
      }, {});
      return onSubmit(newValues);
    },
    value: {
      ...internalInputFields.reduce(
        (acc, inputField) => ({
          ...acc,
          [inputField._id]: values[inputField._id] ?? defaultValuesFromInputFields[inputField._id],
        }),
        {},
      ),
      internalNoteToTeam: internalNoteToTeam ?? RichText.getDefaultRichEditorValue(),
      customPrice,
      officialReasonText: officialReasonText ?? RichText.getDefaultRichEditorValue(),
      showCalculatedPrice,
      newPrice: null,
    },
    onClose,
    ContainerComponent: Drawer,
    footer: true,
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    validationSchema: (props) => customPriceValidationSchema({ internalInputFields, ...props }),
    width: '400px',
  });
};
