import { useTranslation } from 'react-i18next';
import { DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import ICONS_BY_STATE from 'components/user/SigningStateIcon';
import { RenderIconWithText } from 'components/common/List/FieldRenderers';

/**
 * Render digital signature state with i18n and icon
 * @param {object} inputProperties - The input properties for the component
 * @returns {JSX.Element} The DigitalSignatureStateWithIcon component
 * @component
 */
const DigitalSignatureStateWithIcon = ({ digitalSignatureState }) => {
  const { t } = useTranslation();
  return (
    <RenderIconWithText
      text={t(
        `ShoppingCartEntriesPage.entriesTable.digitalSignatureState.${
          digitalSignatureState || DIGITAL_SIGNATURE_STATE.NO_DIGITAL_SIGNATURE
        }`,
      )}
      icon={ICONS_BY_STATE[digitalSignatureState || DIGITAL_SIGNATURE_STATE.NO_DIGITAL_SIGNATURE]({ size: '16px' })}
    />
  );
};

export default DigitalSignatureStateWithIcon;
