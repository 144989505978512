import i18n from 'i18n';
import { updateShoppingCart } from 'graphql/methods';
import { updateShoppingCartStatus } from 'pages/shoppingCartManagement/ShoppingCartEntries/ShoppingCartEntries';
import inputConfirmModal from 'utils/inputConfirmModal';
import ChangeProjectCardStateModalContent from '../components/ChangeProjectCardStateModalContent';

/**
 * Changes the status of a project card by showing a modal for the user to select a new status
 * @param {string} _id - Id of the project card
 * @param {string} state - Status id of the project card
 * @returns {Promise<void>}
 */
const changeProjectCardState = async (_id, state) =>
  inputConfirmModal({
    formContent: () => <ChangeProjectCardStateModalContent status={state} />,
    fields: [],
    onSubmit: async (modifier) => {
      if (modifier.status !== state) await updateShoppingCart({ _id, modifier: { statusId: modifier.status } });
    },
    value: { status: state },
    validationSchema: updateShoppingCartStatus,
    headerText: i18n.t('ShoppingCartEntriesPage.statusModal.title'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    forceMultiField: true,
    width: '600px',
  });

export default changeProjectCardState;
