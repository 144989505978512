import { FaRunning, FaCheck, FaFile, FaStop, FaMinusCircle, FaTimes } from 'react-icons/fa';

/**
 * Maps digital signature states to colored icons
 */
const ICONS_BY_STATE = {
  NO_DIGITAL_SIGNATURE: (props) => <FaTimes color="#595959" {...props} />,
  SIGNED: (props) => <FaCheck color="#16B513" {...props} />,
  DRAFT: (props) => <FaFile color="#595959" {...props} />,
  STARTED: (props) => <FaRunning color="#FAAD14" {...props} />,
  REVOKED: (props) => <FaStop color="rgb(255, 77, 79)" {...props} />,
  TERMINATED: (props) => <FaMinusCircle color="rgb(255, 77, 79)" {...props} />,
};

export default ICONS_BY_STATE;
