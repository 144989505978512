import { useTranslation } from 'react-i18next';
import concatClassNames from 'utils/classNames';
import BankaccountCard from './BankaccountCard';
import classes from './BankAccountList.module.less';

/**
 * List of bank accounts of client
 * @param {object} inputProperties - The input properties for the component
 * @param {object[]} inputProperties.bankAccounts - The bank accounts to display
 * @returns {JSX.Element} List of bank accounts of client
 * @component
 */
const BankAccountList = ({ bankAccounts }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });

  if (!bankAccounts) return null;

  return (
    <div className={classes.bankaccount_list}>
      <h3>{t('label.bankAccounts')}</h3>
      <div className={concatClassNames(classes.bankaccount_list_list, 'custom-scrollbar')}>
        {bankAccounts.length === 0 && <p>{t('noBankAccounts')}</p>}
        {bankAccounts.map((bankaccount) => (
          <BankaccountCard key={bankaccount._id} bankaccount={bankaccount} />
        ))}
      </div>
    </div>
  );
};

export default BankAccountList;
