import * as Yup from 'yup';
import { find, forEach, merge, set } from 'lodash';
import i18n from 'i18n';
import NodeCache from 'node-cache';
import { allInputFieldsInCategories, responseToFormik } from 'components/user/shoppingCart/utils';
import { CalculationMode } from 'constants/item';
import { ShowDigits } from 'constants/shoppingCart';
import { InputFieldType, InternalInputFieldType } from 'constants/inputField';
import moment from 'moment';
import { formatDate } from 'utils/date';
import { Mentions, RichText, DIGITAL_SIGNATURE_STATE } from '@JavaScriptSuperstars/kanzleipilot-shared';
import dot from 'dot-object';
import { SIGNABLE } from 'components/user/shoppingCart/SignatureCard';

window.i18n = i18n;
const schemaCache = new NodeCache({ stdTTL: 1, checkperiod: 2 });
export const isItemRecursive = (item) => item.recursiveFormulaInfo?.usedItemIdsInFormula?.includes(item._id);
export const getBaseShapeForShoppingCartSchema = (documentTemplates, tenantDigitalSignatureEnabled) => {
  const shape = {
    showPrices: Yup.boolean().label('Show Prices'),
    companyId: Yup.string().nullable().required().label(i18n.t('user.ShoppingCart.inputs.companyId.label')),
    contacts: Yup.array().of(Yup.object()).min(1).required().label(i18n.t('user.ShoppingCart.inputs.contacts.label')),
    companyTypeId: Yup.string().required().label(i18n.t('user.ShoppingCart.inputs.companyTypeId.label')),
    meetingAt: Yup.string().nullable().required().label(i18n.t('user.ShoppingCart.inputs.meetingAt.errorLabel')),
    startOfContract: Yup.string().nullable().required().label(i18n.t('user.ShoppingCart.inputs.startOfContract.label')),
    tenantSignees: Yup.array()
      .of(Yup.string())
      .when('documentTemplates', {
        is: (selectedDocumentTemplates) => {
          const enableTenantSignees =
            tenantDigitalSignatureEnabled &&
            documentTemplates &&
            selectedDocumentTemplates.some((selectedTemplate) => {
              const documentTemplateWithProps = documentTemplates.find(
                (template) => template._id === JSON.parse(selectedTemplate)._id,
              );
              return (
                documentTemplateWithProps.isSignable === SIGNABLE.BOTH ||
                documentTemplateWithProps.isSignable === SIGNABLE.ONLY_TENANT
              );
            });
          return enableTenantSignees;
        },
        then: (schema) =>
          schema.when('enableDigitalSignature', {
            is: (enableDigitalSignature) => enableDigitalSignature,
            then: (tenantSigneeSchema) => {
              return tenantSigneeSchema
                .min(1, i18n.t('user.ShoppingCart.SignatureCard.tenantSigneesSelect.error'))
                .required(i18n.t('user.ShoppingCart.SignatureCard.tenantSigneesSelect.error'));
            },
          }),
      }),
    companySignees: Yup.array()
      .of(Yup.string())
      .when('documentTemplates', {
        is: (selectedDocumentTemplates) => {
          const enableCompanySignees =
            tenantDigitalSignatureEnabled &&
            documentTemplates &&
            selectedDocumentTemplates.some((selectedTemplate) => {
              const documentTemplateWithProps = documentTemplates.find(
                (template) => template._id === JSON.parse(selectedTemplate)._id,
              );
              return (
                documentTemplateWithProps.isSignable === SIGNABLE.BOTH ||
                documentTemplateWithProps.isSignable === SIGNABLE.ONLY_COMPANY
              );
            });
          return enableCompanySignees;
        },
        then: (schema) => {
          return schema.when('enableDigitalSignature', {
            is: (enableDigitalSignature) => enableDigitalSignature,
            then: (schemaForEmailTest) => {
              return schemaForEmailTest
                .min(1, i18n.t('user.ShoppingCart.SignatureCard.companySigneesSelect.error'))
                .required(i18n.t('user.ShoppingCart.SignatureCard.companySigneesSelect.error'))
                .test(
                  'has-email',
                  (d) => `${d.path} missingEmailAddress`,
                  (value) => {
                    return (
                      value &&
                      value.every((contact) => {
                        const contactObject = JSON.parse(contact);
                        return contactObject && contactObject.email && contactObject.email !== '';
                      })
                    );
                  },
                );
            },
          });
        },
      }),
  };
  return shape;
};

const getSchema = ({
  affectedInputFieldsMap,
  requiredStartOfServiceCategories,
  recursiveItems,
  referencedItemIdsByItems,
  documentTemplates,
  tenantDigitalSignatureEnabled,
}) => {
  const shape = {
    ...getBaseShapeForShoppingCartSchema(documentTemplates, tenantDigitalSignatureEnabled),
    ...merge(
      ...requiredStartOfServiceCategories.map((name) => ({
        [name]: Yup.string()
          .nullable()
          .required()
          .label(i18n.t('user.ShoppingCart.Category.ValidationLabels.startOfService')),
      })),
    ),
    ...merge(
      ...referencedItemIdsByItems.map((itemId) => ({
        [itemId]: Yup.boolean()
          .nullable()
          .test(
            'item-needs-to-be-selected',
            i18n.t('user.ShoppingCart.Category.Item.referenceError.needsToBeSelected'),
            (value) => !!value,
          ),
      })),
    ),
    ...merge(
      ...affectedInputFieldsMap.map(({ _id, name, type }) => {
        const isCombo = type === 'combo';
        const schema = Yup[isCombo ? 'string' : 'number']()
          .typeError(i18n.t('user.ShoppingCart.Category.InputField.mustBeANumberMessage', { name }))
          .label(name)
          .nullable()
          .required();
        return { [_id]: schema };
      }),
    ),
    ...merge(
      ...recursiveItems.map((item) => ({
        [item._id]: Yup.boolean().test('fieldIncludesRecursiveField', i18n.t('common.Item.recursive'), () => false),
      })),
    ),
  };
  // console.log(shape);
  const schema = Yup.object().shape(shape);
  return schema;
};
const cachedSchema = (key) => {
  const cacheKey = JSON.stringify(key);
  const cached = schemaCache.get(cacheKey);
  if (cached) return cached;
  const generated = getSchema(key);
  schemaCache.set(cacheKey, generated);
  return generated;
};
const getFilterCategories = (categories = [], companyTypeId) => {
  return categories.filter((category) => category.companyTypeIds.includes(companyTypeId));
};

const getIsChecked =
  ({ values }) =>
  (_id) =>
    values[_id] || values[`${_id}_bookmark`];

const isNotActualCost = (calculationMode) => calculationMode !== CalculationMode.ON_ACTUAL_COST;

const getValidationSchema = ({
  values,
  categories: _categories,
  touched,
  setFieldsTouched,
  documentTemplates,
  tenantDigitalSignatureEnabled,
}) => {
  const isChecked = getIsChecked({ values });
  const categories = getFilterCategories(_categories, values.companyTypeId);
  const allItems = categories.map((category) => category.items).flat();
  const setFieldsTouchedArray = [];
  const affectedInputFieldsMap = categories
    .map((c) =>
      c.items.map((i) => {
        if (isChecked(i._id) && isNotActualCost(i.calculationMode)) {
          return i.allAffectedInputFields;
        }
        return [];
      }),
    )
    .flat()
    .flat();

  affectedInputFieldsMap.forEach((field) => touched[field._id] || setFieldsTouchedArray.push(field._id));

  const requiredStartOfServiceCategories = categories
    .filter((c) =>
      find(c.items, (i) => isChecked(i._id) && (i.paymentInterval === 'monthly' || i.paymentInterval === 'yearly')),
    )
    .map((c) => `${c._id}_startOfService`);
  const referencedItemIdsByItems = allItems
    .map(
      (item) =>
        isNotActualCost(item.calculationMode) &&
        isChecked(item._id) &&
        item.recursiveFormulaInfo.formulaRequiredItemIds,
    )
    .filter((usedItemIdsInFormula) => usedItemIdsInFormula?.length)
    .flat();
  referencedItemIdsByItems.forEach((itemId) => touched[itemId] || setFieldsTouchedArray.push(itemId));

  const recursiveItems = allItems.filter(
    (item) => isNotActualCost(item.calculationMode) && isChecked(item._id) && isItemRecursive(item),
  );
  recursiveItems.forEach((field) => touched[field._id] || setFieldsTouchedArray.push(field._id));
  requiredStartOfServiceCategories.forEach((field) => touched[field] || setFieldsTouchedArray.push(field));
  setFieldsTouchedArray.length && setFieldsTouched(setFieldsTouchedArray);

  return cachedSchema({
    affectedInputFieldsMap,
    requiredStartOfServiceCategories,
    recursiveItems,
    referencedItemIdsByItems,
    documentTemplates,
    tenantDigitalSignatureEnabled,
  });
};

export const validationSchema = ({
  formikRef,
  values,
  categories,
  documentTemplates,
  tenantDigitalSignatureEnabled,
}) => {
  if (!formikRef?.current) return Yup.object();
  const { touched, setFormikState, validateForm } = formikRef.current;
  const setFieldsTouched = (names) => {
    window.setTimeout(() => {
      setFormikState((state) => ({
        ...state,
        touched: { ...state.touched, ...merge(...names.map((f) => ({ [f]: true }))) },
      }));
      window.setTimeout(() => validateForm(), 0);
    }, 0);
  };

  return getValidationSchema({
    values,
    categories,
    touched,
    setFieldsTouched,
    documentTemplates,
    tenantDigitalSignatureEnabled,
  });
};
export const startOfServiceFields = ({ categories }) =>
  categories
    .filter((c) => find(c.items, (i) => i.paymentInterval === 'monthly' || i.paymentInterval === 'yearly'))
    .map((c) => `${c._id}_startOfService`);

export const hasCategoryStartOfService = (category) => {
  return find(category.items, (i) => i.paymentInterval === 'monthly' || i.paymentInterval === 'yearly');
};
export const getDefaultValueFromInternalInputField = (internalInputField, { formatter = (e) => e } = {}) =>
  formatter(
    internalInputField.defaultType === InternalInputFieldType.INPUT
      ? internalInputField.defaultValue
      : parseFloat(internalInputField.variable.value),
  );
export const getInitialValues = ({ categories, dbValues, preferences, initialValues, prevValues, isIndependent }) => {
  let values = merge(
    ...categories
      .map((category) =>
        [
          allInputFieldsInCategories([category]).map((field) => ({
            [field._id]:
              field.type === InputFieldType.INTERNAL ? getDefaultValueFromInternalInputField(field) : undefined,
          })),
          category.items.map((item) => ({
            [item._id]: false,
            [`${item._id}_bookmark`]: false,
          })),
          { [`${category._id}_discount`]: undefined },
          ...(hasCategoryStartOfService(category) ? [{ [`${category._id}_startOfService`]: undefined }] : []),
        ].flat(),
      )
      .flat(),
    {
      name: i18n.t('user.ShoppingCart.inputs.name.default', { date: formatDate(Date()) }),
      startOfContract: null,
      meetingAt: moment(new Date()).format('YYYY-MM-DD'),
      companyId: null,
      companySignees:
        dbValues?.cart?.signatureData?.companySignees?.map((signee) =>
          JSON.stringify({ _id: signee.id, email: signee.email }),
        ) || [],
      contacts: [],
      enableDigitalSignature: dbValues?.cart.digitalSignatureState
        ? dbValues?.cart?.digitalSignatureState !== DIGITAL_SIGNATURE_STATE.NO_DIGITAL_SIGNATURE
        : true,
      signature: preferences.signature ?? '',
      showPrices: preferences.showPrices ?? true,
      showDigits: preferences.showDigits ?? ShowDigits.avoid,
      feeType: preferences.feeType ?? 'standard',
      showDiscounts: preferences.showDiscounts ?? true,
      tenantSignees: dbValues?.cart?.signatureData?.tenantSignees?.map((signee) => signee.id) || [],
      debugMode: false,
      documentTemplates: preferences.documentTemplates ?? [],
      monthlyPaymentDecision:
        dbValues?.cart?.monthlyPaymentDecision ||
        preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.defaultOption,
      ...initialValues,
      emailTemplateId: preferences.emailTemplateId,
      monthlyPaymentMode: preferences?.monthlyPaymentPreferences?.monthlyPaymentMode,
      monthlyPaymentQuestionText: preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.questionText,
      monthlyPaymentTitle: preferences?.monthlyPaymentPreferences?.monthlyPaymentTitle,
      monthlyPaymentHelpText: preferences?.monthlyPaymentPreferences?.shoppingCartSettings?.helpText,
      sepaCustomSettings: !!(
        (dbValues?.cart?.signatureData?.sepaMode !== undefined &&
          dbValues?.cart?.signatureData?.sepaMode !== preferences?.digitalSignaturePreferences?.sepaMode) ||
        (dbValues?.cart?.signatureData?.sepaEnabled !== undefined &&
          dbValues?.cart?.signatureData?.sepaEnabled !== preferences?.digitalSignaturePreferences?.sepaEnabled)
      ),
      sepaEnabled:
        dbValues?.cart?.signatureData?.sepaEnabled !== undefined
          ? dbValues?.cart?.signatureData?.sepaEnabled
          : preferences?.digitalSignaturePreferences?.sepaEnabled,
      sepaMode:
        dbValues?.cart?.signatureData?.sepaMode !== undefined
          ? dbValues?.cart?.signatureData?.sepaMode
          : preferences?.digitalSignaturePreferences?.sepaMode,
    },
  );
  const allInputFields = allInputFieldsInCategories(categories);

  if (dbValues) {
    values = {
      ...values,
      ...responseToFormik({
        ...dbValues,
        prevValues,
        discounts: categories.map((category) => category.discounts).flat(),
        allInputFields,
        isIndependent,
      }),
    };
    const { deletedIds } = dbValues.cart;
    if (deletedIds?.length) {
      Object.entries(dot.dot(values)).forEach(([key, value]) => {
        if (RichText.isRichTextString(value) && find(deletedIds, (_id) => value.includes(_id))) {
          set(
            values,
            key,
            Mentions.replaceMentions({
              value,
              normalizeMention: (node) => {
                if (deletedIds.includes(node._id) || deletedIds.includes(node.name))
                  return Mentions.generateDeletedMention(node);
                return node;
              },
            }),
          );
        }
      });
    }
  }
  return values;
};
export const deselectItems = ({ categories, values, companyTypeId: currentId, setFieldValueAndTouched }) => {
  forEach(categories, (category) => {
    forEach(category?.items, ({ _id, companyTypeIds }) => {
      let itemId;
      if (values[_id]) itemId = _id;
      else if (values[`${_id}_bookmark`]) itemId = `${_id}_bookmark`;
      if (!itemId) return;

      const isCategoryVisible = find(category?.companyTypeIds, (id) => currentId === id);
      const isItemVisible = find(companyTypeIds, (id) => currentId === id);
      if (!isCategoryVisible || !isItemVisible) {
        setFieldValueAndTouched(itemId, false);
      }

      if (values[`${_id}_bookmark`] && values[_id]) {
        setFieldValueAndTouched(`${_id}_bookmark`, false);
      }
    });
  });
};
