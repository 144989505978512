import CountrySelectWithLabel from 'components/common/FormComponents/Formik/CountrySelectWithLabel';
import { useTranslation } from 'react-i18next';

/**
 * Country input field for address for client form
 * @returns {JSX.Element} Country input field
 * @component
 */
const ClientAddressCountryField = () => {
  const { t } = useTranslation();
  return (
    <CountrySelectWithLabel
      name="address.country"
      label={t('ClientsListPage.addClientModal.form.address.country.label')}
      placeholder={t('ClientsListPage.addClientModal.form.address.country.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ClientAddressCountryField;
