import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { getDocumentsOfClientQuery } from 'graphql/queries';
import { RenderDateTime } from 'components/common/List/FieldRenderers';
import Badge from 'components/common/Badge';
import { InlineListLayout } from 'components/common/List';
import PrintFileActionButton from 'components/common/ActionButtons/PrintFileActionButton';
import DownloadFileActionButton from 'components/common/ActionButtons/DownloadFileActionButton';
import { ViewButton } from 'components/common/List/DefaultActionButtons';
import openViewProjectCard from 'ProjectCardManagement/ProjectCardManagement/methods/openViewProjectCard';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const generateUseFetchClientDocuments = (client) => () => {
  const { data, loading, error } = useQuery(getDocumentsOfClientQuery, { variables: { clientId: client._id } });
  return { data: data?.getDocumentsOfClient, loading, error };
};

const generateListItemConfig = (t, navigate) => ({
  rows: [
    {
      rowColumns: [
        {
          fieldName: 'name',
          fieldLabel: 'Name',
          renderValue: (value, item) => {
            return (
              <>
                <span>{value}</span>
                {item.isSigned ? <Badge text={t('badges.signed')} /> : null}
                {item.isGenerating ? <Badge text={t('badges.generating')} /> : null}
              </>
            );
          },
        },
        {
          fieldName: 'createdAt',
          fieldLabel: 'Created at',
          renderValue: RenderDateTime,
        },
      ],
      alignLastEntryRight: true,
    },
  ],
  actionButtons: [
    ViewButton(({ parentId }) => openViewProjectCard(parentId, navigate)),
    {
      component: ({ item }) => <PrintFileActionButton url={item.link} fileType="application/pdf" />,
      key: 'print',
    },
    {
      component: ({ item }) => <DownloadFileActionButton url={item.link} fileType={item.type} fileName={item.name} />,
      key: 'download',
    },
  ],
  onClick: ({ parentId }) => openViewProjectCard(parentId, navigate),
  showLabel: false,
  bordered: false,
});

/**
 * Tab for showing documents of a client as list
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.client - The client object
 * @param {string} inputProperties.client._id - The client id
 * @returns {JSX.Element} Tab with documents of a client
 * @component
 */
const DocumentsTab = ({ client }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.documentsTab' });
  const navigate = useNavigate();

  const initSort = useMemo(() => ['createdAt', 'DESC'], []);

  const useFetchClientDocuments = useMemo(() => generateUseFetchClientDocuments(client), [client]);
  const listItemConfig = useMemo(() => generateListItemConfig(t, navigate), [navigate, t]);

  return (
    <InlineListLayout
      listItemConfig={listItemConfig}
      useDataFetching={useFetchClientDocuments}
      initSort={initSort}
      bigList
    />
  );
};

export default DocumentsTab;
