import { DeleteOutlined, EditOutlined, EyeOutlined, FileAddOutlined } from '@ant-design/icons';

/**
 * Default edit action button for list item config
 * @param {(item: object) => void} onClick - Function to be called when the button is clicked
 * @returns {object} Edit button config object
 */
export const EditButton = (onClick) => ({
  icon: <EditOutlined />,
  onClick,
  key: 'edit',
});

/**
 * Default duplicate action button for list item config
 * @param {(item: object) => void} onClick - Function to be called when the button is clicked
 * @returns {object} Duplicate button config object
 */
export const DuplicateButton = (onClick) => ({
  icon: <FileAddOutlined />,
  onClick,
  key: 'duplicate',
});

/**
 * Default delete action button for list item config
 * @param {(item: object) => void} onClick - Function to be called when the button is clicked
 * @returns {object} Delete button config object
 */
export const DeleteButton = (onClick) => ({
  icon: <DeleteOutlined />,
  onClick,
  danger: true,
  key: 'delete',
});

/**
 * Default view action button for list item config
 * @param {(item: object) => void} onClick - Function to be called when the button is clicked
 * @returns {object} View button config object
 */
export const ViewButton = (onClick) => ({
  icon: <EyeOutlined />,
  onClick,
  key: 'view',
});

/**
 * Default action buttons for list item config
 * @param {(item: object) => void} onEdit - Function to be called when the edit button is clicked
 * @param {(item: object) => void} onDelete - Function to be called when the delete button is clicked
 * @returns {object[]} Default action buttons for list item
 */
export const DefaultActionButtons = (onEdit, onDelete) => [EditButton(onEdit), DeleteButton(onDelete)];
