import { Input } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

export const QuestionInput = ({ t }) => {
  return (
    <FormItem
      label={t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.question.label')}
      name="shoppingCartSettings.questionText"
      tooltip={t('admin.Setting.MonthlyPaymentPreferences.shoppingCartSettings.question.tooltip')}
    >
      <Input.TextArea name="shoppingCartSettings.questionText" autoSize />
    </FormItem>
  );
};
