import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { Form, SubmitButton, Input } from 'formik-antd';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertFromFormik, setGraphqlErrors } from 'components/common/ErrorComponent';
import toast from 'utils/toast';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { changePassword } from 'graphql/methods';
import { LockOutlined } from '@ant-design/icons';
import { resetPasswordSchema } from '../schemas';

function PasswordForm() {
  const { t } = useTranslation();
  return (
    <I18nFormik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        passwordConfirmation: '',
      }}
      validationSchema={resetPasswordSchema}
      onSubmit={async ({ currentPassword, newPassword }, formik) => {
        try {
          formik.setSubmitting(true);
          await changePassword({ currentPassword, newPassword });
          formik.resetForm();
          toast.successDefault();
        } catch (error) {
          console.error(error);
          formik.setErrors(
            setGraphqlErrors({
              error,
              errorResolver: ({ message }) => {
                if (message.includes('are incorrect'))
                  return ['currentPassword', t('user.Setting.AccountSetting.tabs.password.errors.currentPassworWrong')];
                return undefined;
              },
            }),
          );
        } finally {
          formik.setSubmitting(false);
        }
      }}
    >
      <Form layout="vertical">
        <AlertFromFormik />
        <FormItem name="currentPassword" label={t('user.Setting.AccountSetting.tabs.password.fields.currentPassword')}>
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} name="currentPassword" />
        </FormItem>
        <FormItem name="newPassword" label={t('user.Setting.AccountSetting.tabs.password.fields.newPassword')}>
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} name="newPassword" />
        </FormItem>
        <FormItem
          name="passwordConfirmation"
          label={t('user.Setting.AccountSetting.tabs.password.fields.passwordConfirmation')}
        >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} name="passwordConfirmation" />
        </FormItem>
        <SubmitButton style={{ marginTop: '16px' }}>
          {t('user.Setting.AccountSetting.tabs.password.savePassword')}
        </SubmitButton>
      </Form>
    </I18nFormik>
  );
}

export default memo(PasswordForm, equal);
