import { useTranslation } from 'react-i18next';
import { RoundedButton } from '../Button';
import Modal from './Modal';

/**
 * Footer for one button modal
 * @param {object} inputProperties - Input properties for the component
 * @param {string} inputProperties.buttonType - Button type of the footer button
 * @param {Function} inputProperties.onClick - On click function of the footer button
 * @param {string} inputProperties.buttonText - Text of the footer button
 * @returns {JSX.Element} Custom footer
 * @component
 */
const CustomFooter = ({ buttonType = 'primary', onClick, buttonText }) => {
  const { t } = useTranslation();
  return (
    <RoundedButton type={buttonType} onClick={onClick} size="large">
      {buttonText || t('common.ok')}
    </RoundedButton>
  );
};

/**
 * Modal with one button in footer
 * @param {object} inputProperties - Input properties for the component
 * @param {boolean} inputProperties.visible - Visibility of the modal
 * @param {string} inputProperties.title - Title of the modal
 * @param {React.ReactNode} inputProperties.children - Children of the modal
 * @param {string} inputProperties.size - Size of the modal ('medium' or 'wide')
 * @param {string} inputProperties.className - Class name of the modal
 * @param {string} inputProperties.buttonText - Text of the button
 * @param {Function} inputProperties.onButtonClick - On button click function
 * @param {string} inputProperties.buttonType - Type of the button
 * @param {Function} inputProperties.onCancel - On cancel function
 * @returns {JSX.Element} One button modal
 * @component
 */
const OneButtonModal = ({
  visible,
  title,
  children,
  size,
  className = '',
  buttonText,
  onButtonClick,
  buttonType,
  onCancel,
  ...props
}) => {
  return (
    <Modal
      visible={visible}
      title={title}
      className={className}
      size={size}
      footer={<CustomFooter buttonText={buttonText} onClick={onButtonClick || onCancel} buttonType={buttonType} />}
      onCancel={onCancel}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default OneButtonModal;
