import { useTranslation } from 'react-i18next';
import { Select as SelectFormik } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

const Step3 = ({ currentStep }) => {
  const { t } = useTranslation();
  const typesKeys = [
    { value: 'company', label: t('user.ShoppingCart.ContactData.company.inputs.type.company') },
    { value: 'individual', label: t('user.ShoppingCart.ContactData.company.inputs.type.individual') },
  ];

  if (currentStep !== 2) return null;
  return (
    <FormItem name="defaultCompanyType" label={t(`admin.Setting.ImportCompanies.fields.defaultCompanyType`)}>
      <SelectFormik
        name="defaultCompanyType"
        showSearch
        style={{ width: '100%' }}
        placeholder={t('admin.Setting.ImportCompanies.table.columnSource.placeholder')}
      >
        {typesKeys.map((type) => (
          <SelectFormik.Option value={type.value} key={type.value}>
            {type.label}
          </SelectFormik.Option>
        ))}
      </SelectFormik>
    </FormItem>
  );
};

export default Step3;
