import { gql } from '@apollo/client';
import userContactFragment from './userContactFragment';
import userCompanyImportFieldsFragment from './userCompanyImportFieldsFragment';
import userCompanyAddressFragment from './userCompanyAddressFragment';

const userCompanyFragment = gql`
  fragment userCompanyFragment on Company {
    _id
    type
    name
    identifier
    contacts {
      ...userContactFragment
    }
    bankAccounts {
      _id
      iban
      bic
      accountOwnerName
      hasSepaMandate
      bankData {
        bankName
        bankCity
      }
      sepaDocumentLink
    }
    address {
      ...userCompanyAddressFragment
    }
    phoneNumber
    importFields {
      ...userCompanyImportFieldsFragment
    }
  }
  ${userCompanyImportFieldsFragment}
  ${userCompanyAddressFragment}
  ${userContactFragment}
`;

export default userCompanyFragment;
