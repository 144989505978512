import FormItem from './FormItem';
import Select from './Select';

/**
 * Select field as form item with label, tooltip, ... with formik support
 * @param {object} inputProperties - Input properties of the component
 * @param {string} inputProperties.name - Name of the input field
 * @param {string} inputProperties.label - Label of the input field
 * @param {string} inputProperties.placeholder - Placeholder of the input field
 * @param {React.ReactNode} inputProperties.children - Children of the component (Options)
 * @param {string} inputProperties.tooltip - Tooltip of the input field
 * @param {object} inputProperties.inputProps - Additional input properties See ant design documentation for more information
 * @param {object} inputProperties.formItemProps - Additional form item properties See ant design documentation for more information
 * @returns {JSX.Element} Select field with label
 * @component
 */
const SelectWithLabel = ({ label, name, placeholder, children, tooltip, inputProps, formItemProps }) => {
  return (
    <FormItem name={name} label={label} tooltip={tooltip} {...formItemProps}>
      <Select name={name} placeholder={placeholder} {...inputProps}>
        {children}
      </Select>
    </FormItem>
  );
};

SelectWithLabel.Option = Select.Option;

export default SelectWithLabel;
