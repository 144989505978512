import { useTranslation } from 'react-i18next';
import PageContainer from 'components/layout/PageContainer';
import ClientsList from '../components/ClientsList';
import AddNewClientButton from '../components/AddNewClientButton';
import ClientManagementHelperWidget from '../components/ClientManagementHelperWidget';

/**
 * Page for managing clients of a tenant
 * @returns {JSX.Element} The ClientsListPage component
 * @component
 */
const ClientsListPage = () => {
  const { t } = useTranslation();

  const actionButtons = [
    {
      key: 'addClient',
      component: <AddNewClientButton />,
    },
  ];

  return (
    <PageContainer
      title={t('ClientsListPage.title')}
      actionButtons={actionButtons}
      left={<ClientManagementHelperWidget />}
    >
      <ClientsList />
    </PageContainer>
  );
};

export default ClientsListPage;
