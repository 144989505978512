import { Select } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { ErrorMessage } from 'formik';
import classes from './SelectInputField.module.less';

const { Option } = Select;

/**
 * Renders a multi-select input
 * @param {Object} inputParameters - Input properties of the component
 * @param {string} inputParameters.name - Name of the input field (used in the form data)
 * @param {string} inputParameters.placeholder - Placeholder text for the input field
 * @param {Object[]} inputParameters.options - List of options for the select field
 * @param {string} inputParameters.options.label - Label of the option
 * @param {string} inputParameters.options.value - Value of the option
 * @param {string} inputParameters.tooltip - Optional: Tooltip text to provide additional information about the input field
 * @param {string} inputParameters.label - Label of the input field
 * @param {string} inputParameters.className - Optional: Class name of the wrapped container
 * @param {boolean} inputParameters.enableErrorHandling - Optional: Enable error handling for the input field
 * @param {React.Component} inputParameters.errorComponent - Optional: Error component to display when error occurs
 * @param {string} inputParameters.mode - Optional: Mode of the select field (tags/multiple)
 * @param {string} inputParameters.size - Optional: Size of the select field (small/large/middle)
 * @returns {JSX.Element} multi-select input field
 * @component
 */
const SelectInputField = ({
  name,
  placeholder,
  options,
  tooltip,
  label,
  className,
  enableErrorHandling,
  errorComponent,
  mode,
  size,
}) => {
  const optionsComponents = options?.map((option) => {
    return (
      <Option label={option.label} value={option.value} key={option.value}>
        <div className="demo-option-label-item">{option.label}</div>
      </Option>
    );
  });
  return (
    <div className={className}>
      <FormItem className="hide-form-item-colon form-item-no-margin" name={name} label={label} tooltip={tooltip}>
        <div className={classes.selectInputField}>
          <Select
            name={name}
            mode={mode || 'multiple'}
            allowClear
            placeholder={placeholder}
            optionLabelProp="label"
            disabled={!options}
            size={size || 'middle'}
          >
            {optionsComponents}
          </Select>
        </div>
        {enableErrorHandling && (
          <div className={classes.errorAlert}>
            <ErrorMessage component={errorComponent} name={name} />
          </div>
        )}
      </FormItem>
    </div>
  );
};

export default SelectInputField;
