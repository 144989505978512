import { useTranslation } from 'react-i18next';
import MoreInfoWidget from 'components/common/MoreInfoWidget';

/**
 * Helper widget for the ClientViewPage
 * @component
 */
const ClientViewPageHelperWidget = () => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t('ClientViewPage.help.buttonLabel')}
      title={t('ClientViewPage.help.title')}
      helpText={t('ClientViewPage.help.helpText')}
      videoCaption={t('ClientViewPage.help.videoCaption')}
      videoUrl={t('ClientViewPage.help.videoUrl')}
      imageUrl={t('ClientViewPage.help.imageUrl')}
    />
  );
};

export default ClientViewPageHelperWidget;
