import { PhoneController } from 'components/common/PhoneInputFormik';

/**
 * Renders phone number, when it is more then the country code
 * @param {string} phoneNumber - Phone number
 * @returns {string} Phone number or null
 */
const getPhoneNumber = (phoneNumber) => {
  return PhoneController.parse(phoneNumber).phone ? `+${phoneNumber}` : null;
};

export default getPhoneNumber;
