import { useTranslation } from 'react-i18next';
import { EditFilled } from '@ant-design/icons';
import PageContainerActionButton from 'components/common/PageContainerActionButton';
import EditModalHandler from './EditModalHandler';

/**
 * Page button for editing project cards
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.disabled - Whether the button is disabled
 * @param {boolean} inputProperties.loading - Whether the button is loading
 * @param {Function} inputProperties.onClick - On click handler
 * @returns {JSX.Element} Page button for editing project cards
 * @component
 */
const EditButton = ({ disabled, loading, onClick, className }) => {
  const { t } = useTranslation();

  return (
    <PageContainerActionButton
      icon={<EditFilled />}
      className={className}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {t('viewer.ShoppingCartView.editShoppingCart')}
    </PageContainerActionButton>
  );
};

/**
 * Edit button for page for start the edit process for a project card
 * @param {Object} inputParameter
 * @param {string} inputParameter._id - Database id of shopping cart object, which should be edit
 * @param {string} inputParameter.className - Css classname for button style
 * @param {boolean} inputParameter.disabled - sets disabled state of the button
 * @returns {JSX.Element} Edit page button for project cards with modal handler
 * @component
 */

const EditProjectCardButton = ({ _id, className, disabled }) => {
  return <EditModalHandler editButton={EditButton} _id={_id} disabled={disabled} className={className} />;
};

export default EditProjectCardButton;
