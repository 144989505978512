import { useEffect, useRef, useCallback } from 'react';
import { Button } from 'antd';
import { ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import cn from 'classnames';
import * as Yup from 'yup';
import SigningInfoPagesLayout from 'components/layouts/SigningInfoPagesLayout';
import { Input, Form, FormItem } from 'formik-antd';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import useLoginSignee from 'hooks/signing/useLoginSignee';
import { setGraphqlErrors } from 'components/common/ErrorComponent';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import useLoginInternalSignee from 'hooks/signing/useLoginInternalSignee';
import routePaths from 'router/route-paths';
import CustomWarningAlert from 'components/signing/CustomWarningAlert';
import classes from './StartSigning.module.less';

const PasswordSchema = (labelText) => {
  return Yup.object().shape({
    password: Yup.string().required().min(1).label(labelText.password),
  });
};

const checkToken = (token) => {
  if (!token) return null;
  try {
    const decodedToken = jwtDecode(token);
    if (!decodedToken) return null;
    const { expires } = decodedToken;
    if (expires < new Date(Date.now())) return null;
    return jwtDecode(token);
  } catch {
    return null;
  }
};

const errorHandlingForStartButton = (error, navigate) => {
  const { message } = error;
  if (message.includes('signed')) {
    navigate(routePaths.signingAlreadySigned);
    return false;
  }
  if (!message.includes('passwordWrong') && !message.includes('notYourLink')) {
    navigate(routePaths.signingUnvalid);
    return false;
  }
  return true;
};

/**
 * PasswordMode component is the content for the card, if the password mode is enabled on the start signing page
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Function} inputParameters.translation - Translation function
 * @param {React.Ref} inputParameters.innerRef - Reference for the formik form
 * @param {String} inputParameters.token - Link token of the signee
 * @param {Function} inputParameters.navigate - Navigation function to nvaigate to an other route
 * @param {Function} inputParameters.loginSignee - Function to login the signee
 * @returns {JSX.Element} content for the card on the start signing page
 * @components
 */
const PasswordMode = ({ translation, innerRef, token, navigate, loginSignee }) => {
  const onLoginSignee = async ({ password }, formik) => {
    try {
      formik.setSubmitting(true);
      await loginSignee({ linkToken: token, password });
      formik.resetForm();
      navigate(routePaths.signing, { replace: true });
    } catch (error) {
      const formikError = errorHandlingForStartButton(error, navigate);
      if (!formikError) return;
      formik.setErrors(
        setGraphqlErrors({
          error,
          // eslint-disable-next-line no-shadow
          errorResolver: ({ message }) => {
            if (message.includes('passwordWrong')) {
              return ['password', translation('signing.infoPages.startPage.errors.passwordWrong')];
            }
            return undefined;
          },
          showErrorsInFormForValues: ['password'],
        }),
      );
    }
  };

  return (
    <>
      <CustomWarningAlert
        message={translation('signing.infoPages.startPage.passwordAlert.message')}
        description={translation('signing.infoPages.startPage.passwordAlert.description')}
        className={classes.alert}
        icon={<ExclamationCircleOutlined style={{ fontSize: '21px' }} />}
      />
      <I18nFormik
        initialValues={{ password: '' }}
        onSubmit={onLoginSignee}
        innerRef={innerRef}
        submitForm
        validationSchema={() => PasswordSchema({ password: translation('signing.infoPages.startPage.password.label') })}
      >
        <Form className={classes.form}>
          <FormItem name="password" className={classes.password}>
            <Input.Password
              placeholder={translation('signing.infoPages.startPage.password.placeholderText')}
              name="password"
              className={cn(classes.password_input, 'auth-input')}
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </FormItem>
        </Form>
      </I18nFormik>
    </>
  );
};

/**
 * StartSigning component is the page component of the start signing page for the signing process
 * @returns {JSX.Element} start page for the signing process
 * @component
 */
const StartSigning = () => {
  const { token } = useParams();
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const decodedToken = checkToken(token);
  const passwordFormRef = useRef();
  const [loginSignee] = useLoginSignee();
  const [loginSigneeAsUser, loading] = useLoginInternalSignee();
  const [me] = useCurrentUser();

  const automaticLoginOfLoggedInUser = useCallback(async () => {
    try {
      await loginSigneeAsUser({ signingProcessId: decodedToken.id });
      navigate(routePaths.signing, { replace: true });
    } catch (error) {
      errorHandlingForStartButton(error, navigate);
    }
  }, [decodedToken, loginSigneeAsUser, navigate]);

  useEffect(() => {
    if (decodedToken === null) navigate(routePaths.signingUnvalid);
    else if (me && decodedToken.signeeEmail && me.email === decodedToken.signeeEmail && !loading) {
      automaticLoginOfLoggedInUser();
    }
  }, [me, automaticLoginOfLoggedInUser, decodedToken, navigate, loading]);

  if (decodedToken === null) {
    return null;
  }

  const onStartClicked = async () => {
    if (passwordFormRef?.current?.handleSubmit) {
      passwordFormRef.current.handleSubmit();
      return;
    }
    try {
      await loginSignee({ linkToken: token });
      navigate(routePaths.signing, { replace: true });
    } catch (error) {
      errorHandlingForStartButton(error, navigate);
    }
  };

  return (
    <SigningInfoPagesLayout theme={decodedToken.theme} contentAreaClassName={classes.contentArea}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.infoPageHeading}>{translation('signing.infoPages.startPage.heading')}</h1>
        {decodedToken.needsPassword ? (
          <PasswordMode
            translation={translation}
            innerRef={passwordFormRef}
            token={token}
            navigate={navigate}
            loginSignee={loginSignee}
          />
        ) : null}
      </div>
      <div className={classes.buttonWrapper}>
        <Button type="primary" className={classes.button} onClick={onStartClicked}>
          {translation('signing.infoPages.startPage.startButton')}
        </Button>
      </div>
    </SigningInfoPagesLayout>
  );
};

export default StartSigning;
