import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/common/FormComponents/Formik/InputWithLabel';

/**
 * Input field for address street of client
 * @returns {JSX.Element} Street input field
 */
const ClientStreetField = () => {
  const { t } = useTranslation();
  return (
    <InputWithLabel
      name="address.street"
      label={t('ClientsListPage.addClientModal.form.address.street.label')}
      placeholder={t('ClientsListPage.addClientModal.form.address.street.placeholder')}
      inputProps={{ autoComplete: 'off' }}
    />
  );
};

export default ClientStreetField;
