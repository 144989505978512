import { InputNumber } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import classes from './DefaultLinkValidityTimeInput.module.less';

const DefaultLinkValidityTimeInput = ({ t }) => {
  return (
    <FormItem
      name="defaultLinkValidityTime"
      label={t('admin.Setting.DigitalSignaturePreferences.generalSettings.defaultLinkValidityTime.label')}
      tooltip={t('admin.Setting.DigitalSignaturePreferences.generalSettings.defaultLinkValidityTime.tooltip')}
    >
      <InputNumber
        name="defaultLinkValidityTime"
        addonAfter={t('admin.Setting.DigitalSignaturePreferences.generalSettings.defaultLinkValidityTime.unit')}
        className={classes.input}
        min={1}
        controls={false}
      />
    </FormItem>
  );
};

export default DefaultLinkValidityTimeInput;
