import Card from 'components/common/CardV2';
import Timeline from 'components/common/Timeline';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import useClientHistory from 'ProjectCardManagement/ClientManagement/hooks/useClientHistory';
import useLazyListRendering from 'hooks/common/useLazyListRendering';
import TimelineItem from './TimelineItem';

/**
 * Shows card with timeline of client history
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.client - The client object
 * @param {string} inputProperties.client._id - The client ID
 * @returns {JSX.Element} Card with timeline
 * @component
 */
const TimelineCard = ({ client }) => {
  const { history, loading, error } = useClientHistory(client._id);
  const { mappedData, loadMoreRef } = useLazyListRendering(
    history,
    (item) => <TimelineItem key={item._id} action={item} />,
    30,
  );
  return (
    <Card>
      <GraphQLLoadingWrapper data={mappedData} loading={loading} error={error}>
        <Timeline>{mappedData}</Timeline>
        <div ref={loadMoreRef} style={{ height: '1px' }} />
      </GraphQLLoadingWrapper>
    </Card>
  );
};

export default TimelineCard;
