import { Badge, Typography } from 'antd';
import i18n from 'i18n';
import { DownloadAll } from './actions/DownloadAll';
import { DownloadOne } from './actions/DownloadOne';
import { OpenDocumentPreview } from './actions/OpenDocumentPreview';
import { PrintDocument } from './actions/PrintDocument';
import classes from './DocumentsCard.module.less';

const { Text } = Typography;

export const columns = ({
  documents,
  shoppingCartId,
  generatedFiles,
  addGeneratedPdf,
  isAllDocumentsGenerated,
  companyName,
}) => [
  {
    title: i18n.t('user.PrintShoppingCartPage.fields.name'),
    key: 'name',
    render: (document) => {
      return (
        <div className={classes.nameColumnItem}>
          <span>{document.name}</span>
          {document.isSigned && (
            <Badge count={i18n.t('user.PrintShoppingCartPage.signed')} className={classes.signedBadge} />
          )}
        </div>
      );
    },
  },
  {
    title: i18n.t('user.PrintShoppingCartPage.fields.actions'),
    render: (document) => (
      <div className={classes.actions}>
        {document._id === 'all' ? (
          <DownloadAll
            documents={documents}
            generatedFiles={generatedFiles}
            addGeneratedPdf={addGeneratedPdf}
            isAllDocumentsGenerated={isAllDocumentsGenerated}
            companyName={companyName}
          />
        ) : (
          <>
            {document.isGenerating ? (
              <OpenDocumentPreview
                documentTemplate={{
                  _id: document.documentTemplateId,
                  initializationConfigDate: document.initializationConfigDate,
                }}
                shoppingCartId={shoppingCartId}
              />
            ) : null}
            {document.size ? (
              <PrintDocument document={document} generatedFiles={generatedFiles} addGeneratedPdf={addGeneratedPdf} />
            ) : null}
            {!document.isGenerating && document.size ? (
              <DownloadOne document={document} generatedFiles={generatedFiles} addGeneratedPdf={addGeneratedPdf} />
            ) : null}
            {!document.isGenerating && !document.size ? (
              <Text type="danger">{i18n.t('viewer.ShoppingCartView.CreatedDocuments.error')}</Text>
            ) : null}
          </>
        )}
      </div>
    ),
    key: 'actions',
  },
];
