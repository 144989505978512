import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal/Modal';
import AddOrEditClientForm from './AddClientModal/AddOrEditClientForm';
import ClientNumberField from './AddClientModal/ClientNumberField';
import ClientTypeSelect from './AddClientModal/ClientTypeSelect';
import ClientNameField from './AddClientModal/ClientNameField';
import CompanyFields from './AddClientModal/CompanyFields';
import ClientCommericalObject from './AddClientModal/ClientCommercialObjectField';
import ClientAddressFields from './AddClientModal/ClientAddressFields';

import classes from './AddClientModal.module.less';

/**
 * Modal with form for adding or editing a new client to a tenant instance
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.visible - Visibility of the modal
 * @param {Function} inputProperties.closeModal - Function to close the modal
 * @returns {JSX.Element} Add client modal
 * @component
 */
const AddOrEditClientModal = ({ visible, closeModal, clientId }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef();
  const errorRef = useRef();

  return (
    <Modal
      visible={visible}
      className={classes.Modal}
      title={clientId ? t('ClientsListPage.addClientModal.editTitle') : t('ClientsListPage.addClientModal.title')}
      okText={t('ClientsListPage.addClientModal.okButton')}
      onCancel={() => {
        if (submitting) return;
        if (formikRef.current) {
          errorRef.current.resetError();
          formikRef.current.resetForm();
        }
        closeModal();
      }}
      onOk={async () => {
        if (!formikRef.current) {
          return;
        }
        setSubmitting(true);
        try {
          await formikRef.current.submitForm();
          if (!formikRef.current.isValid) {
            setSubmitting(false);
            return;
          }
          errorRef.current.resetError();
          formikRef.current.resetForm();
          closeModal();
        } catch (err) {
          // not empty
        }
      }}
      confirmLoading={submitting}
    >
      <AddOrEditClientForm formikRef={formikRef} setSubmitting={setSubmitting} errorRef={errorRef} clientId={clientId}>
        <ClientNumberField />
        <ClientTypeSelect />
        <ClientNameField />
        <CompanyFields>
          <ClientCommericalObject />
        </CompanyFields>
        <ClientAddressFields />
      </AddOrEditClientForm>
    </Modal>
  );
};

export default AddOrEditClientModal;
