import { useMemo } from 'react';
import { Typography } from 'antd';
import { Select } from 'formik-antd';
import { useCachedQuery } from 'graphql/utils';
import { adminEmailTemplatesQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { useFormikContext } from 'formik';

const { Option } = Select;
const { Text } = Typography;

/**
 * Email template select field for formik forms to select an email template of the tenant instance
 * @param {Object} inputParameters - Input parameters for the component
 * @param {String|JSX.Element} inputParameters.label - Label for the select component
 * @param {String} inputParameters.fieldName - Fieldname for formik form
 * @param {String} inputParamters.placeholder - Placeholder text, shown if select is empty
 * @param {String} inputParameters.tooltip - Tooltip, shown next to the label to explain the property
 * @param {Boolean} inputParameters.isLibrary - Should the email select be in common library mode (only common library templates are selectable in common library mode)
 * @param {Boolean} inputParameters.errorMessageEnabled - Should the select show formik errors for the field
 * @returns {JSX.Element} email template select field
 * @component
 */
const EmailTemplateSelect = ({ label, fieldName, placeholder, tooltip, isLibrary = false, errorMessageEnabled }) => {
  const { errors } = useFormikContext();
  const { data, loading } = useCachedQuery(adminEmailTemplatesQuery, {
    variables: { isLibrary },
  });
  const emailTemplates = useMemo(() => grabFirstGQLDataResult(data), [data]);
  return (
    <FormItem name={fieldName} label={label} tooltip={tooltip}>
      <Select name={fieldName} loading={loading} disabled={loading} placeholder={placeholder} allowClear>
        {loading ||
          emailTemplates?.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
      </Select>
      {errorMessageEnabled ? <Text style={{ color: 'red' }}>{errors[fieldName] ? errors[fieldName] : ''}</Text> : null}
    </FormItem>
  );
};

export default EmailTemplateSelect;
