import i18n from 'i18n';
import en from 'i18n-iso-countries/langs/en.json';
import de from 'i18n-iso-countries/langs/de.json';
import countries, { getName } from 'i18n-iso-countries';

countries.registerLocale(en);
countries.registerLocale(de);

/**
 * Get the name of country by its iso code
 * @param {string} isoCodeOfCountry - The iso code of the country
 * @returns {string} name of the country
 */
const getCountryName = (isoCodeOfCountry) => {
  const countryName = getName(isoCodeOfCountry, i18n.language, { select: 'official' });
  return countryName;
};

export default getCountryName;
