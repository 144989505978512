import { PrinterOutlined } from '@ant-design/icons';
import usePrintFile from 'hooks/signing/usePrintFile';
import ActionButton from './ActionButton';

/**
 * Action button for lists and cards to open print view of browser for a file
 * @param {object} inputProperties - The input properties for the component
 * @param {string} inputProperties.url - The url of the file to print
 * @param {string} inputProperties.fileType - The type of the file
 * @returns {JSX.Element} Button for printing a file
 * @component
 */
const PrintFileActionButton = ({ url, fileType }) => {
  const { printFile } = usePrintFile(url, fileType);
  return <ActionButton onClick={() => printFile()} icon={<PrinterOutlined />} />;
};

export default PrintFileActionButton;
