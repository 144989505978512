import { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { FormItemMemo } from 'memo';
import cn from 'classnames';
import { CalculationMode } from 'constants/item';
import classes from './ItemCard.module.less';
import ItemCardHeaderPanel, { BookmarkButtonMemo, CheckButtonMemo } from './ItemCardHeaderPanel';
import { InputFieldsMemo, RequiredFieldsAlertMemo } from '../components';
import { ItemInfoDebugModeMemo, ScaleInfoDebugModeMemo } from './ItemDebugMode';
import ItemPriceInfo from './ItemPriceInfo';

const InputFieldsWrapper = ({
  calculationMode,
  displayInputFields,
  fieldError,
  isChecked,
  isValid,
  relItems,
  relatedInputs,
}) => {
  if (!isChecked || calculationMode === CalculationMode) return null;
  return (
    <>
      <div className={classes.inputFieldsItem}>
        {displayInputFields?.length ? <InputFieldsMemo inputFields={displayInputFields} /> : null}
      </div>
      {!isValid && (
        <RequiredFieldsAlertMemo fieldError={fieldError} relatedInputs={relatedInputs} relItems={relItems} />
      )}
    </>
  );
};

const InputFieldsWrapperMemo = memo(InputFieldsWrapper, equal);

const ItemCard = ({
  item,
  _id,
  benefits,
  calculationMode,
  currentFeeTypeMessage,
  currentFeeTypeMonthly,
  customPrice,
  debugMode,
  descriptionForContract,
  discount,
  displayInputFields,
  fieldError,
  graduatedScaleMode,
  highlightBindingness,
  infoText,
  internalInputFields,
  internalNoteToTeam,
  isBindingnessActualCost,
  isChecked,
  isValid,
  minPrice,
  maxPrice,
  name,
  notesForFeeAgreement,
  notesToEmployee,
  officialReasonText,
  paymentInterval,
  pleaseNote,
  price,
  pricingFormulaExtended,
  relItems,
  relatedInputs,
  roundPrice,
  scalePricingFormulaTitle,
  scaleTitle,
  scales,
  showCalculatedPrice,
  showDigits,
  showPrices,
  subTitle,
}) => {
  return (
    <FormItemMemo className={cn(classes.itemCart, 'margin-bottom-0')} key={_id} name={_id}>
      <CheckButtonMemo _id={_id} />
      <BookmarkButtonMemo _id={_id} />
      <div className={classes.itemMainInfo}>
        <ItemCardHeaderPanel
          benefits={benefits}
          descriptionForContract={descriptionForContract}
          infoText={infoText}
          itemName={name}
          notesForFeeAgreement={notesForFeeAgreement}
          notesToEmployee={notesToEmployee}
          pleaseNote={pleaseNote}
          subTitle={subTitle}
          item={item}
        />
        <div className={classes.item}>
          <div className={classes.itemFieldContainer}>
            <InputFieldsWrapperMemo
              calculationMode={calculationMode}
              displayInputFields={displayInputFields}
              fieldError={fieldError}
              isChecked={isChecked}
              isValid={isValid}
              relItems={relItems}
              relatedInputs={relatedInputs}
            />
            <ItemInfoDebugModeMemo
              _id={_id}
              calculationMode={calculationMode}
              debugMode={debugMode}
              highlightBindingness={highlightBindingness}
              internalInputFields={internalInputFields}
              minPrice={minPrice}
              maxPrice={maxPrice}
              name={name}
              paymentInterval={paymentInterval}
              pricingFormulaExtended={pricingFormulaExtended}
              roundPrice={roundPrice}
              showDigits={showDigits}
            />
          </div>
          {isChecked ? (
            <ItemPriceInfo
              _id={_id}
              calculationMode={calculationMode}
              currentFeeTypeMessage={currentFeeTypeMessage}
              currentFeeTypeMonthly={currentFeeTypeMonthly}
              customPrice={customPrice}
              discount={discount}
              highlightBindingness={highlightBindingness}
              internalInputFields={internalInputFields}
              internalNoteToTeam={internalNoteToTeam}
              isBindingnessActualCost={isBindingnessActualCost}
              officialReasonText={officialReasonText}
              paymentInterval={paymentInterval}
              price={price}
              showCalculatedPrice={showCalculatedPrice}
              showDigits={showDigits}
              showPrices={showPrices}
              minPrice={minPrice}
              maxPrice={maxPrice}
            />
          ) : null}
        </div>
        <ScaleInfoDebugModeMemo
          debugMode={debugMode}
          graduatedScaleMode={graduatedScaleMode}
          scalePricingFormulaTitle={scalePricingFormulaTitle}
          scaleTitle={scaleTitle}
          scales={scales}
          showDigits={showDigits}
        />
      </div>
    </FormItemMemo>
  );
};
export default memo(ItemCard, equal);
