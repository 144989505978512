import { gql } from '@apollo/client';
import { adminDocumentTemplateShowOrHideItemsFragment } from 'graphql/fragments';

const adminDocumentTemplateShowOrHideCategoryItems = gql`
  query adminDocumentTemplateShowOrHideCategoryItems {
    categories {
      ...adminDocumentTemplateShowOrHideItemsFragment
    }
  }
  ${adminDocumentTemplateShowOrHideItemsFragment}
`;

export default adminDocumentTemplateShowOrHideCategoryItems;
