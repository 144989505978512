import { useTranslation } from 'react-i18next';
import { Card, Button } from 'antd';
import { Field, useFormikContext } from 'formik';
import { TableMemo } from 'memo';
import { memo, useMemo } from 'react';
import { Form, Input } from 'formik-antd';
import { includes } from 'lodash';
import { InputNumber } from 'components/common/InputNumber';
import equal from 'fast-deep-equal/es6/react';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import { updateTheme } from 'graphql/methods';
import { BoldOutlined, ItalicOutlined, UnderlineOutlined } from '@ant-design/icons';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import { ColorPickerFormikInput, FontFormikInput } from './components/Inputs';
import classes from './CorporateIdentitySetting.module.less';

const typeWithStyleButtons = ['title', 'h1', 'h2', 'h3'];

const FormikStyleButton = ({ name, icon }) => {
  return (
    <FormItem name={name}>
      <Field name={name}>
        {({ field, form }) => {
          return (
            <Button
              size="small"
              type={field.value ? 'primary' : 'default'}
              icon={icon}
              onClick={() => {
                form.setFieldValueAndTouched(name, !field.value);
              }}
            />
          );
        }}
      </Field>
    </FormItem>
  );
};

const textStylesForDocumentsTableColumns = ({ t }) => [
  {
    key: 'name',
    dataIndex: 'name',
    render: (name) => t(`admin.Setting.CorporateIdentity.TextStylesForDocuments.inputs.${name}`),
  },
  {
    title: t('admin.Setting.CorporateIdentity.TextStylesForDocuments.field.fontFamily'),
    key: 'fontFamily',
    render: ({ name }) => {
      return (
        <div className={classes.fontInputWrapper}>
          <FontFormikInput name={`${name}.fontFamily`} />
        </div>
      );
    },
    width: 150,
  },
  {
    title: '',
    key: 'format',
    render: ({ name }) => {
      if (includes(typeWithStyleButtons, name)) {
        return (
          <div className={classes.styleButton}>
            <FormikStyleButton name={`${name}.bold`} icon={<BoldOutlined />} />
            <FormikStyleButton name={`${name}.italic`} icon={<ItalicOutlined />} />
            <FormikStyleButton name={`${name}.underline`} icon={<UnderlineOutlined />} />
          </div>
        );
      }
      return null;
    },
    width: 150,
  },
  {
    width: 200,
    title: t('admin.Setting.CorporateIdentity.TextStylesForDocuments.field.color'),
    key: 'colour',
    render: ({ name }) => {
      return <ColorPickerFormikInput className={classes.picker} name={`${name}.color`} />;
    },
  },
  {
    title: t('admin.Setting.CorporateIdentity.TextStylesForDocuments.field.fontSize'),
    key: 'fontSize',
    render: ({ name }) => {
      return <Input name={`${name}.fontSize`} />;
    },
  },
  {
    title: t('admin.Setting.CorporateIdentity.TextStylesForDocuments.field.lineHeight'),
    key: 'lineHeight',
    render: ({ name }) => {
      return <InputNumber name={`${name}.lineHeight`} />;
    },
  },
  {
    title: t('admin.Setting.CorporateIdentity.TextStylesForDocuments.field.marginBottom'),
    key: 'marginBottom',
    render: ({ name }) => {
      return <Input name={`${name}.marginBottom`} />;
    },
  },
  {
    title: t('admin.Setting.CorporateIdentity.TextStylesForDocuments.field.marginTop'),
    key: 'marginTop',
    render: ({ name }) => {
      return <Input name={`${name}.marginTop`} />;
    },
  },
];

const TextStylesForDocumentsTable = ({ styles }) => {
  const { t } = useTranslation();
  return (
    <TableMemo
      bordered={false}
      dataSource={styles}
      pagination={false}
      columns={textStylesForDocumentsTableColumns({ t })}
      scroll={{
        x: 1000,
      }}
    />
  );
};
const TextStylesForDocumentsMemo = memo(TextStylesForDocumentsTable, equal);

const TextStylesForDocumentsFormContent = () => {
  const { values } = useFormikContext();
  const styles = useMemo(() => {
    return [
      { ...values.title, name: 'title' },
      { ...values.h1, name: 'h1' },
      { ...values.h2, name: 'h2' },
      { ...values.h3, name: 'h3' },
      { ...values.textBlock, name: 'textBlock' },
      { ...values.textLessImportant, name: 'textLessImportant' },
      { ...values.testimonial, name: 'testimonial' },
      { ...values.testimonialSmallText, name: 'testimonialSmallText' },
    ];
  }, [
    values.h1,
    values.h2,
    values.h3,
    values.textLessImportant,
    values.testimonial,
    values.testimonialSmallText,
    values.textBlock,
    values.title,
  ]);
  return <TextStylesForDocumentsMemo styles={styles} />;
};

const setDefaultStyles = (field = {}) => ({
  fontFamily: field.fontFamily || 'ProximaNova',
  color: field.color || '#ffffff',
  bold: field.bold || false,
  italic: field.italic || false,
  underline: field.underline || false,
  fontSize: field.fontSize || '',
  lineHeight: field.lineHeight || '',
  marginBottom: field.marginBottom || '',
  marginTop: field.marginTop || '',
});

function TextStylesForDocuments({
  title,
  h1,
  h2,
  h3,
  textBlock,
  testimonial,
  textLessImportant,
  testimonialSmallText,
}) {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      title: setDefaultStyles(title),
      h1: setDefaultStyles(h1),
      h2: setDefaultStyles(h2),
      h3: setDefaultStyles(h3),
      textBlock: setDefaultStyles(textBlock),
      textLessImportant: setDefaultStyles(textLessImportant),
      testimonial: setDefaultStyles(testimonial),
      testimonialSmallText: setDefaultStyles(testimonialSmallText),
    }),
    [h1, h2, h3, testimonial, testimonialSmallText, textLessImportant, textBlock, title],
  );

  return (
    <Card title={t('admin.Setting.CorporateIdentity.TextStylesForDocuments.title')}>
      <I18nFormik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(modifier) => {
          updateTheme({ pdf: modifier });
        }}
      >
        <Form>
          <TextStylesForDocumentsFormContent />
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </Card>
  );
}

export default memo(TextStylesForDocuments, equal);
