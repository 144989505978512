import TimelineCard from './ClientHistoryTab/TimelineCard';

/**
 * History tab for client view
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.client - The client object
 * @param {string} inputProperties.client._id - The client ID
 * @returns {JSX.Element} Card with timeline
 * @component
 */
const ClientHistoryTab = ({ client }) => {
  return (
    <div>
      <TimelineCard client={client} />
    </div>
  );
};

export default ClientHistoryTab;
