import { gql } from '@apollo/client';

const adminDocumentTemplateShowOrHideItemsFragment = gql`
  fragment adminDocumentTemplateShowOrHideItemsFragment on Category {
    _id
    name
    __typename
    items {
      _id
      name
      active
    }
    staticItems {
      _id
      readableTexts {
        name
      }
    }
  }
`;

export default adminDocumentTemplateShowOrHideItemsFragment;
