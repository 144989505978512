import { Space, Typography } from 'antd';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import { Checkbox, Form, FormItem } from 'formik-antd';
import { Trans, useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import classes from './AgreementsForm.module.less';

const { Title } = Typography;

const AgreementsForm = ({ isPrivatePerson, submitReference, stepState }) => {
  const { t: translation } = useTranslation();
  const [submitted, setSubmitted] = useState(stepState !== undefined);
  const innerRef = useRef();
  const initState = stepState
    ? {
        checkDocumentsSeen: stepState?.checkDocumentsSeen,
        checkNoticeOfCancellation: stepState?.checkNoticeOfCancellation,
      }
    : { checkDocumentsSeen: false, checkNoticeOfCancellation: false };

  // eslint-disable-next-line no-param-reassign
  submitReference.current = {
    onSubmit: async () => {
      if (innerRef?.current?.submitForm) {
        await innerRef.current.submitForm();
        if (innerRef?.current?.isValid) setSubmitted(true);
        return innerRef?.current?.isValid;
      }
      return false;
    },
  };

  const onSubmit = ({ checkDocumentsSeen, checkNoticeOfCancellation }, formik) => {
    const errors = {};
    if (isPrivatePerson && !checkNoticeOfCancellation)
      errors.checkDocumentsSeen = translation('signing.viewDocumentsAndSignStep.agreementsForm.errorNotSelectedYet');
    if (!checkDocumentsSeen)
      errors.checkDocumentsSeen = translation('signing.viewDocumentsAndSignStep.agreementsForm.errorNotSelectedYet');
    formik.setErrors(errors);
  };

  return (
    <I18nFormik initialValues={initState} onSubmit={onSubmit} innerRef={innerRef} submitForm>
      <Title level={5}>{translation('signing.viewDocumentsAndSignStep.agreementsForm.stepHeadline')}</Title>
      <Form>
        <Space direction="vertical">
          {isPrivatePerson && (
            <Checkbox name="checkNoticeOfCancellation" disabled={submitted}>
              <Trans
                i18nKey="signing.viewDocumentsAndSignStep.agreementsForm.checkNoticeOfCancellation"
                components={{ b: <strong /> }}
              />
            </Checkbox>
          )}
          <FormItem name="checkDocumentsSeen" id="checkDocumentsSeen">
            <Checkbox className={classes.checkbox} name="checkDocumentsSeen" disabled={submitted}>
              <Trans
                i18nKey="signing.viewDocumentsAndSignStep.agreementsForm.checkDocumentsSeen"
                components={{ b: <strong /> }}
              />
            </Checkbox>
          </FormItem>
        </Space>
      </Form>
    </I18nFormik>
  );
};

export default AgreementsForm;
